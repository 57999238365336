.rdtPicker th.rdtNext, .rdtPicker th.rdtPrev {
    vertical-align: middle;
}
.rdtPicker th.rdtSwitch {
    vertical-align: middle;
    font-size: 14px;
    font-weight: bold;
}
.rdtPicker .dow {
    vertical-align: middle;
    font-size: 14px;
    border-bottom: 1px solid #aeaeae;
}
.rdtPicker td, .rdtPicker td.rdtNew {
    vertical-align: middle;
    font-size: 14px;
}
.rdtPicker {
    padding: 0;
    border: 1px solid #aeaeae;
    border-radius: 3px;
}
.rdtPicker th {border-bottom: 0;background-color: #f0f0f0;}
.rdtPicker td.rdtToday:before {
    bottom: 0px;
    right: 0px;
    content: none;
}
.rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover {
    border-radius: 3px;
}
.rdtPicker td.rdtToday, .rdtPicker td.rdtToday:hover {
    background-color: #2a87d0;
    color: #fff;
    border-radius: 3px;
}
/*.rdtPicker td.rdtDay:hover*/