/* custom code start*/
.calendar-wrapper {
  float: left;
  width: 100%;
  height: 100%;
}
.patient-wrapper {
  float: left;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
}
.patient-modal {
  float: left;
  width: 100%;
  height: 100%;
  position: relative !important;
  border: 1px solid #e2e8ef;
}
#EventLog b {
  color: #388e3c;
}

hr {
  margin: 1px 10px 1px 0px;
  border-top: 1px solid #eee;
}
/* custom code end*/

.e-schedule .e-schedule-toolbar .e-icon-schedule-print::before {
  content: '\e973';
}

.e-schedule .e-schedule-toolbar .e-icon-schedule-pdf::before {
  content: '\e7c8';
}

.custom-field-row {
  margin-bottom: 20px;
}

.multi-prop div {
  padding-left: 0;
  padding-top: 0;
}
.template-wrap {
  background: #3fa9f5;
  width: 100%;
}
.template-wrap.Holiday {
  background: #f35757 !important;
  width: 100%;
  height: 100%;
  color: #ffffff;
  text-align: center;
}
.template-wrap.Leave {
  background: #F57F17;
  width: 100%;
  height: 100%;
  color: #ffffff;
  text-align: center;
}
.schedule-box-title {
  width: 100%;
  padding: 3px 5px;
  font-size: 10px;
  font-weight: normal;
  padding-left: 10px;
}
.schedule-box-body {
  width: 100%;
  padding: 3px 5px;
  font-size: 12px;
  line-height: 12px;
  height: 37px;
}
.e-appointment-details div,
.e-more-popup-wrapper .e-appointment div{
  width: 100%;
}
.schedule-box-edit {
  display: none;
}
.e-more-event-popup .schedule-box-edit {
  display: block;
  width: 50px !important;
  float: right;
  cursor: pointer;
}
.schedule-box-image img{
  width: 30px;
  height: 30px;
  float: left;
  display: none;
}
.schedule-box-desc {
  width: calc(100% - 30px);
  float: left;
}
.e-more-event-popup .schedule-box-image img {
  display: block;
}
.event-editor-block-1,
.event-editor-block-2,
.event-editor-block-3 {
  width: 30%;
  height: 100%;
  float: left;
  overflow-y: auto;
}
.time-slot {
  padding: 5px;
  border: 1px solid #cccccc;
  color: #cccccc;
}
/* .time-slot.active {
  border: 1px solid #666666;
  color: #666666
} */
.time-meridiem.active {
  background: #535699;
  color: #FFFFFF;
}



.e-schedule > * {
  font-family: 'Source Sans Pro' !important;
}





.e-schedule .e-schedule-toolbar .e-toolbar-items.e-tbar-pos {
  height: 60px;
  min-height: 60px;
}
.e-schedule .e-schedule-toolbar .e-toolbar-items {
  background: var(--white);
}
.e-schedule .e-schedule-toolbar .e-tbar-btn {
  background: transparent;
}
.e-schedule .e-schedule-toolbar .e-tbar-btn:hover, .e-schedule .e-schedule-toolbar .e-tbar-btn:focus {
  background: transparent;
}
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  color: var(--default-grey);
  font-weight: 300;
}
.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text, .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: var(--default-color);
  font-weight: 600;
}
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-tbar-btn-text {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
}
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  left: 70px;
}
.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item {
  margin-right: 126px;
}
.e-schedule .e-schedule-toolbar {
  box-shadow: none;
}
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-icon-btn .e-icons.e-btn-icon {
  background: #E3E5E8;
  border-radius: 50%;
  min-width: 27px;
}
.e-schedule .e-schedule-toolbar .e-icon-prev::before,
.e-schedule .e-schedule-toolbar .e-icon-next::before {
  zoom: .7;
}
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  right: 20px;
}
.e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  padding: 0;
}
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  height: calc(100% - 25px);
}
.e-schedule .e-month-view .e-date-header-wrap table td {
  background: #a9a9a9;
  padding: 13px 15px;
  color: var(--white);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
  border-color: var(--white);
}
.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day, 
.e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day {
  color: var(--white);
}
.e-schedule .e-month-view .e-work-cells, .e-schedule .e-month-agenda-view .e-work-cells {
  background-color: var(--white);
}
.e-schedule .e-month-view .e-work-cells:hover, .e-schedule .e-month-agenda-view .e-work-cells:hover {
  background-color: #FAFAFA;
  color: var(--default-text);
}



.e-schedule .e-month-view .e-date-header {
  float: right;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  margin-top: 10px;
  margin-right: 15px;
  position: relative;
  z-index: 1;
}
.e-schedule .e-month-view .e-current-date .e-date-header, 
.e-schedule .e-month-agenda-view .e-current-date .e-date-header {
  background: transparent;
  color: #ED3A2C;
}
.e-schedule .e-month-view .e-other-month, .e-schedule .e-month-agenda-view .e-other-month {
  color: rgb(37 43 62 / 0.5);
}
.schedule-box-title {
  padding-right: 40px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  color: rgb(0 0 0 / .5);
  padding-bottom: 0;
  padding-top: 0;
  line-height: 19px;
}
.schedule-box-body {
  float: left;
  width: 100%;
  padding-right: 40px;
  padding-left: 10px;
  padding-top: 0;
}
.e-schedule .e-month-view .e-appointment {
  border: 0;
  border-radius: 0;
  cursor: pointer;
}



.e-schedule .e-month-view .e-appointment {
  background: transparent;
  border-bottom: 1px solid rgb(0 0 0 / 0.10);
  height: 56px;
}
.template-wrap {
  float: left;
  width: 100%;
}

.schedule-box-name {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.schedule-box-subject {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}
.e-schedule .e-month-view .e-more-indicator {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    padding-left: 10px;
}



.e-more-popup-wrapper {
  padding: 0;
  width: 500px;
}
.e-more-popup-wrapper .e-more-event-header {
  height: 60px;
  margin: 0;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.e-more-popup-wrapper .e-more-event-date-header {
  display: flex;
  align-items: center;
}
.e-more-popup-wrapper .e-header-date {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 25px;
  max-width: none;
}
.e-schedule .e-close-icon::before {
  color: var(--default-color);
}
.e-more-popup-wrapper .e-more-event-close {
  right: 13px;
}
.e-more-event-footer {
  float: left;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.e-more-event-footer h3 {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  color: var(--default-color);
}
.e-more-event-footer button {
  height: 35px;
  border: 0;
  padding: 0 10px;
  color: var(--white);
  border-radius: 5px;
  background-color: var(--default-color);
  box-shadow: 0 0 12px 0 rgba(0,0,0,0.2);
}
.e-more-event-content .template-wrap {
  background: transparent !important;
}








.scheduled-event .schedule-box-body {
  display: flex;
}
.scheduled-event schedule-box-image {
  float: left;
  width: 40px;
  height: 40px;
}
.e-appointment .scheduled-event .schedule-box-image {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.e-appointment .scheduled-event .schedule-box-image img {
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.e-appointment .scheduled-event .schedule-box-desc {
  width: auto;
}
.e-more-popup-wrapper .e-appointment {
  float: left;
  width: 100%;
  background: transparent;
  height: auto;
  min-height: 76px;
  margin: 0;
  padding: 0;
}
.e-appointment .scheduled-event .schedule-box-title {
  margin-bottom: 7px;
  padding: 0;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  color: var(--default-color);
}
.e-appointment .scheduled-event .schedule-box-body {
  padding: 0;
  height: auto;
}
.e-more-event-popup .schedule-box-edit {
  text-align: right;
  color: var(--default-color);
}
.e-appointment .scheduled-event .schedule-box-name {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 3px;
}
.e-appointment .scheduled-event .schedule-box-subject {
  color: var(--default-grey);
}
.template-wrap.Booking.scheduled-event {
  padding: 10px 15px;
  border-bottom: 1px solid #E2E8EF;
}
.e-more-popup-wrapper .e-more-event-content {
  padding: 0;
  float: left;
  width: 100%;
}


/* 
.e-schedule .e-month-view .e-appointment-wrapper {
  float: left;
  width: 100%;
}
.e-schedule .e-month-view .e-work-cells {
  position: relative;
}
.e-schedule .e-month-view .e-appointment {
  float: left;
  width: 100% !important;
} */

.white-modal .modal-body.white-mod-bg {
  background: var(--white);
}
.attendee {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E2E8EF;
  display: flex;
  align-items: center;
}
.attendee-image {
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;
  position: relative;
}
.attendee-image img {
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.attendee-desc {
  float: left;
  width: calc(100% - 65px);
}
.attendee-desc .attendee-name {
  font-size: 18px;
  color: var(--default-text);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 3px;
}
.attendee-desc .attendee-subject {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  color: var(--default-grey);
}
.e-schedule .e-month-view .e-appointment.e-appointment-border, .e-schedule .e-month-view .e-appointment:focus {
  border: 0;
  border-bottom: 1px solid rgb(0 0 0 / 0.10);
  box-shadow: none;
}


.e-quick-popup-wrapper {
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(226 232 239 / 0.75);;
  border-top: 0;}

.e-quick-popup-wrapper .e-cell-popup .e-popup-content .e-popup-table .e-subject {
  font-size: 18px;
}
.e-quick-popup-wrapper .e-cell-popup .e-popup-content .e-popup-table .e-schedule-form > span {
  margin-bottom: 0;
}
.e-date-time {
  display: flex;
  align-items: center;
}
.e-quick-popup-wrapper .e-popup-footer .e-event-details, .e-quick-popup-wrapper .e-popup-footer .e-event-create {
  height: 35px;
  padding: 0 15px;
}
.e-quick-popup-wrapper .e-popup-footer {
  padding: 10px 15px;
  border-top: 1px solid #E2E8EF;
  margin-top: 14px;
}
.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
  color: var(--default-color);
}
.e-btn.e-flat.e-primary:hover, .e-css.e-btn.e-flat.e-primary:hover {
  background: var(--default-color);
  color: var(--white);
}


.book-appointment-modal .e-popup.e-popup-open.e-dialog {
  width: 100% !important;
  max-width: 100% !important;
  height: 100%;
}
.book-appointment-modal .e-dialog .e-dlg-header-content {
  padding: 16px 22px;
  height: 60px;
  border-bottom: 1px solid #E2E8EF;  
  display: flex;
  align-items: center;
}
.book-appointment-modal .e-dialog .e-footer-content {
  padding: 0;
  height: 80px;
  padding: 20px 22px;
  margin: 0;
  border-top: 1px solid #E2E8EF;
  display: flex;
  justify-content: flex-end;
}
.book-appointment-modal .e-dialog .e-footer-content .e-btn {
  height: 40px;
  margin-left: 20px;
  font-weight: 600;
}
.book-appointment-modal .e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
  background: var(--default-color);
  color: var(--white);
  border-radius: 5px;
  font-size: 15px;
  padding: 0 25px;
}
.book-appointment-modal .e-btn.e-flat.e-event-cancel {
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  background-color: #ECF0F1;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
  padding: 0 24px;
}
.book-appointment-modal .e-dialog .e-footer-content .e-btn:first-child {
  margin: 0;
}
.book-appointment-modal .e-btn.e-flat.e-event-delete {
  background: #e14745;
  color: var(--white);
  border-radius: 5px;
}
.book-appointment-modal .e-btn.e-flat.e-primary:focus, .e-css.e-btn.e-flat.e-primary:focus {
  background-color: var(--hover-color);
  color: var(--white);
}







.book-appointment-modal .e-dialog .e-dlg-content {
  padding: 0;
}
.book-appointment-modal .e-dialog .e-dlg-content {
  float: left;
  width: 100%;
  height: calc(100% - 140px);
}
.book-appointment-modal .e-form-container {
  float: left;
  width: 100%;
  height: 100%;
}
.book-appointment-modal .event-editor-block-1 {
  float: left;
  width: 30%;
  height: 100%;
  border-right: 1px solid #E2E8EF;
  overflow-y: auto;
}
.editor-section-details-wrap:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}
.book-appointment-modal .e-form-container .e-schedule-form {
  float: left;
  width: 100%;
  height: 100%;
}
.book-appointment-modal .e-form-container .e-schedule-form > div {
  float: left;
  width: 100%;
  height: 100%;
}
.book-appointment-modal .custom-event-editor {
  float: left;
  width: 100%;
  height: 475px;
}
.book-appointment-modal .event-editor-block-2 {
  border-right: 1px solid #E2E8EF;
}
.book-appointment-modal .event-editor-block-1, .book-appointment-modal .event-editor-block-2 {
  padding: 20px;
}
.book-appointment-modal .react-datepicker__tab-loop {
  float: left;
  width: 100%;
  position: relative;
}
.each-divider {
  float: left;
  width: 100%;
  border-bottom: 0;
  margin-bottom: 30px;
}
.editor-section {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
  justify-content: space-between;
  padding-bottom: 0px;
}
.editor-section-details-wrap {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 30px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #E2E8EF;
}
.editor-section-image {
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;
  position: relative;
}
.editor-section-image img {
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.editor-section-desc {
  float: left;
  width: calc(100% - 65px);
}
.editor-section-desc .editor-section-name {
  font-size: 18px;
  color: var(--default-text);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 3px;
}
.editor-section-desc .editor-section-details {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  color: var(--default-grey);
}






.editor-section .editor-section-title {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 23px;
  color: var(--default-color);
  text-transform: uppercase;
}
.editor-section button {
  float: left;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: var(--default-color);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);
  border: 0;
  color: var(--white);
  font-size: 18px;
}
.editor-section-details.date-field {
  flex-direction: column;
  align-items: flex-start;
}
.editor-section-details.date-field .editor-section-date {
  float: left;
  width: 100%;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  color: var(--default-color);
  margin: 5px 0 0 0;
}
.editor-section-details.date-field .editor-section-date label {
  color: var(--default-grey);
}
.event-editor-block-3 > h5 {
  float: left;
  width: 100%;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 30px;
}
.time-slots {
  float: left;
  width: 100%;
}
.time-meridiem-wrap {
  float: left;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
.time-meridiem {
  float: left;
  height: 40px;
  width: 60px;
  border: 1px solid rgba(64,83,100,0.15);
  border-radius: 10px;
  background-color: var(--white);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}
.time-meridiem.active {
  background: var(--default-color);
}
.time-slots-wrap {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.time-slot {
  float: left;
  height: 40px;
  width: calc((100% / 3) - 14px);
  border: 1px solid rgba(64,83,100,0.15);
  border-radius: 10px;
  background-color: var(--white);
  color: var(--default-text);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.time-slot:nth-child(3n) {
  margin-right: 0;
}
.time-slot.inactive {
  pointer-events: none;
  background: #ebebeb;
  color: #a1a1a1;
}
.time-slot.active {
  background: var(--default-color);
  color: var(--white);
}

.event-editor-block-2 .react-datepicker-popper {
  transform: none !important;
  width: 100% !important;
}
.event-editor-block-2 .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, 
.event-editor-block-2 .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  display: none;
}
.event-editor-block-2 .react-datepicker__month-container {
  width: 100%;
}
.event-editor-block-2 .react-datepicker {
  float: left;
  width: 100%;
  border: 0;
}
.event-editor-block-2 .react-datepicker__day-name, 
.event-editor-block-2 .react-datepicker__day, 
.event-editor-block-2 .react-datepicker__time-name {
  width: calc((100% / 7) - 5px);
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
}
.event-editor-block-2 .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, 
.event-editor-block-2 .react-datepicker__year-read-view--down-arrow, 
.event-editor-block-2 .react-datepicker__month-read-view--down-arrow, 
.event-editor-block-2 .react-datepicker__month-year-read-view--down-arrow, 
.event-editor-block-2 .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, 
.event-editor-block-2 .react-datepicker__year-read-view--down-arrow::before, 
.event-editor-block-2 .react-datepicker__month-read-view--down-arrow::before, 
.event-editor-block-2 .react-datepicker__month-year-read-view--down-arrow::before {
  display: none;
}
.event-editor-block-2 .react-datepicker__day-names {
  padding: 0 30px;
}
.event-editor-block-2 .react-datepicker__month {
  margin: 0 30px;
}
.event-editor-block-2 .react-datepicker__header {
  background: transparent;
  border: 0;
  padding-top: 0;
}
.event-editor-block-2 .react-datepicker__current-month {
  text-align: left;
  line-height: 40px;
  font-size: 24px;
  letter-spacing: 0;
  font-weight: 800;
  margin-bottom: 5px;
}
.event-editor-block-2 .react-datepicker__navigation--previous {
  float: left;
  width: 50px;
  height: 50px;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
  border: 0;
  border-radius: 50%;
  left: -30px;
  top: calc(50% - 5px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
}
.event-editor-block-2 .react-datepicker__navigation--next {
  float: left;
  width: 50px;
  height: 50px;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
  border: 0;
  border-radius: 50%;
  right: -30px;
  top: calc(50% - 5px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
}
.event-editor-block-2 .react-datepicker__navigation--previous:focus, 
.event-editor-block-2 .react-datepicker__navigation--next:focus {
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.12) !important;
}
.event-editor-block-2 .react-datepicker__today-button {
  background: transparent;
  border: 1px solid var(--default-color);
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 75px;
  border-radius: 10px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--default-color);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}
.event-editor-block-2 .react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 20px;
}
.event-editor-block-2 .react-datepicker__day-name {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: var(--default-grey);
}
.event-editor-block-2 .react-datepicker > * {
  font-family: 'Source Sans Pro';
}
.event-editor-block-2 .react-datepicker__day {
  color: var(--default-text);
  font-size: 14px;
  letter-spacing: 0;  
  border-radius: 5px;
}
.event-editor-block-2 .react-datepicker__day--selected, 
.event-editor-block-2 .react-datepicker__day--in-selecting-range, 
.event-editor-block-2 .react-datepicker__day--in-range, 
.event-editor-block-2 .react-datepicker__month-text--selected, 
.event-editor-block-2 .react-datepicker__month-text--in-selecting-range, 
.event-editor-block-2 .react-datepicker__month-text--in-range, 
.event-editor-block-2 .react-datepicker__quarter-text--selected, 
.event-editor-block-2 .react-datepicker__quarter-text--in-selecting-range, 
.event-editor-block-2 .react-datepicker__quarter-text--in-range {
  color: var(--white);
}
.event-editor-block-2 .react-datepicker__day--today {
  font-weight: 600;
  background: #319945;
  border-radius: 5px;
  color: var(--white);
}
.event-editor-block-2 .react-datepicker__day--today:hover {
  background: #319945;
  border-radius: 5px;
  color: #ffffff;
}
.event-editor-block-2 .react-datepicker__day--selected, 
.event-editor-block-2 .react-datepicker__day--in-selecting-range, 
.event-editor-block-2 .react-datepicker__day--in-range, 
.event-editor-block-2 .react-datepicker__month-text--selected, 
.event-editor-block-2 .react-datepicker__month-text--in-selecting-range, 
.event-editor-block-2 .react-datepicker__month-text--in-range, 
.event-editor-block-2 .react-datepicker__quarter-text--selected, 
.event-editor-block-2 .react-datepicker__quarter-text--in-selecting-range, 
.event-editor-block-2 .react-datepicker__quarter-text--in-range {
  background: var(--default-color);
}
.event-editor-block-2 .react-datepicker__day--keyboard-selected, 
.event-editor-block-2 .react-datepicker__month-text--keyboard-selected, 
.event-editor-block-2 .react-datepicker__quarter-text--keyboard-selected{
  background: var(--default-color);
  color: var(--white);
}
.event-editor-block-2 .react-datepicker__day--outside-month {
  color: var(--default-grey);
}
.event-editor-block-2 .react-datepicker__input-container input {
  border: 1px solid var(--default-color);
  border-radius: 10px;
  height: 40px;
  padding: 0 20px 0 42px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 40px;
  background: rgba(var(--default-color), 0.5);
}
.event-editor-block-2 .react-datepicker__input-container {
  float: left;
  width: 100%;
  height: 40px;
  border: 0;
  /* padding: 0 20px; */
  position: relative;
}
.event-editor-block-2 .react-datepicker__input-container:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--default-color);
  position: absolute;
  left: 0;
  top: 0;
  opacity: .1;
  border-radius: 10px;
}



.e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells {
  vertical-align: middle;
  background: #a9a9a9;
  color: var(--white);
  padding-left: 20px;
  padding-right: 20px;
  /* height: 50px; */
}
.e-schedule .e-vertical-view .e-header-cells .e-header-date {
  font-size: 24px;
  letter-spacing: 0;
  line-height: 31px;
  float: left;
}
.e-schedule .e-vertical-view .e-header-cells .e-header-day {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 31px;
  float: right;
}
.e-schedule .e-vertical-view .e-time-cells-wrap .e-time-cells {
  vertical-align: middle;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  border-bottom: 0;
  padding-left: 20px;
}
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
  background: transparent;
  border: 0; 
  border-radius: 0;
  width: 100% !important;
}
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-appointment-details {
  padding: 0;
}
.e-schedule .e-vertical-view .e-time-cells-wrap table td, .e-schedule .e-vertical-view .e-work-cells {
  height: 56px;
  border-bottom: 0;
}
.e-schedule .e-vertical-view .e-time-cells-wrap tr:last-child .e-time-cells {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.e-schedule .e-vertical-view .e-content-wrap table tbody tr:last-child td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-up-icon {
  display: none;
}
.e-schedule .e-vertical-view .e-left-indent {
  width: 12.5%;
} 
.e-schedule .e-vertical-view .e-time-cells-wrap .e-time-cells > span {
  float: left;
}
.e-more-event-popup .e-appointment .scheduled-event .schedule-box-title {
  background: transparent !important;
}
.e-more-popup-wrapper .e-header-date:focus {
  text-decoration: none;
}
/* .e-dlg-container .e-dialog .e-dlg-content {
  padding: 16px 22px;
} */
.e-dialog .e-dlg-header-content {
  padding: 16px 22px;
  height: 60px;
}
.e-dialog .e-footer-content {
  float: left;
  width: 100%;
  padding: 0;
  height: 80px;
  padding: 20px 15px;
  margin: 0;
}
.e-dialog .e-footer-content .e-btn {
  height: 40px;
}
.e-btn.e-flat.e-primary:focus, 
.e-css.e-btn.e-flat.e-primary:focus, 
.e-btn.e-flat.e-primary:active, 
.e-btn.e-flat.e-primary.e-active, 
.e-css.e-btn.e-flat.e-primary:active, 
.e-css.e-btn.e-flat.e-primary.e-active {
  background-color: transparent;
  border-color: transparent;
  color: var(--default-color);
}
.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td.e-header-cells {
  background: #a9a9a9;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 31px;
  color: var(--white);
  vertical-align: middle;
  padding-left: 20px;
  padding-right: 20px;
}
.e-dlg-container > * , .book-appointment-modal .e-dialog .e-footer-content .e-btn {
  font-family: 'Source Sans Pro';
}
.e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 15px;
  width: auto;
}
.e-dlg-header .e-title-text {
  font-weight: 600;
}
.e-schedule .e-vertical-view .e-work-cells{
  background-color: #ffffff;
}

.patient-modal .event-editor-block-2 {
  padding-left: 50px !important;
  padding-right: 50px !important;
  width: 40%;
  height: 100%;
  overflow-y: auto;
}
.patient-modal .e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn,
.book-appointment-modal.patient-modal .e-btn.e-flat.e-event-cancel {
    display: none;
}
.bs-modal.bs-conformation .bs-modal-content .bs-modal-title h3:before {
  flex-shrink: 0;
}
.patient-wrapper .e-schedule .e-month-view .e-appointment-wrapper {
  display: none;
}
/* .calendar-wrapper.patient-wrapper {
  height: 100vh;
} */

.patient-wrapper .event-editor-block-2 .react-datepicker__day--disabled {
  color: #ccc;
}
.patient-wrapper .event-editor-block-2 .react-datepicker__day.e-disableCell {
  color: #ccc;
  pointer-events: none;
}

.each-divider .text-input-box .input-box {
  border: 0;
  border-bottom: 1px solid #e4e5e9;
  padding: 0;
  border-radius: 0;
}
.each-divider .text-input-box {
  margin-bottom: 20px;
  float: left;
  width: 100%;
}
.each-divider .text-input-box .textarea {
  border: 0;
  border-bottom: 1px solid #e4e5e9;
  padding: 0;
  margin-bottom: 0;
}
.each-divider .text-input-box:last-child {
  margin-bottom: 0;
}
.e-toolbar-right .e-date-range {
  pointer-events: none !important;
  margin-right: 10px;
}
.e-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-icon-down-arrow {
  display: none;
}
.e-btn.e-flat.e-primary:focus, 
.e-css.e-btn.e-flat.e-primary:focus, 
.e-btn.e-flat.e-primary:active, 
.e-btn.e-flat.e-primary.e-active, 
.e-css.e-btn.e-flat.e-primary:active, 
.e-css.e-btn.e-flat.e-primary.e-active {
	background-color: var(--default-color) !important;
	color: var(--white) !important;
}
.event-editor-block-2 .editor-section {
  margin-bottom: 15px;
}

.even-time-block {
  float: left;
  width: 100%;
  height: calc(100% - 80px);
  padding: 20px;
}
.even-time-block h5 {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.day-slots-avaible-cnt {
  color: #cc0001;
}
.even-time-block .error-field {
  font-size: 16px;
}


@media(max-width: 1024px) {

  .calendar-wrapper {
    padding: 0;
  }
  .calendar-header {
    padding: 0 15px;
    flex-direction: column;
    position: relative;
    justify-content: center;
  }
  .calendar-header h5 {
      font-size: 18px;
      line-height: normal;
  }
  .calendar-header p {
      font-size: 16px;
  }
  .e-schedule .e-schedule-table {
      height: calc(100% - 60px) !important;
  }
  .holiday-cell-title {
      font-size: 12px;
  }
  .calendar-title-cntr {
    width: 100%;
    justify-content: center;
  }
  .calendar-title-cntr {
    width: 100%;
    justify-content: center;
  }
  .calendar-title-cntr .meet-set-button {
    position: absolute;
    left: auto;
    right: 15px;
    top: 15px;
  }
  .e-schedule.e-device .e-vertical-view .e-left-indent {
    width: 25%;
  }
  .e-schedule .e-vertical-view .e-left-indent-wrap table tbody td.e-header-cells {
    font-size: 12px;
  }
  .e-schedule.e-device .e-vertical-view .e-header-cells .e-header-day {
    font-size: 12px;
    line-height: normal;
    text-align: center;
    width: 100%;
  }
  .e-schedule.e-device .e-vertical-view .e-header-cells .e-header-date {
    font-size: 12px;
    line-height: normal;
    text-align: center;
    width: 100%;
  }
  .e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells {
    padding: 0 10px;
  }
  .e-schedule.e-device .e-vertical-view .e-time-cells-wrap {
    width: 25%;
  }
  .book-appointment-modal .e-popup.e-popup-open.e-dialog {
    max-width: 100% !important;
    width: 100% !important;
    height: 100%;
    max-height: 100% !important;
  }
  .book-appointment-modal .event-editor-block-1 {
    width: 100%;
    height: auto;
    padding-bottom: 0;
    border: 0;
    border-bottom: 1px solid #E2E8EF;
  }
  .event-editor-block-2 {
    width: 100%;
    height: 380px;
    border: 0;
    border-bottom: 1px solid #E2E8EF;
  }
  .patient-modal .event-editor-block-2 {
    width: 100%;
    padding-left: 20px !important;
  }
  .event-editor-block-3 {
    width: 100%;
    height: auto;
  }
  .patient-modal .event-editor-block-3{
    width: 100%;
  }
  .book-appointment-modal .e-dialog .e-dlg-content {
    height: calc(100% - 60px);
  }
  .react-datepicker-wrapper {
    height: auto;
  }
  .event-editor-block-2 .react-datepicker-popper {
    margin-top: 20px;
  }
  .event-editor-block-2 .react-datepicker__navigation--previous {
    left: 0;
    right: 35px;
    top: 48px;
  }
  .event-editor-block-2 .react-datepicker__navigation--next {
    right: auto;
    top: 48px;
    left: 35px;
  }
  .book-appointment-modal .event-editor-block-2 {
    border: 0;
    border-bottom: 1px solid #E2E8EF;
    height: auto;
    min-height: 500px;
  }
  .e-schedule-dialog.e-device .e-back-icon {
    display: flex;
    align-items: center;
  }
  .e-schedule-dialog.e-device .e-save-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .e-schedule .e-month-view .e-date-header-wrap table td {
    font-size: 12px;
    text-align: center;
    padding: 13px 8px;
  }
  .book-appointment-modal .e-dialog .e-dlg-header-content {
    box-shadow: none;
  }
  .recomend-tab {
    flex-wrap: unset;
    overflow: hidden;
    overflow-x: auto;
  }
  .recomend-tab .nav-item {
    flex: 0 0 45%;
    justify-content: center;
  }
  .settings-section ul li {
    width: calc((100% / 2) - 9px);
  }
  .split-calendar-block .set-input-block {
    width: 100%;
  }
  .split-calendar-block {
    display: block;
  }
  .split-calendar-block .select-chk-block {
    margin-top: 10px;
    margin-left: 0;
  }
  .each-calendar-picker {
    width: 100%;
    margin-bottom: 10px;
  }
  .patient-modal .event-editor-block-3 {
    height: auto;
  }
  .patient-modal .e-schedule-dialog.e-device .e-back-icon {
    display: none;
  }
  .e-schedule-dialog.e-device .e-save-icon {
    color: var(--default-color);
  }
  .e-schedule-dialog.e-device .e-save-icon {
    color: var(--default-color);
    color: var(--default-color);
  }
  .event-editor-block-2 .react-datepicker__navigation--next, .event-editor-block-2 .react-datepicker__navigation--previous {
      width: 25px;
      height: 25px;
  }
  .event-editor-block-2 .react-datepicker__navigation--next:before, .event-editor-block-2 .react-datepicker__navigation--previous:before {
      zoom: .9;
  }
  .event-editor-block-2 .react-datepicker__day, 
  .event-editor-block-2 .react-datepicker__day-name, 
  .event-editor-block-2 .react-datepicker__time-name {
    width: 35px;
  }
  .patient-wrapper .book-appointment-modal .e-popup.e-popup-open.e-dialog {
    height: 100%;
  }
  .event-editor-block-2 .react-datepicker__current-month {
    padding-bottom: 40px;
  }
  .event-editor-block-2 .react-datepicker__day-names {
    padding: 0px;
  }
  .event-editor-block-2 .react-datepicker__month {
    margin: 0px;
  }
  .book-appointment-modal .custom-event-editor {
    height: auto;
  }

}

