@media(max-width: 1024px) {
    
    .swagger-ui .info {
        margin: 20px 0;
    }
    .swagger-ui .scheme-container .schemes {
        flex-direction: column;
        align-items: flex-start;
    }
    .swagger-ui .auth-wrapper {
        margin-top: 20px;
    }
    .swagger-ui .opblock .opblock-summary {
        flex-direction: column;
    }
    .swagger-ui .opblock .opblock-summary-path {
        max-width: 100%;
        padding: 0;
        margin: 5px 0 4px;
    }
    .swagger-ui .opblock .opblock-summary-method {
        padding: 3px;
        font-size: 12px;
    }
    .swagger-ui .responses-inner {
        overflow: scroll;
        overflow-y: auto;
        width: 100%;
        height: 100%;
    }
    .swagger-ui .responses-inner .responses-table {
        min-width: 900px !important;
    }
    .swagger-ui .response-col_links {
    display: table-cell;
    }
    .swagger-ui .model-title {
        font-size: 14px;
        word-break: break-word;
    }
    .swagger-ui .inner-object {
        overflow: scroll;
        overflow-y: auto;
        width: 100%;
        height: 100%;
    }
    .swagger-ui table.model tr.property-row td {
        display: block;
        word-break: break-all;
    }
    .swagger-ui table.model tr.property-row td:first-child {
        padding: 0;
    }
    .swagger-ui .model {
        margin-bottom: 15px;
        float: left;
        width: 100%;
    }
    .swagger-ui section.models .model-container:first-of-type {
        margin: 15px;
    }
    .swagger-ui .servers-title {
        margin-bottom: 15px;
        float: left;
        width: 100%;
    }
    .swagger-ui .scheme-container {
        padding-top: 0;
    }
}