@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
.col {
    display: inline;
}
.col.col-milestone {
    font-weight: bold;
    color: #3f51b5;
}
.e-gantt .e-gantt-chart .e-timeline-top-header-cell .e-gantt-top-cell-text,
.e-gantt .e-gantt-chart .e-timeline-single-header-cell .e-gantt-top-cell-text {
    text-align: center;
}
.e-gantt .e-gantt-chart .e-gantt-parent-taskbar-inner-div.e-row-expand {
    background: none;
    border: 3px solid rgba(97, 97, 97, 0.87);
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 14px !important;
    border-left: 4px solid rgba(97, 97, 97, 0.87);
    border-right: 4px solid rgba(97, 97, 97, 0.87);
    border-bottom: none;
}
.e-gantt .e-gantt-chart .e-gantt-parent-taskbar-inner-div.e-row-expand .e-task-label {
    color: rgba(97, 97, 97, 0.87);
    line-height: 14px !important;
}
.e-gantt .e-gantt-chart .e-right-label-container {
    margin-left: 10px;
}
.e-gantt .e-gantt-chart .e-gantt-child-taskbar-inner-div {
    background-color: #cccccc;
    border: 1px solid #cccccc;
}
.e-gantt .e-gantt-chart .e-milestone-top,
.e-gantt .e-gantt-chart .e-milestone-bottom {
    border-bottom-color: #3f51b5 !important;
    border-top-color: #3f51b5 !important;
}

.col-milestone.milestone-icon:before {
    content: "";
    background-color: #3f51b5;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    margin-right: 5px;flex-shrink: 0;
    margin-left: 2px;
    margin-bottom: 0px;
}

.col-milestone.milestone-icon {
    display: flex;
    align-items: center;
    margin-top: -10px;
}