.relative {
    position: relative;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.page-builder {
    display: flex;
}

.display-flex {
    display: flex;
}

.page-builder > .canvas-container,
.page-builder .main-canvas {
    display: flex;
}

.page-builder .main-canvas {
    flex: 1;
}

.drag-item {
    width: 100%;
    min-height: 45px;
}

.canvas.dropzone {
    justify-content: normal;
}

.task-content-block.DashBuilder {
    float: left;
    width: 100%;
    height: calc(100% - 80px);
    padding: 0px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    overflow-y: hidden;
    margin-top: 20px;
    background: #ecf0f1;
    border: 0;
}

.DashBuilder .col {
    padding: 0px;
    float: left;
    width: 100%;
    height: 100%;
}

.DashBuilder .canvas.empty.dropzone {
    height: 100%;
}

.DashBuilder .dropzone {
    padding: 20px;
    height: auto;
    background: #fff;
    border: 1px transparent;
    /* background-image: url(./images/line-texture.png); */
}

.DashBuilder .dropzone .dropzone {
    border: 1px solid #E2E7EB;
    background-color: #fff;
    justify-content: start;
    margin-bottom: 20px;
}

.DashBuilder .nav-tabs {
    background: #fff;
    border-color: #E3E5E8;
}

.DashBuilder .nav-tabs .nav-link {
    border: none;
    border-radius: 0;
}

.left-container {
    float: left;
    display: flex;
    align-items: center;
}

.DashBuilder .left-container .layout-header-block {
    width: auto;
}

.DashBuilder .nav-link {
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0px;
    width: calc((100% / 4) - 5px);
    text-align: center;
    color: #848484;
    font-size: 14px;
    transition: 0.1s ease-out;
    font-weight: 600;
}

.DashBuilder .nav-link.active {
    color: var(--default-color);
    font-weight: 600;
    border-bottom: 2px solid var(--default-color);
    transition: 0.3s ease-in;
}

.DashBuilder .dashboard-selection {
    width: 200px;
    height: 51px;
    position: absolute;
    right: 0px;
    top: -1px;
}

.DashBuilder .dashboard-selection .select-box.eq-width {
    height: 100%;
}

.DashBuilder .dashboard-selection .select-box select {
    height: 51px;
    border-radius: none;
}

.DashBuilder .page-builder {
    margin: 0px !important;
    float: left;
    width: 100%;
    height: 100%;
}

.DashBuilder .canvas-container.col-sm-9 {
    padding: 0px;
    max-height: 100%;
    overflow-y: auto;
    background: #fff;
    /* margin-bottom: 10px; */
    float: left;
    width: 100%;
    height: 100%;
}

.DashBuilder .dropzone-placeholder {
    color: #bdbdbd;
    font-size: 24px;
    font-weight: 300;
}

.DashBuilder .col-sm-3 {
    /* padding: 0px;
    background: #fff;
    border-left: 1px solid #E3E5E8; */
    height: 100%;
}

.DashBuilder .card {
    border-left: none;
    border-right: none;
    float: left;
    width: 100%
}

.DashBuilder .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: none;
}

.DashBuilder .accordion > .card .card-header {
    margin-bottom: 0px;
    height: 60px;
    line-height: 60px;
}

.DashBuilder .btn.btn-link {
    float: left;
    width: 100%;
    height: 100%;
    color: #444444;
    font-size: 16px;
    padding: 0px 20px;
    border: 0;
    /* border-bottom: 1px solid #E3E5E8; */
    text-decoration: none;
    text-align: left;
}

.DashBuilder .ResetBtn {
    color: #fff;
    background-color: #2E3243;
    border: 0;
    font-size: 14px;
    padding: 0 15px;
    border-radius: 5px;
    height: 35px;
}

.canvas-body {
    float: left;
    width: 100%;
    /*height: calc(100% - 60px);*/
    overflow-y: auto;
}

.canvas-footer {
    float: left;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.IconIn:before {
    content: "";
    display: inline-block;
    background: url("./images/sprite-v1.svg") no-repeat;
}

.ResetIcon:before {
    background-position: -382px -409px;
    width: 13px;
    height: 12px;
    margin-right: 7px;
}

#noanim-tab-tab-builder:before {
    content: "";
    display: inline-block;
    background: var(--sprite-two) no-repeat -213px -448px;
    width: 19px;
    height: 19px;
    margin-right: 5px;
    vertical-align: text-top;
}

#noanim-tab-tab-builder.active:before {
    content: "";
    display: inline-block;
    background: var(--sprite-two) no-repeat -213px -426px;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    vertical-align: text-top;
}

#noanim-tab-tab-Preview:before {
    content: "";
    display: inline-block;
    background: var(--sprite-two) no-repeat -239px -448px;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    vertical-align: text-top;
}

#noanim-tab-tab-Preview.active:before {
    content: "";
    display: inline-block;
    background: var(--sprite-two) no-repeat -239px -426px;
    width: 19px;
    height: 19px;
    margin-right: 5px;
    vertical-align: text-top;
}

.DashBuilder .palette {
    padding: 0px;
    border: none;
}

.DashBuilder .drag-item {
    float: left;
    width: 100%;
    height: 45px;
    border: 1px dashed rgba(74, 74, 74, 0.2);
    background-color: rgba(236, 240, 241, 0.5);
    color: #444444;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: relative;
    cursor: move;
}

.drag-item .drag-icon {
    float: left;
    line-height: 16px;
    font-weight: 600;
}

.drag-item .drag-icon:before {
    content: "";
    display: inline-block;
    background: url(./images/sprite-v1.svg) no-repeat;
    background-position: -370px -408px;
    width: 8px;
    height: 13px;
    margin-right: 13px;
    vertical-align: bottom;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item {
    border: 1px solid #E3E5E8;
    background-color: rgba(63, 169, 245, 0.05);
    color: #4A4A4A;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 20px 0px 20px;
    height: auto;
    display: block;
    min-height: auto;
    background-image: url(./images/line-texture.png);
}

.DashBuilder .main-canvas .canvas.dropzone .inner-layout-builder .drag-item {
    border: 1px dashed var(--default-color);
    background-color: var(--col-active);
    color: #444444;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    height: 45px;
    padding: 0 20px;
}

.DashBuilder .layout-builder-titles {
    height: 30px;
    line-height: 30px;
    background: var(--default-color);
    text-align: center;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    padding: 0 13px;
    width: 140px;
    margin-bottom: 10px;
    border-radius:  0 0 5px 5px;
}

.DashBuilder .layout-header-block-title {
    float: left;
    height: 100%;
    display: flex;
    align-items: center;
}

.DashBuilder .layout-header-block {
    min-width: 250px;
    height: 30px;
    border: none;
    padding: 0px 10px;
    margin-left: 15px;
    float: left;
    background: #fff;
    border: 1px solid #E3E5E8;
    color: #444444;
}

.DashBuilder .layout-header-block::placeholder {
    color: #bdbdbd;
}

.DashBuilder .drag-item .m-0.form-group label.col-sm-12 {
    padding: 0px;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item .col-sm-3 {
    min-height: auto;
}

.DashBuilder-modal {
    width: 100%;
    height: auto;
    float: left;
}

.DashBuilder-modal .task-input-container {
    width: 50%;
    margin: 5px 0px;
    margin-right: 10px;
    display: inline-block;
    float: left;
    min-height: 40px;
    line-height: 40px;
}

.DashBuilder-modal .task-input-container .select-box.eq-width {
    margin: 0px;
    float: right;
    width: calc(100% - 100px);
}

.DashBuilder-modal .task-input-container:first-child {
    width: 100%;
}

.DashBuilder-modal .stroke-color-select {
    width: 100%;
    display: inline-block;
    height: 40px;
    line-height: 40px;
}

.DashBuilder-modal label {
    float: left;
    color: #405364;
    font-size: 14px;
    font-weight: 500;
    width: 90px;
    margin-right: 10px;
    overflow: hidden;
}

.DashBuilder-modal .task-input-container.check {
    width: 100%;
}

.DashBuilder-modal .task-input-container.check .select-box:after {
    content: '';
    display: none;
}

.DashBuilder-modal .task-input-container.check .select-box.eq-width {
    float: left;
    width: 20px;
    height: 40px;
    line-height: 35px;
}

.DashBuilder-modal .graph-proprties {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    overflow: auto;
    max-height: 350px;
}

.DashBuilder-modal .graph-proprties-axis {
    float: left;
    width: 50%;
    margin-right: 10px;
}

.DashBuilder-modal .graph-proprties-axis .task-input-container {
    width: 100%;
}

.DashBuilder-modal .graph-proprties-color {
    width: calc(50% - 10px);
}

.DashBuilder-modal .graph-proprties-color .task-input-container:first-child {
    width: 50%;
}

.DashBuilder-modal .graph-proprties-color .task-input-container {
    float: right;
}

.DashBuilder .chart-icons {
    float: right;
    height: 45px;
    display: flex;
    align-items: center;
}

.DashBuilder .settings-icon {
    width: 20px;
    height: 20px;
    background: var(--default-color);
    border-radius: 2px;
    margin-right: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.26);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.DashBuilder .settings-icon:before {
    content: "";
    display: inline-block;
    background: url("./images/sprite-v1.svg") no-repeat;
    background-position: -358px -412px;
    width: 7px;
    height: 8px;
    zoom: 1.35;
}

.DashBuilder .delete-icon {
    width: 20px;
    height: 20px;
    background: #E14845;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.26);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.DashBuilder .delete-icon:before {
    content: "";
    display: inline-block;
    background: url("./images/sprite-v1.svg") no-repeat;
    background-position: -346px -412px;
    width: 6px;
    height: 7px;
    zoom: 1.5;
}

.DashBuilder .save-in-icon:before {
    content: "";
    display: inline-block;
    background: url("./images/sprite-v1.svg") no-repeat -414px -412px;
    width: 7px;
    height: 7px;
}

.DashBuilder .save-in-icon {
    width: 15px;
    height: 15px;
    background: #28a745;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.26);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
}

.DashBuilder .tab-content {
    float: left;
    width: 100%;
    height: calc(100% - 50px);
}

.DashBuilder .accordion {
    float: left;
    width: 100%;
}

.DashBuilder .accordion .card-body {
    float: left;
    width: 100%;
    border-top: 1px solid #E3E5E8;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item .col-sm-12 {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item .mt-3 {
    margin-top: 0 !important;
}

.DashBuilder .main-canvas .canvas.dropzone .inner-layout-builder .drag-item .form-group label span {
    width: auto;
    font-weight: 600;
    text-align: left;
}

.DashBuilder .main-canvas .canvas.dropzone .inner-layout-builder .drag-item .form-group label {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.DashBuilder .main-canvas .canvas.dropzone .inner-layout-builder .drag-item .form-group {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item .inner-layout-builder .col-sm-6 {
    padding-left: 0;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item .inner-layout-builder .col-sm-6:last-child {
    padding-right: 0;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item .mt-3 svg {
    position: absolute;
    right: 20px;
    top: 13px;
    cursor: pointer;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item .inner-layout-builder .col-sm-3 {
    padding: 0;
    padding-right: 15px;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item .inner-layout-builder .col-sm-3:last-child {
    padding-right: 0;
}

.DashBuilder .canvas-properties {
    float: left;
    width: 100%;
    height: 100%;
    background: #fff;
    border-left: 1px solid #E3E5E8;
    padding: 0;
}

.inner-layout-builder {
    float: left;
    width: 100%;
    margin: 0 !important;
}

.inner-layout-builder .col-sm-4 {
    padding: 0;
    padding-right: 15px;
}

.inner-layout-builder .col-sm-4:last-child {
    padding-right: 0;
}

.DashBuilder .main-canvas {
    float: left;
    width: 100%;
    height: 100%;
}

.drag-item + div {
    margin-top: 0;
}

.drag-item {
    margin-bottom: 20px;
}

.drag-item:last-child {
    margin-bottom: 0;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item:last-child {
    margin-bottom: 10px;
}

.drag-item .form-group {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item .inner-layout-builder .dropzone .drag-item {
    height: auto;
    min-height: 45px;
}

.SaveBtn {
    border: 0;
    background: #28a745;
    color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.26);
    padding: 0 15px;
    border-radius: 5px;
    height: 35px;
    position: relative;
}

.SaveIcon:before {
    content: "";
    background: url(./images/sprite-v1.svg) no-repeat -108px -116px;
    width: 14px;
    height: 11px;
    margin-right: 7px;
}

.ibt-body.dynamic-dashboard .row {
    margin-left: 0;
    margin-right: 0;
}

.ibt-body.dynamic-dashboard .col-sm-4 {
    margin-right: 15px;
    flex: 30%;
    padding: 0;
    width: calc((100% / 3) - 12px);
}

.ibt-body.dynamic-dashboard .row .col-sm-4:last-child {
    margin-right: 0;
}

.stakeholders-header {
    margin: 10px 0;
}

.ibt-body.dynamic-dashboard .col-sm-3 {
    margin-right: 15px;
    flex: 23%;
    padding: 0;
    display: flex;
    width: calc((100% / 4) - 12px);
}

.ibt-body.dynamic-dashboard .row .col-sm-3:last-child {
    margin-right: 0;
}

.ibt-body.dynamic-dashboard .col-sm-6 {
    margin-right: 15px;
    flex: 49%;
    padding: 0;
}

.ibt-body.dynamic-dashboard .row .col-sm-6:last-child {
    margin-right: 0;
}

.ibt-body.dynamic-dashboard .col-sm-12 {
    padding: 0;
}

.dynamic-dashboard .ibt-progress-table {
    margin-bottom: 0;
}

.dynamic-dashboard .ind-small-tab-dashboard {
    margin-right: 0;
}

.ibt-body.dynamic-dashboard .col-sm-3:last-child {
    margin-right: 0;
}

.ibt-body.dynamic-dashboard .col-sm-6 {
    margin-right: 15px;
    flex: 49% 1;
    padding: 0;
    width: calc((100% / 2) - 8px);
}

.task-input-container.container-flex {
    display: flex;
    align-items: center;
    width: 100%;
}

.task-input-container.container-flex .new-task-input {
    width: 50%;
}

.task-input-container.container-flex .select-chk-block {
    width: 50%;
    padding-left: 50px;
}

.task-input-container.container-flex .select-chk-block label {
    width: 100%;
}

.DashBuilder-modal .select-chk-block .control {
    width: auto;
}

.DashBuilder-modal .task-input-container {
    margin: 0;
}

.task-input-container.container-flex .select-chk-block {
    width: auto;
}

.DashBuilder-modal .task-input-container {
    width: 100%;
}

.DashBuilder-modal .task-input-container .select-box.eq-width {
    width: 100%;
}

.DashBuilder-modal .task-input-container .select-box.eq-width select {
    height: 50px;
    border: 0;
    border-bottom: 1px solid #ECEFF1;
    padding-left: 0;
    border-radius: 0px;
}

.DashBuilder-modal .task-input-container.select-line {
    margin-top: 10px;
}

.main-container-wrap {
    float: left;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.main-container-wrap .task-input-container {
    width: 50%;
}

.main-container-wrap .task-input-container.wid-3 {
    width: 33.33%;
}

.main-container-wrap .task-input-container:first-child {
    width: 50%;
}

.DashBuilder-modal .task-input-container label {
    text-align: left;
    color: #848484;
    line-height: normal;
    margin: 10px 0 0 0;
}

.DashBuilder-modal .task-input-container.margin-b-20 {
    margin-bottom: 20px;
}

.DashBuilder-modal {
    padding: 20px;
}

.canvas.dropzone .modal-body {
    padding: 0;
    height: 400px;
    overflow-y: auto;
}

.canvas.dropzone .bsy-modal {
    padding: 0;
}

.DashBuilder .col-sm-3 .left-container .layout-header-block {
    min-width: auto;
    width: 60px;
}

.DashBuilder .main-canvas .col-sm-3 .canvas.dropzone .inner-layout-builder .col-sm-3 .drag-item .form-group label span {
    width: 110px;
}

.text-hover span {
    font-size: 0;
    min-width: auto;
}

.DashBuilder .col-sm-3 .left-container.text-hover .layout-header-block {
    min-width: 125px;
}

.DashBuilder .main-canvas .canvas.dropzone .inner-layout-builder .col-sm-3 .drag-item .form-group label .text-hover span {
    width: auto;
}

.ibt-body.dynamic-dashboard .col-sm-6, .ibt-body.dynamic-dashboard .col-sm-4 {
    display: flex;
}

.task-content-block.DashBuilder.with-db-header {
    height: calc(100% - 95px);
    margin-top: 0;
}

.modal-body .DashBuilder-modal .graph-proprties {
    overflow: unset;
    max-height: unset;
}

.DashBuilder .accordion > .card .card-header {
    position: relative;
}

.DashBuilder .accordion > .card .card-header:before {
    content: "";
    background: url(./images/sprite.svg) no-repeat -454px -355px;
    width: 8px;
    height: 16px;
    position: absolute;
    right: 25px;
    top: 23px;
    transition: .3s ease-out;
}

.DashBuilder .accordion > .card .card-header.active:before {
    transform: rotate(90deg);
}

.floating-summary-body img{
    max-width: 100%;
}

@media (max-width: 1200px) {
    .blue-lister {
        width: 100%;
    }

    .ibt-body.dynamic-dashboard .col-sm-6 {
        flex: 48% 1;
    }
}

@media (max-width: 1024px) {

    .ibt-body.dynamic-dashboard .col-sm-4 {
        flex: 100%;
        margin-right: 0;
        max-width: none;
    }

    .ibt-body.dynamic-dashboard .col-sm-4:last-child .ibt-card .ind-ibt-rating {
        margin-bottom: 0;
    }

    .ibt-body.dynamic-dashboard .col-sm-3 {
        flex: 100%;
        margin-right: 0;
        max-width: none;
    }

    .ibt-body .row .col-sm-6:last-child .blue-lister {
        margin-bottom: 0;
    }

    .scrollable table {
        width: 100%;
        min-width: 900px !important;
    }

    .ibt-body.dynamic-dashboard .col-sm-6 {
        flex: 100% 1;
        margin-right: 0;
    }

}

@media (max-width: 767px) {
    .DashBuilder .nav-item {
        flex: 0 0 50%;
    }

    .DashBuilder .nav-tabs {
        background: #fff;
        width: calc(100% - 140px);
    }

    .DashBuilder .dashboard-selection {
        width: 140px;
    }

    .DashBuilder .dashboard-selection .select-box select {
        padding: 0px 5px;
    }
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
}

/* Entity dashboard  */

.data-view .form-detail-top.entity-top {
    max-height: 107px;
    border-bottom: 0;
}

.linked-collection-list {
    float: left;
    margin-top: 7px;
}

.linked-collection-list li {
    float: left;
    width: auto;
    height: 35px;
    min-width: 100px;
    border: 1px solid var(--default-color);
    color: var(--default-color);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    border-radius: 5px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.linked-collection-list li.active {
    background: var(--default-color);
    color: #fff;
}

.data-view.field-form .data-holder.entity-dashboard-list {
    height: calc(100% - 129px);
    position: relative;
}

.table-holder {
    float: left;
    width: 100%;
    height: 100%;
    max-width: calc(100vw - 50px);
    background: #fff;
    border: 1px solid rgba(64, 83, 100, .15);
    overflow-y: auto;
    max-height: 100%;
}

.entity-dashboard-table {
    float: left;
    width: 100%;
    margin: 0;
}

.entity-dashboard-table thead tr th {
    color: #565656;
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
    border-top: 0;
    border-bottom: 0;
    padding: 12px 25px;
    white-space: nowrap;
    vertical-align: middle;
    text-transform: uppercase;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    top: 0;
    background: #fff;
}

.entity-dashboard-table thead tr th:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #e3e5e8;
    display: block;
    bottom: 0;
}

.entity-dashboard-table tbody tr td {
    color: #555;
    font-size: 16px;
    line-height: 16px;
    border-top: 0;
    border-bottom: 1px solid #e3e5e8;
    padding: 12px 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    white-space: nowrap;
    max-width: 260px;
    max-height: 16px;
    overflow: hidden;
    word-break: break-word;
}

.floating-side-panel {
    float: left;
    width: 400px;
    height: 100%;
    background-color: #ECF0F1;
    border: 1px solid #E3E5E8;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    right: 0;
    top: 0;
}

.floating-side-panel-header {
    float: left;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #E3E5E8;
}

.floating-side-panel-header h5 {
    float: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
}

.floating-side-panel-body {
    float: left;
    width: 100%;
    height: calc(100% - 60px);
    padding: 15px 20px;
    overflow-y: auto;
}

.floating-summary {
    float: left;
    width: 100%;
    height: auto;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 15px 20px;
    position: relative;
}

.floating-summary-header {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.floating-summary-header h5 {
    float: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
}

.floating-summary-body {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 0;
    padding: 0 20px 20px 20px;
}

.floating-summary-body:last-child {
    margin-bottom: 0;
}

.floating-summary-user-block {
    float: left;
    width: 100%;
}

.floating-summary-user-left {
    float: left;
    width: 130px;
    height: 130px;
    border-radius: 5px;
    margin-right: 15px;
}

.floating-summary-user-left img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.floating-summary-user-right {
    float: left;
    width: calc(100% - 145px);
}

.floating-summary-body .floating-summary-user-right p {
    float: left;
    width: 100%;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.44px;
    margin-bottom: 0;
}

.floating-summary-body p {
    font-size: 14px;
    line-height: 18px;
    color: #444444;
    margin-bottom: 20px;
}

.floating-summary-body p:last-child {
    margin-bottom: 0;
}

.floating-summary-body ul {
    float: left;
    width: 100%;
}

.floating-summary-body ul li {
    float: left;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: #444444;
    margin-bottom: 5px;
    padding-left: 12px;
    position: relative;
}

.floating-summary-body ul li:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #444444;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 7px;
}

.floating-boiler-plate {
    float: left;
    width: 100%;
}

.floating-boiler-image {
    float: left;
    width: 100%;
    height: 215px;
    margin-bottom: 15px;
    border-radius: 5px;
}

.floating-boiler-image img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.floating-summary-body .subhead {
    float: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 12px;
}

.floating-summary-body iframe {
    float: left;
    width: 100% !important;
    height: 215px !important;
    margin-bottom: 15px;
}

.floating-summary-body p a {
    color: var(--default-color);
}

.boiler-slider {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: calc(50% - 15px);
}

.boiler-slider nav {
    float: left;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: #4A90E2;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.boiler-slider-left {
    position: relative;
    left: -25px;
}

.boiler-slider-right {
    position: relative;
    right: -25px;
}

.boiler-slider nav:before {
    content: "";
    /*background: url(../images/sprite.svg) no-repeat -385px -474px;*/
    width: 14px;
    height: 8px;
    display: inline-block;
    zoom: .7;
}

.boiler-slider nav.boiler-slider-left:before {
    transform: rotate(90deg);
}

.boiler-slider nav.boiler-slider-right:before {
    transform: rotate(-90deg);
}

.floating-summary.step-viewer {
    padding: 0;
    background: transparent;
    box-shadow: none;
}

.floating-summary-body .floating-step {
    float: left;
    width: calc(100% - 20px);
    padding-left: 20px;
}

.floating-summary-body .floating-step li {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 0;
}

.floating-summary-body .floating-step li:before {
    content: none;
}

.floating-summary-body .floating-step li:last-child {
    margin-bottom: 0;
}

.floating-step-header {
    float: left;
    width: 100%;
    margin-bottom: 5px;
}

.floating-step-header h5 {
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
}

.floating-step-header p {
    float: left;
    width: 100%;
    color: #848484;
    font-size: 14px;
    letter-spacing: 0.44px;
    line-height: 22px;
    text-align: justify;
}

.floating-step.mode-1 li {
    float: left;
    height: auto;
    padding: 15px 20px;
    width: 100%;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
}

.floating-step.mode-1:before {
    content: "";
    width: 2px;
    height: calc(100% - 142px);
    background: var(--default-color);
    position: absolute;
    left: 3px;
    top: 31px;
}

.floating-step.mode-1 li:before {
    content: "";
    width: 8px;
    height: 8px;
    background: var(--default-color);
    position: absolute;
    top: 23px;
    left: -20px;
    border-radius: 50%;
}

.floating-step.mode-1 li:after {
    content: "";
    width: 16px;
    height: 16px;
    background: #ffffff;
    position: absolute;
    left: -5px;
    top: 19px;
    transform: rotate(45deg);
}

.floating-step.mode-2:before {
    content: "";
    width: 2px;
    height: calc(100% - 152px);
    background: var(--default-color);
    position: absolute;
    left: 3px;
    top: 17px;
}

.floating-step.mode-2 li:before {
    content: "";
    width: 8px;
    height: 8px;
    background: var(--default-color);
    position: absolute;
    top: 9px;
    left: -20px;
    border-radius: 50%;
}

.floating-step.mode-2 .floating-step-body {
    float: left;
    width: 100%;
    height: auto;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    position: relative;
}

.floating-step.mode-2 .floating-step-body:after {
    content: "";
    width: 16px;
    height: 16px;
    background: #ffffff;
    position: absolute;
    left: -5px;
    top: calc(50% - 8px);
    transform: rotate(45deg);
}

.floating-step.mode-3:before {
    content: "";
    width: 2px;
    height: calc(100% - 129px);
    background: var(--default-color);
    position: absolute;
    left: 3px;
    bottom: 47px;
}

.floating-step.mode-3 .floating-step-body {
    float: left;
    width: 100%;
    height: auto;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    position: relative;
}

.floating-step.mode-3 .floating-step-body:before {
    content: "";
    width: 8px;
    height: 8px;
    background: var(--default-color);
    position: absolute;
    top: 37px;
    left: -20px;
    border-radius: 50%;
}

.floating-step.mode-3 .floating-step-body:after {
    content: "";
    width: 16px;
    height: 16px;
    background: #ffffff;
    position: absolute;
    left: -5px;
    top: 33px;
    transform: rotate(45deg);
}

.floating-summary-user-left .floating-boiler-image {
    height: auto;
}

.settings-panel.dynamic-cp .sketch-picker {
    position: fixed;
    left: calc(50% - 110px);
    top: calc(50% - 157px);
}

.chart-holder {
    padding: 20px;
}

/******/
/* Growth Enable */

.growth-container {
    float: left;
    width: 100%;
}

.growth-row,
.build-row {
    float: left;
    width: 100%;
} 

.each-growth-block {
    float: left;
    width: 100%;
    height: 140px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 0.5px 3px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.three-col .each-growth-block {
    width: calc(33.33% - 14px);
    margin-right: 20px;
}

.three-col .each-growth-block:nth-child(3n) {
    margin-right: 0;
}

.growth-graph {
    float: left;
    width: 100%;
}

.growth-header {
    float: left;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.growth-body {
    float: left;
    width: 100%;
    padding: 0 20px 20px;
}

.growth-header h5 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    color: #444444;
}

.growth-header .select-box {
    width: auto;
}

.growth-header .select-box select {
    height: 25px;
    border: 0;
    padding-left: 0;
    padding-right: 20px;
    color: #444444;
}

.growth-header .select-box:after {
    right: 6px;
    top: 12px;
}

.growth-boiler {
    float: left;
    width: 100%;
    padding: 20px;
    position: relative;
}

.growth-boiler-image {
    float: left;
    width: 152px;
    height: 100px;
    margin-right: 15px;
    border-radius: 5px;
}

.growth-boiler-image img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.growth-boiler-details {
    float: left;
    width: calc(100% - 167px);
}

.growth-boiler-details-header {
    float: left;
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.growth-boiler-details-header h5 {
    float: left;
    font-size: 20px;
    color: #444444;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    margin-right: 20px;
}

.edit-block {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    color: #444444;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.add-block {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    color: var(--default-color);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.edit-block:after {
    content: "";
    background: url(images/sprite-v1.svg) no-repeat -688px -117px;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-left: 5px;
}

.add-block:before {
    content: "";
    background-position: -704px -118px;
    width: 9px;
    height: 9px;
    display: inline-block;
    margin-right: 7px;
}

.growth-boiler-details p {
    float: left;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    color: #848484;
}

.growth-text-only {
    float: left;
    width: 100%;
    padding: 20px;
}

.text-only .growth-boiler-details {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.text-only .growth-boiler-details:last-child {
    margin-bottom: 0;
}

.each-growth-block.text-only {
    height: auto;
    min-height: 140px;
}

.read-more-link {
    color: var(--default-color) !important;
    font-weight: 600;
    text-transform: uppercase;
}

.read-more-link:after {
    content: "";
    /*background: url(../images/sprite-v1.svg) no-repeat -670px -119px;*/
    width: 12px;
    height: 8px;
    display: inline-block;
    margin-left: 7px;
}

.each-growth-record {
    float: left;
    width: calc((100% / 4) - 15px);
    margin-right: 20px;
    margin-bottom: 20px;
}

.each-growth-record:nth-child(4n) {
    margin-right: 0;
}

.growth-record-block {
    float: left;
    width: 100%;
    padding-top: 5px;
}

.growth-record-image {
    float: left;
    width: 100%;
    height: 315px;
}

.growth-record-image img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.growth-record-name {
    float: left;
    width: 100%;
    margin-top: 15px;
}

.growth-record-name h5 {
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
}

.growth-collection-record {
    float: left;
    width: 100%;
    padding-top: 15px;
}

.growth-collection-record-header {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.growth-collection-record-header p {
    float: left;
    width: 100%;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0;
    color: #848484;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.growth-collection-record-header h5 {
    float: left;
    width: 100%;
    font-size: 18px;
    line-height: 23px;
    color: #444444;
    font-weight: 600;
}

.growth-collection-record-footer {
    float: left;
    width: 100%;
}

.growth-collection-record-footer ul {
    float: left;
    width: 100%;
    margin-bottom: 0;
}

.growth-collection-record-footer ul li {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E4E6E9;
}

.growth-collection-record-footer ul li p {
    float: left;
    width: 50%;
    font-size: 12px;
    text-transform: uppercase;
}

.growth-collection-record-footer ul li h5 {
    float: left;
    width: 50%;
    font-size: 12px;
    font-weight: 600;
    text-align: right;
}

.growth-collection-record-footer ul li:last-child {
    border-bottom: 0;
}

.four-col .each-growth-block {
    float: left;
    width: calc((100% / 4) - 15px);
    margin-right: 20px;
}

.four-col .each-growth-block:nth-child(4n) {
    margin-right: 0;
}

.each-growth-block.unique-block {
    height: auto;
}

.growth-unique-record {
    float: left;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.growth-unique-record p {
    float: left;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: uppercase;
    color: #848484;
    margin-bottom: 5px;
}

.growth-unique-record h5 {
    float: left;
    width: 100%;
    font-size: 18px;
    line-height: 23px;
    color: #444444;
    font-weight: 600;
}

.each-growth-block .entity-dashboard-table thead tr th:first-child {
    border-radius: 5px 0 0 0;
}

.each-growth-block .entity-dashboard-table thead tr th:last-child {
    border-radius: 0 5px 0 0;
}

.task-details-info-wrap li h5 {
    color: #444444;
    font-size: 14px;
    font-weight: 600;
}

.task-details-info-wrap li p {
    color: #848484;
    text-transform: uppercase;
}

.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}

.growth-mod .growth-wrapper {
    padding: 10px 15px;
}

.growth-content-body .blue-lister {
    border: 1px solid #EBEDF4;
    border-radius: 15px;
}

.growth-content-body .ind-small-tab-dashboard {
    border: 1px solid #EBEDF4;
    border-radius: 15px;
    margin-bottom: 3px;
}

.growth-boiler-details-header p {
    margin-bottom: 0;
}

.growth-boiler-details-header.growth-enable-outer {
    margin-bottom: 15px;
}

.growth-boiler .form-table {
    border-radius: 5px;
    margin-top: 11px;
    margin-bottom: 20px;
}

.growth-boiler .form-table:last-child {
    margin-bottom: 0;
}

.growth-unique-record.button-only .general-btn {
    padding: 0 15px;
}

.growth-unique-record.button-only {
    padding: 0;
    margin-bottom: 10px;
}

.growth-unique-record.button-only .button-block {
    margin: 0;
}

.growth-unique-record.button-only .growth-boiler-details-header {
    margin: 0;
}

.growth-main-wrap .mt-3 > .row .each-growth-block.text-only {
    height: calc(100% - 20px);
}


.mast-head-banner-container {
    float: left;
    width: 100%;
    overflow: hidden;
}

.mast-head-banner-container:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 15%);
    position: absolute;
    left: 0;
    top: 0;
}

.mast-head-banner-container.full-banner {
    position: absolute;
    left: 0;
    top: 0;
}

.mast-head-banner-inner {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 70px;
    position: relative;
}

.carousel-text {
    float: left;
    width: auto;
    max-width: 40%;
}

.carousel-text > div:first-child > p {
    float: left;
    width: 100%;
    font-size: 22px;
    line-height: normal;
    font-weight: 600;
    color: var(--white);
    margin-bottom: 5px;
}

.carousel-text > div > p {
    font-size: 16px;
    color: var(--white);
    margin-bottom: 15px;
}

.carousel-text a {
    float: left;
    width: auto;
    padding: 0 20px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--default-color);
    color: var(--white);
}

.modal-body .task-content-block.DashBuilder {
    margin-top: 0;
}

.DashBuilder .main-canvas .canvas.dropzone .drag-item .mt-3 .ql-formats svg {
    position: relative;
    right: 0;
    top: 0;
}

.each-settings-panel-wrap-body .ql-toolbar.ql-snow + .ql-container.ql-snow {
    float: left;
    width: 100%;
}

.ql-editor {
    font-weight: 400;
}

.remove-file-wrapper {
    float: left;
    width: auto;
    position: relative;
}

.remove-file-wrapper img {
    float: left;
    width: 100%;
    max-width: 100px;
    height: 100px;
    object-fit: contain;
    border: 1px solid #e5e5e5;
}

.each-inp-field.file-field {
    margin-top: 20px;
}

.remove-file {
    float: left;
    width: 25px;
    height: 25px;
    padding: 0;
    line-height: 25px;
    border: 0;
    color: #ffffff;
    font-size: 14px;
    background: #f44336;
    border-radius: 50%;
    position: absolute;
    right: -13px;
    top: -8px;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.growth-wrapper .col-sm-6,
.growth-wrapper .col-sm-4,
.growth-wrapper .col-sm-3,
.growth-wrapper .col-sm-8 {
    display: flex;
    flex-direction: column;
}

.growth-enable-table .table.entity-dashboard-table tbody tr td {
    line-height: 22px;
}

.growth-enable-table .table.entity-dashboard-table tbody tr td:first-child {
    line-height: 22px;
}

.full-width.growth-mod.event-view-modal .modal-header {
    padding: 0;
    background: transparent;
    height: 0;
    margin: 0;
    box-shadow: none;
    border: 0;
}

.event-view-modal .modal-header .close {
    position: fixed;
    right: 40px;
    top: 25px;
    background: rgb(236 240 241 / 65%);
    z-index: 9;
    padding: 7px;
    border-radius: 50%;
}

.event-view-modal .modal-header .close:before {
    zoom: .8;
}

.event-view-modal .growth-wrapper {
    padding-top: 0;
}

.form-set .side-tab .tab-content .tab-pane > .col-sm-4,
.form-set .side-tab .tab-content .tab-pane > .col-sm-6,
.form-set .side-tab .tab-content .tab-pane > .col-sm-3,
.form-set .side-tab .tab-content .tab-pane > .col-sm-8 {
    display: flex;
}

.form-set .growth-boiler .form-table .table tbody tr td:first-child {
    padding: 12px 25px;
}

.col-sm-6 .col-sm-6 .growth-enable-table .table.entity-dashboard-table tbody tr td:first-child {
    width: 45%;
}

.DashBuilder .col-sm-3 {
    height: 100%;
    padding: 0;
}

.canvas-container .floating-side-panel {
    position: relative;
    left: 0;
    width: 100%;
}

/* GE Mobile View */


.ge-float-summary {
    padding: 0;
    overflow: hidden;
}

.ge-float-summary .ge-payment {
    margin: 0;
}

.ge-accordion .accordion-header h5, .ge-mob-preview .accordion-header h5 {
    float: left;
    width: 100%;
    color: #1E323B;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    cursor: pointer;
}

.ge-accordion .floating-summary-user-left,
.ge-mob-preview .floating-summary-user-left {
    width: 100%;
    height: auto;
    margin-top: 10px;
}

.ge-accordion .floating-summary-user-left .floating-summary-body,
.ge-mob-preview .floating-summary-user-left .floating-summary-body {
    margin-bottom: 6px;
}

.ge-mob-preview .floating-summary-user-left .floating-summary-body:last-child,
.ge-accordion .floating-summary-user-left .floating-summary-body:last-child {
    margin-bottom: 0;
}

.wa-chat-main-right-header.ge-header {
    height: 70px;
    display: flex;
    align-items: center;
}

.wa-chat-main-right-header.ge-header h5 {
    font-weight: 600;
    color: var(--default-color);
}

.ge-mob-body .container {
    max-width: 100%;
    padding: 0;
    margin: 0;
}

.ge-mob-body .ge-accordion {
    box-shadow: none;
    border-bottom: 1px solid #dee0e3;
    border-radius: 0;
    margin: 0;
}

.ge-mob-body .profile-entry-data .general-btn {
    height: 30px;
    min-width: auto;
    padding: 0 10px;
    font-size: 12px;
}

.ge-mob-body .details-entry-left label {
    font-size: 12px;
    line-height: 14px;
}

.ge-mob-body .details-entry-left {
    padding: 0 10px;
}

.ge-mob-body .ge-float-summary {
    box-shadow: none;
    border-bottom: 1px solid #dee0e3;
    margin: 0;
    border-radius: 0;
}

.ge-mob-body .clipboard-wrap {
    margin: 0;
}

.ge-mob-preview .floating-summary .growth-enable-table .table.entity-dashboard-table tbody tr td:first-child {
    width: 32%;
}

.check-line {
    float: left;
    width: 100%;
    padding: 20px;
}

.check-line .control {
    font-size: 16px;
    color: var(--default-grey);
}

.floating-summary .floating-summary {
    margin: 0;
    box-shadow: none;
    border-top: 1px solid #e3e5e8;
    border-radius: 0;
}

.modal-body .task-content-block.DashBuilder.ge-mob-builder .modal-header {
    background: var(--default-color);
}

.modal-body .task-content-block.DashBuilder.ge-mob-builder .modal-header h5 {
    padding: 0;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
}

.ge-mob-body .ge-accordion .floating-summary-body {
    padding: 0;
}

.ge-mob-body .ind-small-tab-dashboard {
    box-shadow: none;
    border-top: 1px solid #dee0e3;
    margin: 0;
}

.ge-mob-body .wa-payment-wrap {
    float: left;
    padding: 20px 20px 10px 20px;
    border-top: 1px solid #dee0e3;
}

.ge-mob-body .floating-summary-header {
    padding: 0 20px;
}

.floating-summary-body.ge-description {
    padding: 20px;
}

.growth-wrapper .float-button-box {
    width: 50px;
    height: 50px;
    color: var(--white);
    z-index: 10;
    right: 30px;
    bottom: 30px;
}

.inline-flex-end {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.inline-flex-end .select-box {
    max-width: 250px;
}

.growth-content-body .form-table .table.entity-dashboard-table tbody tr td .wf-btn-block {
    position: absolute;
    right: 0;
    top: 16px;
}

.growth-content-body .form-table .table.entity-dashboard-table tbody tr td:first-child .form-table-edit p {
    line-height: normal;
}

.pointer-label:first-child {
    display: block !important;
}

.growth-boiler-details-header.body-pointer {
    margin-bottom: 12px;
    margin-top: -4px;
}

.growth-enable-table .table.entity-dashboard-table tbody tr td:first-child {
    width: 40%;
}

.growth-enabled .growth-nav {
    border-color: transparent;
    border-radius: 8px;
}

.growth-enabled .growth-nav .nav.flex-column.nav-pills {
    margin-bottom: 20px;
}

.each-builder-row {
    float: left;
    width: 100%;
}

.each-builder-row .form-table.fixed-header {
    margin-bottom: 20px;
}

.each-growth-block .growth-boiler .each-growth-block:last-child {
    margin-bottom: 0;
}

.each-growth-block.unique-block {
    position: relative;
    left: 20px;
    max-width: calc(100% - 40px);
}

.growth-nav .nav-link {
    padding: 0 20px;
}

.modal-body .task-content-block.DashBuilder .close:before {
    content: none;
}

.modal-body .task-content-block.DashBuilder .close {
    position: absolute;
    right: 15px;
    top: 25px;
}

.blue-lister {
    box-shadow: none;
}

.blue-lister .grid-header {
    border-bottom: 0;
}

.blue-lister .button-anchor {
    border: 0;
}

.blue-lister .page-block-header h5 {
    font-weight: bold;
}

.legend-limit {
    margin-top: 25px;
    max-height: 250px;
    overflow-y: auto;
    padding-right: 10px;
}

.growth-wrapper .col-sm-3 .each-growth-block.unique-block,
.growth-wrapper .col-sm-4 .each-growth-block.unique-block,
.growth-wrapper .col-sm-6 .each-growth-block.unique-block,
.growth-wrapper .col-sm-8 .each-growth-block.unique-block,
.growth-wrapper .col-sm-12 .each-growth-block.unique-block {
    left: 0;
    max-width: 100%;
}
.growth-wrapper .col-sm-3 .growth-nav .each-growth-block.unique-block {
    box-shadow: none;
}
.each-growth-block.txt-block {
    height: auto;
}
.card-full {
    width: 100%;
}
.growth-enable-table.auto-width-table .fieldset-tables .table.entity-dashboard-table tbody tr td:hover:first-child {
    background: var(--col-active);
}
.growth-enable-table.auto-width-table .fieldset-tables .table.entity-dashboard-table tbody tr td:first-child {
    padding-left: 1rem;
}
.editor-content {
    line-height: normal;
}
.masthead-container {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}
.masthead-container .mast-head-banner-container.full-banner {
    position: relative;
    left: -15px;
    width: calc(100% + 30px);
}
/* .margin-0 .form-table.coll-full-height.full-view-tabel.fixed-header {
    margin-bottom: 0;
} */
/* .growth-main-wrap .mt-3>.row {
    margin-bottom: 0 !important;
} */
.table-grid .growth-boiler-details {
    width: 100%;
}
.growth-container .ind-small-tab-dashboard {
    border-radius: 5px;
    box-shadow: 0 0.5px 3px 0 rgb(0 0 0 / 10%);
    padding: 15px 18px 15px 18px;
    height: auto;
}
.each-growth-block .entity-dashboard-table thead tr th {
    color: #848484;
}
.growth-content-body .form-table .table.entity-dashboard-table tbody tr td {
    color: var(--default-text);
}
.right-panel-group-each ol li {
    font-size: 16px;
}
.dynamic-dashboard .blue-lister {
    border-radius: 5px;
}
.dynamic-dashboard .ind-small-tab-dashboard {
    border-radius: 5px;
}
.ibt-body.dynamic-dashboard.dd-p-0 .col-sm-12 {
    padding-right: 15px;
    padding-left: 15px;
}
.ibt-body.dynamic-dashboard.dd-p-0  .col-sm-12 .col-sm-12 {
    padding: 0;
}
.ibt-body.dynamic-dashboard.dd-p-0 .push-right:first-child {
    margin: 0;
}
.ibt-body.dynamic-dashboard.dd-p-0 .back-to-list-button-block {
    padding-left: 0;
}
.dynamic-dashboard.dd-p-0 .blue-lister, 
.dynamic-dashboard.dd-p-0 .ind-small-tab-dashboard {
    box-shadow: 0 0.5px 3px 0 rgb(0 0 0 / 10%);
}
.ibt-body.dynamic-dashboard.dd-p-0 .col-sm-4, 
.ibt-body.dynamic-dashboard.dd-p-0 .col-sm-6 {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 0;
}
.ibt-body.dynamic-dashboard.dd-p-0 .col-sm-12 .col-sm-4:last-child, 
.ibt-body.dynamic-dashboard.dd-p-0 .col-sm-12 .col-sm-6:last-child {
    margin-right: 0;
}
.ibt-body.dynamic-dashboard.dd-p-0 .col-sm-12 .col-sm-4, 
.ibt-body.dynamic-dashboard.dd-p-0 .col-sm-12 .col-sm-6 {
    padding-left: 0;
    padding-right: 0;
    margin-right: 15px;
}
.ibt-body.dynamic-dashboard.dd-p-0 .col-sm-9 {
    padding: 0;
}


.subheader-card {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.subheader-card h5 {
    font-size: 19px;
    font-weight: 400;
}
.settings-card {
    float: left;
    width: 25px;
    height: 25px;
    border: 0;
    background: transparent;
    padding: 0;
    line-height: 0;
}
.settings-card:before {
    background-position: -179px -203px;
    width: 16px;
    height: 16px;
}
.pad-20 .modal-body {
    padding: 20px !important;
}
.chart-settings-splitter.full-width {
    width: 100%;
}

.with-navtab .for-mobile {
    display: none;
}

.each-settings-panel-wrap-body .each-inp-box {
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
}
.each-settings-panel-wrap-body .each-inp-box:last-child {
    margin: 0;
}
.each-settings-panel-wrap-body .each-inp-box > label {
    text-align: left;
    font-weight: 400;
    margin-bottom: 20px;
    font-size: 16px;
    color: var(--default-color);
}
.each-settings-panel-wrap-body .each-inp-box .select-chk-block {
    width: 50%;
    margin-right: 0;
}
.each-settings-panel-wrap-body .each-inp-box .select-chk-block label {
    text-align: left;
}
.each-settings-panel-wrap-body .each-inp-box .select-chk-block:last-child {
    margin: 0;
}
.preview-wrapper-box .growth-main-wrap .mt-3>.row > .col-sm-3 {
    padding-right: 15px;
    padding-left: 15px;
}
.preview-wrapper-box .growth-enabled .growth-nav .nav.flex-column.nav-pills {
    margin: 0;
    background: transparent;
    border: 0;
}
.preview-wrapper-box .growth-enabled .growth-nav .nav.flex-column.nav-pills .nav-link {
    width: 100%;
    text-align: left;
    border-bottom: 0;
    height: 40px;
    line-height: 40px;
    font-weight: 400;
    transition: .3s ease-out;
}
.preview-wrapper-box .growth-enabled .growth-nav .nav.flex-column.nav-pills .nav-link.active {
    font-weight: 600;
}
.preview-wrapper-box .ind-small-tab-dashboard {
    border-radius: 5px;
    box-shadow: 0 0.5px 3px 0 rgb(0 0 0 / 10%);
    padding: 15px 18px 15px 18px;
    height: auto;
}


.hide-for-lg {
    display: none;
}
.hide-for-sm {
    display: block;
}
.hide-for-lg.hide-for-sm {
    display: none;
}
.growth-enable-table .table.entity-dashboard-table tbody tr td.label-hide-large {
    display: none;
}
.growth-enable-table .table.entity-dashboard-table tbody tr td.label-hide-large.label-hide-mob.label-hide-large {
    display: none;
}
.label-hide-large .growth-boiler-details-header {
    display: none;
}
.label-hide-mob.label-hide-large .growth-boiler-details-header {
    display: none;
}
.small-tab-header.with-desc-box.label-hide-large {
    display: none;
}
.small-tab-header.with-desc-box.label-hide-mob.label-hide-large {
    display: none;
}


@media (max-width: 1024px) {


    .DashBuilder .canvas-container.col-sm-9 {
        max-width: 70%;
    }

    .DashBuilder .canvas-properties.col-sm-3 {
        max-width: 30%;
        flex: 0 0 30%;
    }

    .DashBuilder .canvas-container.col-sm-3:last-child {
        display: none;
    }

    .DashBuilder .canvas-properties.col-sm-3:last-child {
        display: block;
    }

    .canvas-container.col-sm-6 {
        max-width: 60%;
        flex: 0 0 60%;
    }
    
    .DashBuilder .left-container .layout-header-block {
        min-width: auto;
        margin-right: 10px;
        width: 100%;
    }

}

@media (max-width: 767px) {

    .DashBuilder .page-builder {
        flex-direction: column;
    }

    .DashBuilder .canvas-container.col-sm-9 {
        max-width: 100%;
        height: 350px;
        flex-shrink: 0;
    }

    .DashBuilder .canvas-properties.col-sm-3 {
        max-width: 100%;
        height: 700px;
        flex-shrink: 0;
        flex: 0 0 100%;
        border: 0;
        border-top: 1px solid #e3e5e8;
    }

    .canvas-container.col-sm-6 {
        max-width: 100%;
    }

    .form-set.collection-settings .side-tab .DashBuilder .tab-content {
        height: calc(100% - 50px);
    }

    .DashBuilder .nav-tabs {
        width: 100%;
    }

    .growth-wrapper .col-sm-12 {
        padding: 0;
    }
    .growth-mod .growth-wrapper {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 8px;
        padding-bottom: 0px;
    }
    .step4 .entity-preview-center {
        width: 100%;
    }

    .with-navtab .for-mobile {
        display: block;
    }
    .with-navtab .for-large {
        display: none;
    }
    .switch-dropdown {
        float: left;
        width: 100%;
        background: var(--white);
        border: 1px solid #e3e5e8;
        border-left: 0;
        border-right: 0;
        padding: 20px;
        position: fixed;
        left: 0;
        top: 65px;
        z-index: 9;
        display: none;
    }
    .with-navtab .switch-dropdown {
        display: block;
    }
    .template-4.growth-mod.with-navtab .modal-body {
        margin-top: 98px;
    }
    .template-4.growth-mod.with-navtab .modal-header {
        margin-bottom: 0;
        height: 65px;
        flex-shrink: 0;
    }

    .growth-main-wrap .mt-3>.row {
        flex-direction: column;
    }
    .growth-main-wrap .mt-3>.row .col-sm-3,
    .growth-main-wrap .mt-3>.row .col-sm-9 {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .hide-for-lg {
        display: block;
    }
    .hide-for-sm {
        display: none;
    }
    .growth-enable-table .table.entity-dashboard-table tbody tr td.label-hide-mob {
        display: none;
    }
    .growth-enable-table .table.entity-dashboard-table tbody tr td.label-hide-large {
        display: table-cell;
    }
    .label-hide-mob .growth-boiler-details-header {
        display: none;
    }
    .label-hide-large .growth-boiler-details-header {
        display: flex;
    }
    .small-tab-header.with-desc-box.label-hide-mob {
        display: none;
    }
    .small-tab-header.with-desc-box.label-hide-large {
        display: block;
    }
    
}