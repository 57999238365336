
.form-single-value-box  .pg-viewer-wrapper {
    float: left;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    margin-top: 15px;
}
.form-single-value-box  .pg-viewer-wrapper .pg-viewer,
.form-preview-table .pg-viewer-wrapper .pg-viewer {
    float: left;
    width: 100%;
    height: 100%;
}
.form-single-value-box  .spreadsheet-viewer,
.form-preview-table  .spreadsheet-viewer {
    float: left;
    width: 100%;
    height: 100%;
}
.form-single-value-box  .react-grid-Container,
.form-preview-table .react-grid-Container {
    float: left;
    width: 100%;
    height: calc(100% - 50px);
}
.form-single-value-box  .react-grid-Main,  
.form-preview-table .react-grid-Main {
    float: left;
    width: 100%;
    height: 100%;
}
.form-single-value-box  .react-grid-Grid , 
.form-preview-table .react-grid-Grid {
    float: left;
    width: 100%;
    /* height: calc(100% - 150px); */
    min-height: 100% !important;
}
.form-single-value-box  .react-grid-Canvas, 
.form-preview-table .react-grid-Canvas {
    float: left;
    width: 100%;
    height: 100% !important;
    overflow: auto !important;
}
.form-single-value-box .sheet-names,
.form-preview-table .sheet-names {
    margin-bottom: 10px;
    float: left;
}
.form-single-value-box .sheet-names input,
.form-preview-table .sheet-names input  {
    float: left;
    height: 40px;
    padding: 0;
    background: var(--default-color);
    color: var(--white);
    border: 0;
    padding: 0 10px;
    margin-right: 10px;
    border-radius: 5px;
}
.form-single-value-box  .pdf-canvas canvas,
.form-preview-table .pdf-canvas canvas {
    width: 100% !important;
}
.with-card-preview .table.form-preview-table tbody tr td .preview-panel iframe {
    height: 100%;
}
.table.form-preview-table tbody tr td .preview-embed {
    max-width: 336px;
}