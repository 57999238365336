/* Template 13  */

.template-13 .detailed-form-view-right-flow-top * {
    font-family: 'Roboto';
}

.template-13 .detailed-form-view-container {
    background: #F5F7FB;
}

.template-13 .ind-form-field.custom-template-view {
    background: var(--white);
    border-radius: 16px;
    padding: 35px;
    box-shadow: 0 0 6px 0px rgb(0 0 0 / 8%);
}

.template-13 .ind-form-field .fiels-inp-box {
    border: 1px solid #e0e0e0;
    height: 50px;
    font-size: 14px;
    padding-top: 0px;
    border-radius: 5px;
    background: #FAFAFA !important;
}

.template-13 .ind-form-field .input-block {
    margin-bottom: 20px;
    border: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.template-13 .ind-form-field .input-block .label-title-block {
    order: 0;
}

.template-13 .ind-form-field .fiels-inp-box {
    order: 1;
}

.template-13 .ind-form-field .anim-text {
    color: var(--default-grey);
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 8px;
    line-height: 20px;
}

.template-13 .ind-form-field .input-block.active .anim-text {
    position: relative;
    top: 0;
    left: 0;
}

.template-13 .ind-form-field .input-block textarea {
    height: 150px;
    padding-top: 25px;
}

.template-13 .ind-form-field .radio-wrapper {
    padding: 0;
    border: 0;
    margin-bottom: 15px;
}

.template-13 .ind-form-field .radio-wrapper .radio-block {
    padding: 0;
}

.template-13 .ind-form-field .radio-wrapper .radio-block h5 {
    font-size: 14px;
}

.template-13 .ind-form-field .radio-wrapper .radio-block label {
    padding: 0;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-right: 20px;
    padding: 0 0 0 35px;
    line-height: 25px;
}

.template-13 .ind-form-field .radio-block label:before {
    width: 25px;
    height: 25px;
    top: 0;
    left: 0;
}

.template-13 .ind-form-field .radio-block input:checked + label:after {
    background: var(--white);
    width: 11px;
    height: 11px;
    left: 7px;
    top: 7px;
}

.template-13 .ind-form-field .radio-wrapper .radio-block:checked + label {
    background: #26c58d;
}

.template-13 .ind-form-field .radio-block input:checked + label:before {
    background: #26c58d;
    border-color: #26c58d;
}

.template-13 .ind-form-field .checkbox-wrapper {
    padding: 0;
    border: 0;
    margin-bottom: 15px;
}

.template-13 .ind-form-field .checkbox-wrapper .select-chk-block {
    padding: 0;
    margin-right: 20px;
}

.template-13 .ind-form-field .checkbox-wrapper .select-chk-block label {
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 5px;
    margin-right: 20px;
    padding: 0 0 0 35px;
}

.template-13 .ind-form-field .checkbox-wrapper .select-chk-block .control {
    line-height: 25px;
}

.template-13 .ind-form-field .checkbox-wrapper .select-chk-block .control .checkbox-indicator {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    left: 0;
    top: 0;
    border-color: #ccc;
}

.template-13 .ind-form-field .checkbox-wrapper .select-chk-block .control .input-check-box.active + .checkbox-indicator {
    background: #26c58d;
    border-color: #26c58d;
}

.template-13 .ind-form-field .checkbox-wrapper .select-chk-block .control .checkbox-indicator:after {
    zoom: 1.7;
}

.template-13 .ind-form-field .checkbox-wrapper .select-chk-block .control .input-check-box {
    width: 100%;
    height: 100%;
}

.template-13 .ind-form-field .checkbox-wrapper h5,
.template-13 .ind-form-field .radio-wrapper h5 {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    line-height: 20px;
}

.template-13 .ind-form-field .input-block.drop select {
    border: 1px solid #e0e0e0;
    height: 50px;
}

.template-13 .ind-form-field .input-block.drop:before {
    top: 45px;
    background-position: -278px -472px;
}

.template-13 .ind-form-field .fieldset:before {
    background-color: rgb(38 197 141 / 20%);
}

.template-13 .ind-form-field .input-block.toggle {
    border: 0;
}

.template-13 .toggle .field-common.field-req-block {
    padding: 0;
    height: auto;
}

.template-13 .btn-toggle {
    background-color: #e0e0e0;
}

.template-13 .btn-toggle.active {
    background: #26c58d;
}

.template-13 .btn-toggle:before,
.template-13 .btn-toggle:after {
    font-weight: 400;
}

.template-13 .handle {
    width: 18.5px;
    height: 18.5px;
    top: 3px;
    left: 4px;
}

.template-13 .btn-toggle.active .handle {
    left: 40px;
}

.template-13 .template-13 .ind-form-field .fiels-inp-box::placeholder {
    opacity: 0;
}

.template-13 .ind-form-field .signature-canvas {
    padding: 0 0 15px 0;
    border: 0;
}

.template-13 .ind-form-field .input-block.signature {
    border: 0;
}

.template-13 .ind-form-field .sig-button-block {
    padding: 0;
}

.template-13 .sig-button-block button {
    box-shadow: none;
}

.template-13 .ind-form-field .signature-left label {
    color: var(--default-grey);
    font-size: 14px;
}

.template-13 .ind-form-field .signature-canvas canvas {
    border-color: #e0e0e0;
    border-radius: 5px !important;
    background: #FAFAFA;
}

.template-13 .sig-preview {
    border-color: #e0e0e0;
    background: #FAFAFA;
}

.template-13 .ind-form-field .input-block.html-editor {
    border: 0;
}

.template-13 .ind-form-field .input-block.html-editor .label-title-block .field-header {
    color: var(--default-grey);
    font-size: 14px;
    margin-bottom: 8px;
}

.template-13 .detailed-form-view .input-block .quill {
    border-color: #e0e0e0;
    border-radius: 5px;
}

.template-13 .detailed-form-view .input-block .ql-container.ql-snow {
    border-color: #e0e0e0;
}

.template-13 .detailed-form-view .input-block .ql-toolbar.ql-snow {
    background: #FAFAFA;
    border-radius: 5px 5px 0 0;
}

.template-13 .ind-form-field .input-block.date-field {
    border: 0;
}

.template-13 .ind-form-field .input-block.date-field .label-title-block .field-header {
    font-size: 14px;
    color: var(--default-grey);
    margin-bottom: 8px;
}

.template-13 .input-block.date-field .form-type-input {
    padding: 0;
}

.template-13 .ind-form-field .input-block.date-field .form-type-input .form-control {
    height: 50px;
    border: 1px solid #e0e0e0;
    background: #FAFAFA;
}

.template-13 .ind-form-field .input-block.date-field .form-type-input .form-control::placeholder {
    opacity: 0;
}

.template-13.detailed-form-view .input-block .quill {
    border-radius: 5px;
    border-color: #e0e0e0;
}

.template-13.detailed-form-view .input-block .ql-container.ql-snow {
    border-color: #e0e0e0;
}

.template-13.detailed-form-view .input-block .ql-toolbar.ql-snow {
    background: #FAFAFA;
    border-radius: 5px 5px 0;
}

.template-13 .sig-preview:before {
    color: var(--default-grey);
    font-size: 12px;
}

.template-13 .ind-form-field .input-block.attachment-box {
    border: 0;
}

.template-13 .ind-form-field .input-block.attachment-box .field-header {
    font-size: 14px;
    color: var(--default-grey);
    margin-bottom: 8px;
}

.template-13 .ind-form-field .attachment-box .upload-wrapper.sm-line {
    border-color: #e0e0e0;
    border-radius: 5px;
    height: 50px;
    background: #FAFAFA;
}

.template-13 .ind-form-field .btnsubIconsadd:before {
    background-position: -267px -183px;
}

.template-13 .ind-form-field .btnsubIcon:before {
    background-position: -244px -183px;
}

.template-13 .ind-form-field .fieldset .input-block .rightButtons {
    left: auto;
    transform: rotate(0deg);
}

.template-13 .photo-capture-block .label-title-block .field-header {
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--default-grey);
}

.template-13 .photo-capture-wrapper {
    border-color: #e0e0e0;
    background: #FAFAFA;
}

.template-13 .video-record-options .start-record i {
    font-family: 'FontAwesome';
    top: 0;
}

.template-13 .video-recorder-start h2,
.template-13 .video-recorder-end h2 {
    font-weight: 400;
    font-size: 12px;
}

.template-13 .preview-panel {
    border-radius: 5px;
    margin-bottom: 0;
}

.template-13 .preview-embed {
    margin: 0;
}

.template-13 .otp-button-block {
    padding: 0;
    order: 2;
    margin-top: 15px;
}

.template-13 .field-button-block .general-btn,
.template-13 .otp-button-block .general-btn {
    font-weight: 400;
}

.template-13 .ind-form-field .input-block.otp-field .field-header {
    font-weight: 400;
    font-size: 14px;
    color: var(--default-grey);
}

.template-13 .ind-form-field .input-block.feedback-field .field-header {
    font-size: 14px;
    font-weight: 400;
    color: var(--default-grey);
}

.template-13 .ind-form-field .input-block.resources .field-header {
    display: none;
}

.template-13 .ind-form-field .phonenumber .anim-text {
    left: 0;
}

.template-13 .phonenumber .phone-number-field {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.template-13 .phone-number-field .select-box select {
    height: 50px;
}

.template-13 .ind-form-field .input-block.phonenumber .fiels-inp-box {
    border: 0;
}

.template-13 .ind-form-field .input-block .label-title-block .field-header {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    color: var(--default-grey);
}

.template-13 .custom-template-view .form-input-block-left .form-type-input {
    border-color: #e0e0e0;
    background: #FAFAFA;
}

.template-13 .ind-form-field .input-block .ind-task-input.selectize-custom {
    height: auto;
    min-height: 50px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background: #FAFAFA;
}

.template-13 .ind-form-field .input-block.autofill .autofill-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    color: var(--default-grey);
}

.template-13 .video-recorder-start video,
.template-13 .video-recorder-end video {
    background: #FAFAFA;
}

.template-13 .detailed-form-view-right-bottom .button-block .general-btn {
    background: #26c58d;
    border-color: #26c58d;
    box-shadow: none;
    border-radius: 5px;
    font-weight: 500;
    letter-spacing: .5px;
}

.template-13 .display-img img {
    border-radius: 5px;
}

.template-13 .display-component .pg-viewer-wrapper .video-container video {
    border-radius: 5px;
}

.template-13 .ind-form-field .tag-wrapper-bl input {
    background: #FAFAFA;
}

/* Template 13 End */

/* Template 14 */

.template-14 .detailed-form-view-right-flow-top * {
    font-family: 'Roboto';
}

.template-14 .detailed-form-view-container {
    background: #F5F7FB;
}

.template-14 .ind-form-field.custom-template-view {
    background: var(--white);
    border-radius: 16px;
    padding: 35px;
    box-shadow: 0 0 6px 0px rgb(0 0 0 / 8%);
}

.template-14 .ind-form-field .fiels-inp-box {
    border: 1px solid #e0e0e0;
    height: 50px;
    font-size: 14px;
    padding-top: 0px;
    border-radius: 5px;
}

.template-14 .ind-form-field .input-block {
    margin-bottom: 20px;
    border: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.template-14 .ind-form-field .input-block .label-title-block {
    order: 0;
}

.template-14 .ind-form-field .fiels-inp-box {
    order: 1;
}

.template-14 .ind-form-field .anim-text {
    color: var(--default-grey);
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 8px;
    line-height: 20px;
}

.template-14 .ind-form-field .input-block.active .anim-text {
    position: relative;
    top: 0;
    left: 0;
}

.template-14 .ind-form-field .input-block textarea {
    height: 150px;
    padding-top: 25px;
}

.template-14 .ind-form-field .radio-wrapper {
    padding: 0;
    border: 0;
    margin-bottom: 15px;
}

.template-14 .ind-form-field .radio-wrapper .radio-block {
    padding: 0;
}

.template-14 .ind-form-field .radio-wrapper .radio-block h5 {
    font-size: 14px;
}

.template-14 .ind-form-field .radio-wrapper .radio-block label {
    padding: 0;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-right: 20px;
    padding: 0 0 0 35px;
    line-height: 25px;
}

.template-14 .ind-form-field .radio-block label:before {
    width: 25px;
    height: 25px;
    top: 0;
    left: 0;
}

.template-14 .ind-form-field .radio-block input:checked + label:after {
    background: var(--white);
    width: 11px;
    height: 11px;
    left: 7px;
    top: 7px;
}

.template-14 .ind-form-field .radio-wrapper .radio-block:checked + label {
    background: var(--default-color);
}

.template-14 .ind-form-field .radio-block input:checked + label:before {
    background: var(--default-color);;
    border-color: var(--default-color);;
}

.template-14 .ind-form-field .checkbox-wrapper {
    padding: 0;
    border: 0;
    margin-bottom: 15px;
}

.template-14 .ind-form-field .checkbox-wrapper .select-chk-block {
    padding: 0;
    margin-right: 20px;
}

.template-14 .ind-form-field .checkbox-wrapper .select-chk-block label {
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 5px;
    margin-right: 20px;
    padding: 0 0 0 35px;
}

.template-14 .ind-form-field .checkbox-wrapper .select-chk-block .control {
    line-height: 25px;
}

.template-14 .ind-form-field .checkbox-wrapper .select-chk-block .control .checkbox-indicator {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    left: 0;
    top: 0;
    border-color: #ccc;
}

.template-14 .ind-form-field .checkbox-wrapper .select-chk-block .control .input-check-box.active + .checkbox-indicator {
    background: var(--default-color);;
    border-color: var(--default-color);;
}

.template-14 .ind-form-field .checkbox-wrapper .select-chk-block .control .checkbox-indicator:after {
    zoom: 1.7;
}

.template-14 .ind-form-field .checkbox-wrapper .select-chk-block .control .input-check-box {
    width: 100%;
    height: 100%;
}

.template-14 .ind-form-field .checkbox-wrapper h5,
.template-14 .ind-form-field .radio-wrapper h5 {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    line-height: 20px;
}

.template-14 .ind-form-field .input-block.drop select {
    border: 1px solid #e0e0e0;
    height: 50px;
}

.template-14 .ind-form-field .input-block.drop:before {
    top: 45px;
    background-position: -278px -472px;
}

.template-14 .ind-form-field .fieldset:before {
    background-color: rgb(53 123 183 / 20%);
}

.template-14 .ind-form-field .input-block.toggle {
    border: 0;
}

.template-14 .toggle .field-common.field-req-block {
    padding: 0;
    height: auto;
}

.template-14 .btn-toggle {
    background-color: #e0e0e0;
}

.template-14 .btn-toggle.active {
    background: var(--default-color);;
}

.template-14 .btn-toggle:before,
.template-14 .btn-toggle:after {
    font-weight: 400;
}

.template-14 .handle {
    width: 18.5px;
    height: 18.5px;
    top: 3px;
    left: 4px;
}

.template-14 .btn-toggle.active .handle {
    left: 40px;
}

.template-14 .template-14 .ind-form-field .fiels-inp-box::placeholder {
    opacity: 0;
}

.template-14 .ind-form-field .signature-canvas {
    padding: 0 0 15px 0;
    border: 0;
}

.template-14 .ind-form-field .input-block.signature {
    border: 0;
}

.template-14 .ind-form-field .sig-button-block {
    padding: 0;
}

.template-14 .sig-button-block button {
    box-shadow: none;
}

.template-14 .ind-form-field .signature-left label {
    color: var(--default-grey);
    font-size: 14px;
}

.template-14 .ind-form-field .signature-canvas canvas {
    border-color: #e0e0e0;
    border-radius: 5px !important;
}

.template-14 .sig-preview {
    border-color: #e0e0e0;
}

.template-14 .ind-form-field .input-block.html-editor {
    border: 0;
}

.template-14 .ind-form-field .input-block.html-editor .label-title-block .field-header {
    color: var(--default-grey);
    font-size: 14px;
    margin-bottom: 8px;
}

.template-14 .detailed-form-view .input-block .quill {
    border-color: #e0e0e0;
    border-radius: 5px;
}

.template-14 .detailed-form-view .input-block .ql-container.ql-snow {
    border-color: #e0e0e0;
}

.template-14 .detailed-form-view .input-block .ql-toolbar.ql-snow {
    background: #FAFAFA;
    border-radius: 5px 5px 0 0;
}

.template-14 .ind-form-field .input-block.date-field {
    border: 0;
}

.template-14 .ind-form-field .input-block.date-field .label-title-block .field-header {
    font-size: 14px;
    color: var(--default-grey);
    margin-bottom: 8px;
}

.template-14 .input-block.date-field .form-type-input {
    padding: 0;
}

.template-14 .ind-form-field .input-block.date-field .form-type-input .form-control {
    height: 50px;
    border: 1px solid #e0e0e0;
}

.template-14 .ind-form-field .input-block.date-field .form-type-input .form-control::placeholder {
    opacity: 0;
}

.template-14.detailed-form-view .input-block .quill {
    border-radius: 5px;
    border-color: #e0e0e0;
}

.template-14.detailed-form-view .input-block .ql-container.ql-snow {
    border-color: #e0e0e0;
}

.template-14.detailed-form-view .input-block .ql-toolbar.ql-snow {
    background: #FAFAFA;
    border-radius: 5px 5px 0;
}

.template-14 .sig-preview:before {
    color: var(--default-grey);
    font-size: 12px;
}

.template-14 .ind-form-field .input-block.attachment-box {
    border: 0;
}

.template-14 .ind-form-field .input-block.attachment-box .field-header {
    font-size: 14px;
    color: var(--default-grey);
    margin-bottom: 8px;
}

.template-14 .ind-form-field .attachment-box .upload-wrapper.sm-line {
    border-color: #e0e0e0;
    border-radius: 5px;
    height: 50px;
}

.template-14 .ind-form-field .fieldset .input-block .rightButtons {
    left: auto;
    transform: rotate(0deg);
}

.template-14 .photo-capture-block .label-title-block .field-header {
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--default-grey);
}

.template-14 .photo-capture-wrapper {
    border-color: #e0e0e0;
}

.template-14 .video-record-options .start-record i {
    font-family: 'FontAwesome';
    top: 0;
}

.template-14 .video-recorder-start h2,
.template-14 .video-recorder-end h2 {
    font-weight: 400;
    font-size: 12px;
}

.template-14 .preview-panel {
    border-radius: 5px;
    margin-bottom: 0;
    background: var(--white);
}

.template-14 .preview-embed {
    margin: 0;
}

.template-14 .otp-button-block {
    padding: 0;
    order: 2;
    margin-top: 15px;
}

.template-14 .field-button-block .general-btn,
.template-14 .otp-button-block .general-btn {
    font-weight: 400;
}

.template-14 .ind-form-field .input-block.otp-field .field-header {
    font-weight: 400;
    font-size: 14px;
    color: var(--default-grey);
}

.template-14 .ind-form-field .input-block.feedback-field .field-header {
    font-size: 14px;
    font-weight: 400;
    color: var(--default-grey);
}

.template-14 .ind-form-field .input-block.resources .field-header {
    display: none;
}

.template-14 .ind-form-field .phonenumber .anim-text {
    left: 0;
}

.template-14 .phonenumber .phone-number-field {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.template-14 .phone-number-field .select-box select {
    height: 50px;
}

.template-14 .ind-form-field .input-block.phonenumber .fiels-inp-box {
    border: 0;
}

.template-14 .ind-form-field .input-block .label-title-block .field-header {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    color: var(--default-grey);
}

.template-14 .custom-template-view .form-input-block-left .form-type-input {
    border-color: #e0e0e0;
}

.template-14 .ind-form-field .input-block .ind-task-input.selectize-custom {
    height: auto;
    min-height: 50px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.template-14 .ind-form-field .input-block.autofill .autofill-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    color: var(--default-grey);
}

.template-14 .detailed-form-view-right-bottom .button-block .general-btn {
    background: var(--default-color);;
    border-color: var(--default-color);;
    box-shadow: none;
    border-radius: 5px;
    font-weight: 500;
    letter-spacing: .5px;
}

.template-14 .display-img img {
    border-radius: 5px;
}

.template-14 .display-component .pg-viewer-wrapper .video-container video {
    border-radius: 5px;
}

/* Template 14 End */

/* Template 18 */

.template-18 .ind-form-field .input-block {
    border: 0;
    padding: 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.template-18 .ind-form-field .input-block .label-title-block {
    order: 0;
    margin-bottom: 8px;
}

.template-18 .ind-form-field .anim-text {
    position: relative;
    left: 0;
    top: 0;
    font-size: 15px;
    color: var(--default-text);
    font-weight: 600;
}

.template-18 .ind-form-field .fiels-inp-box {
    height: 50px;
    border: 1px solid #e4e4e4;
    border-radius: 0 !important;
    background: #fff !important;
    order: 1;
}

.template-18 .ind-form-field .radio-wrapper {
    border: 0;
    padding: 0;
}

.template-18 .ind-form-field .checkbox-wrapper {
    border: 0;
    padding: 0;
}

.template-18 .ind-form-field .upload-wrapper.sm-line {
    height: 50px;
    border: 1px solid #e4e4e4;
    padding: 0;
    padding-right: 10px;
    background: #fff;
}

.template-18 .ind-form-field .checkbox-wrapper,
.template-18 .ind-form-field .radio-wrapper {
    margin-bottom: 10px;
}

.template-18 .ind-form-field .checkbox-wrapper h5,
.template-18 .ind-form-field .radio-wrapper h5 {
    font-size: 16px;
    color: var(--default-text);
    font-weight: 600;
    margin: 0;
    width: auto;
}

.template-18 .ind-form-field .radio-wrapper .label-title-block {
    margin-bottom: 8px;
}

.template-18 .ind-form-field .radio-wrapper .radio-block {
    line-height: inherit;
    height: 50px;
    display: flex;
    align-items: center;
}

.template-18 .ind-form-field .radio-wrapper .radio-block label:before {
    top: 17px;
}

.template-18 .ind-form-field .radio-wrapper .radio-block input:checked + label:after {
    top: 21px;
    background-color: #1e74eb;
}

.ibt .template-18 .ind-form-field .radio-wrapper .radio-block input:checked + label:after {
    top: 21px;
    background-color: #12A14E;
}

.template-18 .ind-form-field .checkbox-wrapper .select-chk-block {
    height: 50px;
    display: flex;
    align-items: center;
}

.template-18 .ind-form-field .checkbox-wrapper .label-title-block {
    margin-bottom: 8px;
}

.template-18 .ind-form-field .input-block.drop select {
    height: 50px;
    border: 1px solid #e4e4e4;
}

.template-18 .ind-form-field .input-block.drop:before {
    top: 48px;
    right: 32px;
    z-index: 10;
}

.template-18 .ind-form-field .style-view-fieldset-new .input-block.drop:before {
    top: 23px;
}

.template-18 .ind-form-field .fieldset {
    padding: 20px;
    border: 1px solid #e4e4e4;
    border-left: 3px solid rgb(30 116 235 / 50%);
    margin: 10px 0px 20px;
    background: #fff;
}

.ibt .template-18 .ind-form-field .fieldset {
    border-left: 3px solid rgb(20 101 53 / 50%);
}

.template-18 .ind-form-field .input-block textarea {
    height: 200px;
}

.template-18 .ind-form-field .form-header-block > h5 {
    font-size: 24px !important;
}

.template-18 .ind-form-field .form-header-block p {
    font-size: 16px;
    margin-bottom: 10px;
}

.template-18 .ind-form-field .input-block.drop {
    border-bottom: 0;
}

.template-18 .toggle .field-common.field-req-block {
    padding: 0;
    height: 55px;
}

.template-18 .ind-form-field .input-block .label-title-block label {
    font-size: 16px;
    color: var(--default-text);
    font-weight: 600;
    margin: 0;
    width: auto;
}

.template-18 .ind-form-field .signature-canvas {
    padding: 0;
    margin-bottom: 20px;
    background: transparent;
}

.template-18 .ind-form-field .signature-canvas canvas {
    border-radius: 0 !important;
    border-color: #e4e4e4;
    background: #fff;
}

.template-18 .ind-form-field .sig-button-block {
    padding: 0;
}

.template-18 .ind-form-field .sig-button-block .general-btn.grey {
    box-shadow: none;
}

.template-18 .sig-preview {
    border-radius: 0;
    border-color: #e4e4e4;
}

.template-18 .ind-form-field .input-block.date-field .form-type-input .form-control {
    height: 50px;
    border: 1px solid #e4e4e4;
    border-radius: 0;
}

.template-18 .input-block.date-field .form-type-input {
    padding-top: 0;
}

.template-18 .file-upload {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: #f5f5f5;
    border-right: 1px solid #e4e4e4;
}

.template-18 .file-upload label:before {
    left: 20px;
}

.template-18 .ind-form-field .file-upload label {
    font-size: 14px;
    font-weight: 600;
}

.template-18 .photo-capture-wrapper {
    border: 1px dashed #cccccc;
    border-radius: 0;
    background: #fff;
}

.template-18 .photo-capture-btn.take:before {
    filter: grayscale(1);
}

.template-18 .form-input-block-left .form-type-input {
    border-radius: 0;
    border-color: #e4e4e4;
}

.template-18 .ind-form-field .input-block .label-title-block .autofill-label {
    font-size: 16px;
    margin: 0;
    width: auto;
}

.template-18 .ind-form-field .input-block .ind-task-input.selectize-custom {
    height: 50px;
    border: 1px solid #e4e4e4;
    background: #fff;
    order: 1;
}

.template-18 .otp-button-block {
    padding: 20px 0;
    order: 1;
}

.template-18 .ind-form-field .input-block.otp-field {
    margin: 0;
}

.template-18 .ind-form-field .input-block.otp-field .field-header {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 15px;
}

.template-18 .otp-button-block .general-btn {
    border-radius: 0;
}

.template-18 .ind-form-field .input-block.feedback-field .label-title-block > h5 {
    font-size: 16px;
    margin: 0;
    width: auto;
}

.template-18 .feedback-block {
    height: 50px;
    display: flex;
    align-items: center;
}

.template-18 .field-button-block .general-btn {
    border-radius: 0;
}

.template-18 .phone-number-field .select-box select {
    border-radius: 0;
    background: #f5f5f5;
}

.template-18 .detailed-form-view-right-flow-top .detailed-form-view-right-top {
    background: #1e74eb;
}

.ibt .template-18 .detailed-form-view-right-flow-top .detailed-form-view-right-top {
    background: #12A14E;
}

.template-18 .form-preview-header .preview-subheader {
    color: var(--white);
}

.template-18 .form-preview-header p {
    color: var(--white);
}

.template-18 .detailed-form-view-right-flow-top .detailed-form-view-right-body {
    padding-top: 20px;
    background: #F9FAFC;
}

.template-18 .ind-form-field .fieldset:before {
    content: none;
}

.template-18 .detailed-form-view-right-bottom .button-block .general-btn {
    box-shadow: none;
    border-radius: 0;
    font-size: 16px;
    background: #1e74eb;
    border-color: #1e74eb;
}

.ibt .template-18 .detailed-form-view-right-bottom .button-block .general-btn {
    background: #12A14E;
    border-color: #12A14E;
}


.template-18.detailed-form-view.direct-flow .modal-body .form-preview-header .close {
    background: #ffffff9c;
    padding: 10px;
    border-radius: 50%;
    top: 22px;
    right: 22px;
}

.template-18.form-full-height .detailed-form-view-right-bottom {
    background: #F9FAFC;
}

.template-18 .detailed-form-view .input-block .quill {
    border-color: #e4e4e4;
}

.template-18 .detailed-form-view .input-block .ql-container.ql-snow {
    border-color: #e4e4e4;
}

.template-18 .detailed-form-view-left {
    float: left;
    width: 270px;
    height: 100%;
    background: #263755;
    padding: 92px 30px 30px;
    overflow-y: auto;
    border-left: 5px solid #1e74eb;
}

.ibt .template-18 .detailed-form-view-left {
    background: #0F3A21;
    border-left: 5px solid #12A14E;
}

.template-18.detailed-form-view.direct-flow .label-title-block .anim-text .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 3px;
}

.template-18 .input-check-box.active + .checkbox-indicator {
    background: #1e74eb;
    border: 1px solid #1e74eb;
}

.ibt .template-18 .input-check-box.active + .checkbox-indicator {
    background: #12A14E;
    border: 1px solid #12A14E;
}


.template-18 .btn-toggle.active {
    background: #1e74eb;
}

.ibt .template-18 .btn-toggle.active {
    background: #12A14E;
}

.template-18 .ind-form-field .radio-wrapper .label-title-block .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 5px;
}

.template-18 .ind-form-field .checkbox-wrapper .label-title-block .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 5px;
}

.template-18 .ind-form-field .input-block .label-title-block label.field-header .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 3px;
}

.template-18 .ind-form-field .input-block .label-title-block label .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 3px;
}

.template-18 .ind-form-field .input-block .label-title-block .autofill-label .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 3px;
}

.template-18 .ind-form-field .input-block.feedback-field .label-title-block > h5 .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 6px;
}

.template-18 .general-btn.reBtn {
    border-color: #1e74eb;
    background: #1e74eb;
}

.ibt .template-18 .general-btn.reBtn {
    border-color: #12A14E;
    background: #12A14E;
}

.template-18 .ind-form-field.fieldset.flex-box .style-view {
    border: 0;
}

.template-18 .ind-form-field.fieldset.flex-box .style-view .input-block {
    padding-right: 15px;
    justify-content: flex-end;
}

.template-18 .ind-form-field.fieldset.flex-box .style-view .input-block textarea {
    height: auto;
    resize: auto;
    padding-top: 16px;
    max-width: 100%;
    min-width: 100%;
    min-height: 50px;
}

.template-18 .ind-form-field .fieldset .rightButtons {
    left: -22px;
}

.template-18 .ind-form-field .selectize-custom .sl-tag {
    background: #1e74eb;
    border-radius: 0;
}

.ibt .template-18 .ind-form-field .selectize-custom .sl-tag {
    background: #12A14E;
    border-radius: 0;
}

.template-18 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new .input-block {
    min-height: unset;
}

.template-18 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new .rightButtons {
    left: unset;
    right: -41px;
    bottom: -15px;
}

.template-18 .ind-form-field .fieldset .rightButtons {
    left: unset;
    right: -20px;
    bottom: 0px;
}

.template-18 .ind-form-field .input-block.date-field .form-type-input .form-control::placeholder {
    opacity: 0;
}

.template-18 .ind-form-field .input-block.mandatory-box .fiels-inp-box {
    border-color: #e14745;
}

.template-18 .ind-form-field .input-block.mandatory-box .fiels-inp-box {
    border-color: #e14745;
}

.template-18 .ind-form-field .mandatory-box .upload-wrapper.sm-line {
    border-color: #e14745;
}

.template-18 .linked-collection-record li nav h5:before {
    content: "";
    width: 10px;
    height: 10px;
    background: #1e74eb;
    position: absolute;
    left: 0;
    top: 5px;
    border-radius: 50%;
}

.ibt .template-18 .linked-collection-record li nav h5:before {
    background: #12A14E;
}


.template-18 .linked-collection-record li nav h5 {
    position: relative;
    padding-left: 20px;
}

.template-18 .ind-form-field.fieldset.flex-box .style-view .upload-wrapper.sm-line {
    width: 100%;
    border: 1px solid #e4e4e4;
}

.template-18 .ind-form-field.fieldset.flex-box .style-view .upload-wrapper.sm-line .attached-file {
    position: absolute;
    right: -5px;
    bottom: -35px;
}

.template-18 .ind-form-field .fieldset.flex-box .ind-task-input.selectize-custom .tag-task-container input {
    min-width: 120px;
}

.template-18 .ind-form-field.fieldset .form-header-block > h5 {
    font-size: 18px !important;
}

.template-18 .form-header-block.page-title h5 {
    font-size: 20px !important;
}

.template-18 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new {
    margin-top: 0;
}

.template-18 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new .label-title-block {
    display: none;
}

.template-18 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new .rightButtons {
    bottom: 20px;
}

.template-18 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new:last-child .rightButtons {
    bottom: -15px;
}

.trucco .template-18 .detailed-form-view-right-flow-top .detailed-form-view-right-top {
    background: var(--default-color);
}

.trucco .detailed-form-view-left {
    background: #555;
    border-left: 5px solid var(--default-color);
}

.trucco .template-18 .linked-collection-record li nav h5:before {
    background: var(--default-color);
}

.trucco .template-18 .detailed-form-view-right-bottom .button-block .general-btn {
    background: var(--default-color);
    border-color: var(--default-color);
}

/* Template 19 */

.template-19 .ind-form-field .input-block {
    border: 0;
    padding: 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.template-19 .ind-form-field .input-block .label-title-block {
    order: 0;
    margin-bottom: 15px;
}

.template-19 .ind-form-field .anim-text {
    position: relative;
    left: 0;
    top: 0;
    font-size: 15px;
    color: var(--default-text);
    font-weight: 600;
}

.template-19 .ind-form-field .fiels-inp-box {
    height: 50px;
    border: 1px solid #e4e4e4;
    border-radius: 0 !important;
    background: #fff !important;
    order: 1;
}

.template-19 .ind-form-field .radio-wrapper {
    border: 0;
    padding: 0;
}

.template-19 .ind-form-field .checkbox-wrapper {
    border: 0;
    padding: 0;
}

.template-19 .ind-form-field .upload-wrapper.sm-line {
    height: 50px;
    border: 1px solid #e4e4e4;
    padding: 0;
    padding-right: 10px;
    background: #fff;
}

.template-19 .ind-form-field .checkbox-wrapper,
.template-19 .ind-form-field .radio-wrapper {
    margin-bottom: 10px;
}

.template-19 .ind-form-field .checkbox-wrapper h5,
.template-19 .ind-form-field .radio-wrapper h5 {
    font-size: 14px;
    color: var(--default-grey);
    margin: 0;
    width: auto;
}

.template-19 .ind-form-field .radio-wrapper .label-title-block {
    margin-bottom: 10px;
}

.template-19 .ind-form-field .radio-wrapper .radio-block {
    line-height: inherit;
    height: 50px;
    display: flex;
    align-items: center;
}

.template-19 .ind-form-field .radio-wrapper .radio-block label:before {
    top: 17px;
}

.template-19 .ind-form-field .radio-wrapper .radio-block input:checked + label:after {
    top: 21px;
    background-color: #48DAA6;
}

.template-19 .ind-form-field .checkbox-wrapper .select-chk-block {
    height: 50px;
    display: flex;
    align-items: center;
}

.template-19 .ind-form-field .checkbox-wrapper .label-title-block {
    margin-bottom: 10px;
}

.template-19 .ind-form-field .input-block.drop select {
    height: 50px;
    border: 1px solid #e4e4e4;
}

.template-19 .ind-form-field .input-block.drop:before {
    top: 48px;
    right: 32px;
    z-index: 10;
}

.template-19 .ind-form-field .style-view-fieldset-new .input-block.drop:before {
    top: 23px;
}

.template-19 .ind-form-field .fieldset {
    padding: 20px;
    border: 1px solid #e4e4e4;
    border-left: 3px solid rgb(72 218 166 / 50%);
    margin: 10px 0px 20px;
    background: #fff;
}

.template-19 .ind-form-field .input-block textarea {
    height: 200px;
}

.template-19 .ind-form-field .form-header-block > h5 {
    font-size: 18px;
}

.template-19 .ind-form-field .form-header-block p {
    font-size: 16px;
    margin-bottom: 10px;
}

.template-19 .ind-form-field .input-block.drop {
    border-bottom: 0;
}

.template-19 .toggle .field-common.field-req-block {
    padding: 0;
    height: 55px;
}

.template-19 .ind-form-field .input-block .label-title-block label {
    font-size: 14px;
    color: var(--default-grey);
    font-weight: 400;
    margin: 0;
}

.template-19 .ind-form-field .signature-canvas {
    padding: 0;
    margin-bottom: 20px;
    background: transparent;
}

.template-19 .ind-form-field .signature-canvas canvas {
    border-radius: 0 !important;
    border-color: #e4e4e4;
    background: #fff;
}

.template-19 .ind-form-field .sig-button-block {
    padding: 0;
}

.template-19 .ind-form-field .sig-button-block .general-btn.grey {
    box-shadow: none;
}

.template-19 .sig-preview {
    border-radius: 0;
    border-color: #e4e4e4;
}

.template-19 .ind-form-field .input-block.date-field .form-type-input .form-control {
    height: 50px;
    border: 1px solid #e4e4e4;
    border-radius: 0;
}

.template-19 .input-block.date-field .form-type-input {
    padding-top: 0;
}

.template-19 .file-upload {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: #f5f5f5;
    border-right: 1px solid #e4e4e4;
}

.template-19 .file-upload label:before {
    left: 20px;
}

.template-19 .ind-form-field .file-upload label {
    font-size: 14px;
    font-weight: 600;
}

.template-19 .photo-capture-wrapper {
    border: 1px dashed #cccccc;
    border-radius: 0;
    background: #fff;
}

.template-19 .photo-capture-btn.take:before {
    filter: grayscale(1);
}

.template-19 .form-input-block-left .form-type-input {
    border-radius: 0;
    border-color: #e4e4e4;
}

.template-19 .ind-form-field .input-block .label-title-block .autofill-label {
    font-size: 14px;
    margin: 0;
    color: var(--default-grey);
    font-weight: 400;
    width: auto;
}

.template-19 .ind-form-field .input-block .ind-task-input.selectize-custom {
    height: 50px;
    border: 1px solid #e4e4e4;
    background: #fff;
    order: 1;
}

.template-19 .otp-button-block {
    padding: 20px 0;
    order: 1;
}

.template-19 .ind-form-field .input-block.otp-field {
    margin: 0;
}

.template-19 .ind-form-field .input-block.otp-field .field-header {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 15px;
    font-weight: 400;
    color: var(--default-grey);
}

.template-19 .otp-button-block .general-btn {
    border-radius: 0;
}

.template-19 .ind-form-field .input-block.feedback-field .label-title-block > h5 {
    font-size: 14px;
    margin: 0;
    color: var(--default-grey);
    font-weight: 400;
    width: auto;
}

.template-19 .feedback-block {
    height: 50px;
    display: flex;
    align-items: center;
}

.template-19 .field-button-block .general-btn {
    border-radius: 0;
}

.template-19 .phone-number-field .select-box select {
    border-radius: 0;
    background: #f5f5f5;
}

.template-19 .detailed-form-view-right-flow-top .detailed-form-view-right-top {
    background: #ffffff;
    border-bottom: 1px solid #e4e4e4;
}

.template-19 .detailed-form-view-right-flow-top .detailed-form-view-right-body {
    padding-top: 20px;
    background: #F9FAFC;
}

.template-19 .ind-form-field .fieldset:before {
    content: none;
}

.template-19 .detailed-form-view-right-bottom .button-block .general-btn {
    box-shadow: none;
    border-radius: 0;
    font-size: 16px;
    background: #48DAA6;
    border-color: #48DAA6;
}

.template-19.form-full-height .detailed-form-view-right-bottom {
    background: #F9FAFC;
}

.template-19 .detailed-form-view .input-block .quill {
    border-color: #e4e4e4;
}

.template-19 .detailed-form-view .input-block .ql-container.ql-snow {
    border-color: #e4e4e4;
}

.template-19 .detailed-form-view-left {
    float: left;
    width: 270px;
    height: 100%;
    background: #48DAA6;
    padding: 92px 30px 30px;
    overflow-y: auto;
}

.template-19.detailed-form-view.direct-flow .label-title-block .anim-text .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 1px;
}

.template-19 .input-check-box.active + .checkbox-indicator {
    background: #48DAA6;
    border: 1px solid #48DAA6;
}

.template-19 .btn-toggle.active {
    background: #48DAA6;
}

.template-19 .linked-collection-record li {
    border-color: rgb(255 255 255 / 60%);
}

.template-19 .ind-form-field .radio-wrapper .label-title-block .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 5px;
}

.template-19 .ind-form-field .checkbox-wrapper .label-title-block .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 5px;
}

.template-19 .ind-form-field .input-block .label-title-block label.field-header .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 2px;
}

.template-19 .ind-form-field .input-block .label-title-block label .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 3px;
}

.template-19 .ind-form-field .input-block .label-title-block .autofill-label .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 4px;
}

.template-19 .ind-form-field .input-block.feedback-field .label-title-block > h5 .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 6px;
}

.template-19 .general-btn.reBtn {
    border-color: #48daa6;
    background: #48daa6;
}

.template-19 .ind-form-field.fieldset.flex-box .style-view {
    border: 0;
}

.template-19 .ind-form-field.fieldset.flex-box .style-view .input-block {
    padding-right: 15px;
}

.template-19 .ind-form-field.fieldset.flex-box .style-view .input-block textarea {
    height: auto;
    resize: auto;
    padding-top: 16px;
    max-width: 100%;
    min-width: 100%;
    min-height: 50px;
}

.template-19 .ind-form-field .fieldset .rightButtons {
    left: -22px;
}

.template-19 .ind-form-field .selectize-custom .sl-tag {
    background: #48daa6;
    border-radius: 0;
}

.template-19 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new .input-block {
    min-height: unset;
}

.template-19 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new .rightButtons {
    left: unset;
    right: -41px;
    bottom: -15px;
}

.template-19 .ind-form-field .fieldset .rightButtons {
    left: unset;
    right: -20px;
    bottom: 0px;
}

.template-19 .ind-form-field .input-block.date-field .form-type-input .form-control::placeholder {
    opacity: 0;
}

.template-19 .ind-form-field .input-block.mandatory-box .fiels-inp-box {
    border-color: #e14745;
}

.template-19 .ind-form-field .input-block.mandatory-box .fiels-inp-box {
    border-color: #e14745;
}

.template-19 .ind-form-field .mandatory-box .upload-wrapper.sm-line {
    border-color: #e14745;
}

.template-19 .ind-form-field.fieldset.flex-box .style-view .upload-wrapper.sm-line {
    width: 100%;
    border: 1px solid #e4e4e4;
}

.template-19 .ind-form-field.fieldset.flex-box .style-view .upload-wrapper.sm-line .attached-file {
    position: absolute;
    right: -5px;
    bottom: -35px;
}

.template-19 .ind-form-field .fieldset.flex-box .ind-task-input.selectize-custom .tag-task-container input {
    min-width: 120px;
}

.template-19 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new {
    margin-top: 0;
}

.template-19 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new .label-title-block {
    display: none;
}

.template-19 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new .rightButtons {
    bottom: 20px;
}

.template-19 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new:last-child .rightButtons {
    bottom: -15px;
}

.template-18 .ind-form-field .input-block.autofill.mandatory-box .ind-task-input.selectize-custom,
.template-19 .ind-form-field .input-block.autofill.mandatory-box .ind-task-input.selectize-custom {
    border-color: #e14745;
}

/* Template 20 */

.template-20 .ind-form-field .input-block {
    border: 0;
    padding: 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.template-20 .ind-form-field .input-block .label-title-block {
    order: 0;
    margin-bottom: 15px;
}

.template-20 .ind-form-field .anim-text {
    position: relative;
    left: 0;
    top: 0;
    font-size: 15px;
    color: var(--default-text);
    font-weight: 600;
}

.template-20 .ind-form-field .fiels-inp-box {
    height: 50px;
    border: 1px solid #e4e4e4;
    border-radius: 0 !important;
    background: #F7F8FC !important;
    order: 1;
}

.template-20 .ind-form-field .radio-wrapper {
    border: 0;
    padding: 0;
}

.template-20 .ind-form-field .checkbox-wrapper {
    border: 0;
    padding: 0;
}

.template-20 .ind-form-field .upload-wrapper.sm-line {
    height: 50px;
    border: 1px solid #e4e4e4;
    padding: 0;
    padding-right: 10px;
    background: #f7f8fc;
}

.template-20 .ind-form-field .checkbox-wrapper,
.template-20 .ind-form-field .radio-wrapper {
    margin-bottom: 10px;
}

.template-20 .ind-form-field .checkbox-wrapper h5,
.template-20 .ind-form-field .radio-wrapper h5 {
    font-size: 14px;
    color: var(--default-text);
    font-weight: 600;
    margin: 0;
    width: auto;
}

.template-20 .ind-form-field .radio-wrapper .label-title-block {
    margin-bottom: 10px;
}

.template-20 .ind-form-field .radio-wrapper .radio-block {
    line-height: inherit;
    height: 50px;
    display: flex;
    align-items: center;
}

.template-20 .ind-form-field .radio-wrapper .radio-block label:before {
    top: 17px;
}

.template-20 .ind-form-field .radio-wrapper .radio-block input:checked + label:after {
    top: 21px;
    background-color: #526acc;
}

.template-20 .ind-form-field .checkbox-wrapper .select-chk-block {
    height: 50px;
    display: flex;
    align-items: center;
}

.template-20 .ind-form-field .checkbox-wrapper .label-title-block {
    margin-bottom: 10px;
}

.template-20 .ind-form-field .input-block.drop select {
    height: 50px;
    border: 1px solid #e4e4e4;
}

.template-20 .ind-form-field .input-block.drop:before {
    top: 53px;
    z-index: 10;
}

.template-20 .ind-form-field .fieldset {
    padding: 20px;
    border: 1px solid #e4e4e4;
    border-left: 3px solid rgb(82 106 204 / 50%);
    margin: 10px 0px 20px;
    background: #fff;
}

.template-20 .ind-form-field .input-block textarea {
    height: 200px;
}

.template-20 .ind-form-field .form-header-block > h5 {
    font-size: 18px;
}

.template-20 .ind-form-field .form-header-block p {
    font-size: 16px;
    margin-bottom: 10px;
}

.template-20 .ind-form-field .input-block.drop {
    border-bottom: 0;
}

.template-20 .toggle .field-common.field-req-block {
    padding: 0;
    height: 55px;
}

.template-20 .ind-form-field .input-block .label-title-block label {
    font-size: 14px;
    color: var(--default-text);
    font-weight: 600;
    margin: 0;
    width: auto;
}

.template-20 .ind-form-field .signature-canvas {
    padding: 0;
    margin-bottom: 20px;
    background: transparent;
}

.template-20 .ind-form-field .signature-canvas canvas {
    border-radius: 0 !important;
    border-color: #e4e4e4;
    background: #F7F8FC;
}

.template-20 .ind-form-field .sig-button-block {
    padding: 0;
}

.template-20 .ind-form-field .sig-button-block .general-btn.grey {
    box-shadow: none;
}

.template-20 .sig-preview {
    border-radius: 0;
    border-color: #e4e4e4;
}

.template-20 .ind-form-field .input-block.date-field .form-type-input .form-control {
    height: 50px;
    border: 1px solid #e4e4e4;
    border-radius: 0;
    background: #f7f8fc;
}

.template-20 .input-block.date-field .form-type-input {
    padding-top: 0;
}

.template-20 .file-upload {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: #f7f8fc;
    border-right: 1px solid #e4e4e4;
}

.template-20 .file-upload label:before {
    left: 20px;
}

.template-20 .ind-form-field .file-upload label {
    font-size: 14px;
    font-weight: 600;
}

.template-20 .photo-capture-wrapper {
    border: 1px dashed #cccccc;
    border-radius: 0;
    background: #f7f8fc;
}

.template-20 .photo-capture-btn.take:before {
    filter: grayscale(1);
}

.template-20 .form-input-block-left .form-type-input {
    border-radius: 0;
    border-color: #e4e4e4;
    background: #f7f8fc;
}

.template-20 .ind-form-field .input-block .label-title-block .autofill-label {
    font-size: 14px;
    margin: 0;
    width: auto;
}

.template-20 .ind-form-field .input-block .ind-task-input.selectize-custom {
    height: 50px;
    border: 1px solid #e4e4e4;
    background: #f7f8fc;
    order: 1;
}

.template-20 .otp-button-block {
    padding: 20px 0;
    order: 1;
}

.template-20 .ind-form-field .input-block.otp-field {
    margin: 0;
}

.template-20 .ind-form-field .input-block.otp-field .field-header {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 15px;
}

.template-20 .otp-button-block .general-btn {
    border-radius: 0;
}

.template-20 .ind-form-field .input-block.feedback-field .label-title-block > h5 {
    font-size: 16px;
    margin: 0;
    width: auto;
}

.template-20 .feedback-block {
    height: 50px;
    display: flex;
    align-items: center;
}

.template-20 .field-button-block .general-btn {
    border-radius: 0;
}

.template-20 .phone-number-field .select-box select {
    border-radius: 0;
    background: #f7f8fc;
}

.template-20 .detailed-form-view-right-flow-top .detailed-form-view-right-top {
    background: #f7f8fc;
    border-bottom: 1px solid #e4e4e4;
}

.template-20 .detailed-form-view-right-flow-top .detailed-form-view-right-body {
    padding-top: 20px;
    /* background: #F9FAFC; */
}

.template-20 .ind-form-field .fieldset:before {
    content: none;
}

.template-20 .detailed-form-view-right-bottom .button-block .general-btn {
    box-shadow: none;
    border-radius: 0;
    font-size: 16px;
    background: #526acc;
    border-color: #526acc;
}

.template-20 .detailed-form-view .input-block .quill {
    border-color: #e4e4e4;
}

.template-20 .detailed-form-view .input-block .ql-container.ql-snow {
    border-color: #e4e4e4;
}

.template-20 .detailed-form-view-left {
    float: left;
    width: 270px;
    height: 100%;
    background: #526ACC;
    padding: 92px 30px 30px;
    overflow-y: auto;
}

.template-20.detailed-form-view.direct-flow .label-title-block .anim-text .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 2px;
}

.template-20 .input-check-box.active + .checkbox-indicator {
    background: #526acc;
    border: 1px solid #526acc;
}

.template-20 .btn-toggle.active {
    background: #526acc;
}

.template-20 .linked-collection-record li {
    border-color: rgb(255 255 255 / 60%);
}

.template-20 .ind-form-field .input-block .quill {
    background: #f7f8fc;
}

.template-20 .ind-form-field .tag-wrapper-bl input {
    background: transparent;
}

.template-20 .ind-form-field .radio-wrapper .label-title-block .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 5px;
}

.template-20 .ind-form-field .checkbox-wrapper .label-title-block .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 5px;
}

.template-20 .ind-form-field .input-block .label-title-block label.field-header .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 2px;
}

.template-20 .ind-form-field .input-block .label-title-block label .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 3px;
}

.template-20 .ind-form-field .input-block .label-title-block .autofill-label .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 3px;
}

.template-20 .ind-form-field .input-block.feedback-field .label-title-block > h5 .info-clicker-wrap {
    display: block;
    float: right;
    margin-left: 5px;
    position: relative;
    top: 6px;
}

.template-20 .general-btn.reBtn {
    border-color: #526acc;
    background: #526acc;
}

.template-20 .ind-form-field.fieldset.flex-box .style-view {
    border: 0;
}

.template-20 .ind-form-field.fieldset.flex-box .style-view .input-block {
    padding-right: 15px;
}

.template-20 .ind-form-field.fieldset.flex-box .style-view .input-block textarea {
    height: auto;
    resize: auto;
    padding-top: 16px;
    max-width: 100%;
    min-width: 100%;
    min-height: 50px;
}

.template-20 .ind-form-field .fieldset .rightButtons {
    left: -22px;
}

.template-20 .ind-form-field .selectize-custom .sl-tag {
    background: #526acc;
    border-radius: 0;
}

.template-20 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new .input-block {
    min-height: unset;
}

.template-20 .ind-form-field.fieldset.flex-box .style-view.style-view-fieldset-new .rightButtons {
    left: unset;
    right: -41px;
    bottom: -15px;
}

.template-20 .ind-form-field .fieldset .rightButtons {
    left: unset;
    right: -20px;
    bottom: 0px;
}

.template-20 .ind-form-field .input-block.date-field .form-type-input .form-control::placeholder {
    opacity: 0;
}

.template-20 .ind-form-field .input-block.mandatory-box .fiels-inp-box {
    border-color: #e14745;
}

.template-20 .ind-form-field .input-block.mandatory-box .fiels-inp-box {
    border-color: #e14745;
}

.template-20 .ind-form-field .mandatory-box .upload-wrapper.sm-line {
    border-color: #e14745;
}

.template-20 .ind-form-field.fieldset.flex-box .style-view .upload-wrapper.sm-line {
    width: 100%;
    border: 1px solid #e4e4e4;
}

.template-20 .ind-form-field.fieldset.flex-box .style-view .upload-wrapper.sm-line .attached-file {
    position: absolute;
    right: -5px;
    bottom: -35px;
}

.template-20 .ind-form-field .fieldset.flex-box .ind-task-input.selectize-custom .tag-task-container input {
    min-width: 120px;
}

/* Editor with source */

.template-1 .with-source .ql-container.ql-snow {
    border: 0;
}

.template-1.detailed-form-view .input-block.with-source .quill {
    border-top: 1px solid #e4e6e9;
    min-height: auto;
}

.template-1 .ind-form-field .input-block.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    height: auto;
    min-height: 165px;
    padding: 12px 15px;
    border: 0;
    font-size: 16px;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
}

.template-2 .with-source .ql-container.ql-snow {
    border: 0;
}

.template-2.detailed-form-view .input-block.with-source .quill {
    border-top: 1px solid #e4e6e9;
    min-height: auto;
}

.template-2 .ind-form-field .input-block.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    height: auto;
    min-height: 165px;
    padding: 12px 15px;
    border: 0;
    font-size: 16px;
    background: #FFF;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
    border: 1px solid #e4e6e9;
}

.detailed-form-view.template-2 .input-block .ql-toolbar.ql-snow {
    background: #fff;
    border: 1px solid #e4e6e9 !important;
    border-bottom: 0 !important;
}

.detailed-form-view.template-2 .input-block.with-source .showRaw .quill {
    border: 0;
}

.template-3 .with-source .ql-container.ql-snow {
    border: 0;
}

.template-3.detailed-form-view .input-block.with-source .quill {
    border-top: 1px solid #e4e6e9;
    min-height: auto;
}

.template-3 .ind-form-field .input-block.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    height: auto;
    min-height: 165px;
    padding: 12px 15px;
    border: 1px solid #e4e6e9;
    font-size: 16px;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
}

.detailed-form-view.template-3 .input-block .ql-toolbar.ql-snow {
    background: #fff;
    border: 1px solid #e4e6e9 !important;
    border-bottom: 0 !important;
}

.detailed-form-view.template-3 .input-block.with-source .showRaw .quill {
    border: 0;
}

.template-4 .with-source .ql-container.ql-snow {
    border: 0;
}

.detailed-form-view.template-4 .input-block.with-source .ql-toolbar.ql-snow {
    border: 1px solid #e4e6e9 !important;
    border-bottom: 0 !important;
    border-radius: 5px 5px 0 0;
}

.template-4 .ind-form-field .input-block.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    min-height: 165px;
    padding: 12px 15px;
    border: 1px solid #e4e6e9;
    font-size: 16px;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
    border-radius: 0 0 5px 5px;
}

.detailed-form-view.template-4 .input-block.with-source .showRaw .quill {
    border: 0;
    min-height: auto;
}

.detailed-form-view.template-4 .input-block.with-source .quill {
    border-radius: 0 0 5px 5px;
}

.template-5 .with-source .ql-container.ql-snow {
    border: 0;
}

.detailed-form-view.template-5 .input-block.with-source .ql-toolbar.ql-snow {
    border: 1px solid #e4e6e9 !important;
    border-bottom: 0 !important;
    background: #FFFFFF
}

.template-5 .ind-form-field .input-block.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    min-height: 165px;
    padding: 12px 15px;
    border: 1px solid #e4e6e9;
    background: #ffffff;
    font-size: 16px;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
}

.detailed-form-view.template-5 .input-block.with-source .showRaw .quill {
    border: 0;
    min-height: auto;
}

.template-6 .with-source .ql-container.ql-snow {
    border: 0;
}

.detailed-form-view.template-6 .input-block.with-source .ql-toolbar.ql-snow {
    border-bottom: 1px solid #eaebeb !important;
    background: #FFFFFF;
    border-radius: 10px 10px 0 0;
}

.template-6 .ind-form-field .input-block.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    min-height: 165px;
    padding: 12px 15px;
    border: 0;
    background: #ffffff;
    font-size: 16px;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
    border-radius: 0 0 10px 10px;
}

.detailed-form-view.template-6 .input-block.with-source .showRaw .quill {
    border: 0;
    min-height: auto;
}

.template-6.detailed-form-view .input-block.with-source .quill {
    box-shadow: none;
    border-radius: 0 0 10px 10px;
}

.detailed-form-view.template-6 .input-block.with-source .text-editor {
    float: left;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
}

.template-7 .with-source .ql-container.ql-snow {
    border: 0;
}

.detailed-form-view.template-7 .input-block.with-source .ql-toolbar.ql-snow {
    border: 1px solid #c4c6c9 !important;
    border-bottom: 0 !important;
    background: #FFFFFF;
}

.template-7 .ind-form-field .input-block.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    min-height: 165px;
    padding: 12px 15px;
    border: 1px solid #c4c6c9;
    background: #ffffff;
    font-size: 16px;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
}

.detailed-form-view.template-7 .input-block.with-source .showRaw .quill {
    border: 0;
    min-height: auto;
}

.template-11 .with-source .ql-container.ql-snow {
    border: 0;
}

.template-11 .with-source .ql-container.ql-snow .ql-editor {
    background: #ffffff;
    border: 1px solid #e4e6e9;
}

.template-11 .type-form-input.editor.with-source .ql-toolbar.ql-snow {
    border-bottom: 0;
}

.detailed-form-view.template-11 .type-form-input.editor.with-source .showRaw .quill {
    border: 0;
    min-height: auto;
}

.detailed-form-view.template-11 .type-form-input.editor.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    min-height: 165px;
    padding: 12px 15px;
    border: 1px solid #e4e6e9;
    background: #ffffff;
    font-size: 16px;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
}

.template-12 .with-source .ql-container.ql-snow {
    border: 0;
}

.template-12 .with-source .ql-container.ql-snow .ql-editor {
    background: #ffffff;
    border: 1px solid #e4e6e9;
}

.template-12 .each-type-question.editor.with-source .ql-toolbar.ql-snow {
    border-bottom: 0;
}

.template-12 .each-type-question.editor.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    min-height: 165px;
    padding: 12px 15px;
    border: 1px solid #e4e6e9;
    background: #ffffff;
    font-size: 16px;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
}

.template-12 .each-type-question.editor.with-source .showRaw .quill {
    border: 0;
    min-height: auto;
}

.template-18 .with-source .ql-container.ql-snow {
    border: 0;
}

.detailed-form-view.template-18 .input-block.with-source .ql-toolbar.ql-snow {
    border: 1px solid #e4e6e9 !important;
    border-bottom: 0 !important;
    background: #fff;
}

.template-18 .ind-form-field .input-block.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    height: auto;
    min-height: 165px;
    padding: 12px 15px;
    border: 1px solid #e4e6e9;
    background: #fff;
    font-size: 16px;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
}

.detailed-form-view.template-18 .input-block.with-source .showRaw .quill {
    border: 0;
    min-height: auto;
}

.template-18.form-full-height .detailed-form-view-right-flow-top {
    background: #F9FAFC;
}

.template-19.form-full-height .detailed-form-view-right-flow-top {
    background: #F9FAFC;
}

.template-19 .with-source .ql-container.ql-snow {
    border: 0;
}

.detailed-form-view.template-19 .input-block.with-source .ql-toolbar.ql-snow {
    border: 1px solid #e4e6e9 !important;
    border-bottom: 0 !important;
    background: #fff;
}

.template-19 .ind-form-field .input-block.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    height: auto;
    min-height: 165px;
    padding: 12px 15px;
    border: 1px solid #e4e6e9;
    background: #fff;
    font-size: 16px;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
}

.detailed-form-view.template-19 .input-block.with-source .showRaw .quill {
    border: 0;
    min-height: auto;
}

.template-20 .with-source .ql-container.ql-snow {
    border: 0;
}

.detailed-form-view.template-20 .input-block.with-source .ql-toolbar.ql-snow {
    border: 1px solid #e4e6e9 !important;
    border-bottom: 0 !important;
    background: #f7f8fc;
}

.template-20 .ind-form-field .input-block.with-source .raw-editor {
    float: left;
    width: 100%;
    min-width: 100%;
    height: auto;
    min-height: 165px;
    padding: 12px 15px;
    border: 1px solid #e4e6e9;
    background: #f7f8fc;
    font-size: 16px;
    color: var(--default-text);
    line-height: normal;
    resize: auto;
}

.detailed-form-view.template-20 .input-block.with-source .showRaw .quill {
    border: 0;
    min-height: auto;
}

/* Page Template */

.page-temp-container .form-page-tab {
    float: left;
    width: 450px;
    height: calc(100vh - 173px);
    background: var(--white);
    border: 1px solid #e4e6e9;
    border-radius: 5px;
}

.page-temp-container .form-preview-container {
    width: calc(100% - 480px);
    margin: 0;
    margin-right: 30px;
    box-shadow: none;
    border: 1px solid #e4e6e9;
    border-radius: 5px;
}

.page-temp-container {
    float: left;
    width: 100%;
    padding: 0 30px;
    display: flex;
}


.user-tr:hover .wf-btn-block {
    opacity: 1;
    pointer-events: auto;
}

.drag-table .user-tr .user-td:last-child {
    justify-content: flex-end;
}

.full-wid-mod .collection-clik-panel .discuss-inner.full-height-panel {
    height: calc(100vh - 130px);
}

/* Number field arrow */

.number-arrow {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.top-arrow, .down-arrow {
    width: 20px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.top-arrow:before {
    background-position: -420px -164px;
    width: 8px;
    height: 5px;
    transform: rotate(180deg);
    opacity: .5;
}

.down-arrow:before {
    background-position: -420px -164px;
    width: 8px;
    height: 5px;
    opacity: .5;
}

.number-arrow nav:hover:before {
    opacity: 1;
}

.template-1 .number-arrow {
    bottom: calc(50% - 25px);
}

.template-3 .number-arrow {
    bottom: calc(50% - 25px);
}

.template-5 .top-arrow:before {
    background-position: -573px -166px;
}

.template-5 .down-arrow:before {
    background-position: -573px -166px;
}

.template-5 .number-arrow {
    height: 70px;
}

.template-6 .number-arrow {
    height: 45px;
}

.template-7 .number-arrow {
    height: 70px;
}

.template-11 .number-arrow {
    height: 45px;
}

.template-12 .number-arrow {
    height: 45px;
}

.range-slider input[type=number]::-webkit-outer-spin-button,
.range-slider input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: auto;
    margin: 0;
}

.range-slider input[type=number] {
    -moz-appearance: auto;
}

/* preview component */

.preview-panel {
    float: left;
    max-width: 100%;
    margin-bottom: 25px;
    border: 1px solid #e4e6e9;
    background: #FAFAFA;
}

.preview-panel > div {
    height: 100%;
    width: 100%;
}

.preview-component .field-button-block button {
    margin-right: 10px;
}

.preview-embed {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.preview-component .preview-popup {
    float: left;
    width: 100%;
    margin: 20px 0;
}

.preview-component .pg-viewer-wrapper {
    height: 100%;
    overflow: auto;
}

.preview-panel img {
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.form-single-value-box .preview-component {
    margin-top: 7px;
}

.form-single-value-box .preview-panel img {
    object-fit: cover;
    max-width: 100%;
}

.form-single-value-box .agreement .custom-checkbox-text p {
    margin: 0;
    line-height: normal;
}

.form-single-value-box .preview-component .pg-viewer-wrapper {
    height: 100%;
    margin: 0;
    padding: 20 p;
}

.form-single-value-box .preview-component .preview-panel iframe {
    width: 100%;
    height: 100% !important;
}

.ind-form-field .input-block .sheet-names {
    margin-bottom: 10px;
    float: left;
}

.ind-form-field .input-block .sheet-names input {
    float: left;
    height: 40px;
    padding: 0;
    background: var(--default-color);
    color: var(--white);
    border: 0;
    padding: 0 10px;
    margin-right: 10px;
    border-radius: 5px;
}

.template-1 .preview-embed {
    padding: 55px 20px 0;
    margin-bottom: 10px;
}

.template-1 .preview-component .preview-popup {
    padding: 50px 20px 0;
    margin: 10px 0 0 0;
}

.template-3 .preview-embed {
    padding: 55px 0 0;
    margin-bottom: 0px;
}

.template-3 .preview-component .preview-popup {
    padding: 50px 0 0;
    margin: 0px 0 0 0;
}

.template-5 .preview-component {
    margin-top: 20px;
}

.template-5 .preview-component {
    margin-top: 20px;
}

.template-6 .preview-component .preview-popup {
    margin-top: 0;
    margin-bottom: 15px;
}

.template-7 .preview-component {
    margin-top: 20px;
}

.template-8 .preview-component {
    margin-top: 20px;
}

/* Other css */

/* Rule */

.left-rule-panel {
    float: left;
    width: 35%;
    height: 100%;
    padding: 30px 20px 20px 0;
}

.right-rule-panel {
    float: left;
    width: 65%;
    height: 100%;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.left-rule-panel .rule-field-btn-block .dropdown-menu {
    min-width: 270px;
    left: calc(50% - 135px) !important;
}

.summary-card-tab.with-field-padding .profile-entry-body .each-details-entry {
    margin-bottom: 10px;
}

.call-note-options {
    right: 0;
    top: 0;
    position: absolute;
    background: var(--white);
    z-index: 1;
}

.call-note-options button {
    background: transparent;
    border: 0;
    padding: 0 10px;
    margin: 10px 0px;
}

.call-note-tab .tab-pane, .call-note-tab .tab-content {
    position: relative;
}

/* project */

.flex-down .meeting-room-head {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    height: auto;
}

.schedule-mode .each-sub-section.flex-down {
    padding: 15px 20px;
}

.flex-down .meeting-room-head p {
    margin-top: 5px;
    line-height: normal;
}

.flex-down .schedule-list li {
    flex-direction: column;
    align-items: flex-start;
}

.flex-down .schedule-list:before {
    height: calc(100% - 28px);
}

.flex-down .schedule-list li:before {
    top: 10px;
}

.flex-down .schedule-list li:after {
    top: 7px;
}

.flex-down .meeting-list-option button {
    margin-left: 0;
    margin-right: 10px;
}

.flex-down .meeting-list-option {
    margin-top: 7px;
}

.each-sub-section h5 .meeting-list-option {
    margin: 0;
    margin-left: 10px;
}

.each-sub-section h5 {
    line-height: 20px;
}

/* Whatsapp */

.flat-category {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.flat-category:last-child {
    margin-bottom: 0;
}

.flat-category-header {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.flat-category-header h5 {
    color: var(--default-grey);
    font-weight: 600;
}

.flat-category-header nav {
    color: var(--default-color);
    font-size: 10px;
    text-transform: uppercase;
    border: 1px solid;
    line-height: 15px;
    padding: 0 5px;
    border-radius: 5px;
    background: var(--col-active);
}

.ril__caption, .ril__toolbar {
    background: transparent;
}


/* GE view */

.growth-enable-table .table.entity-dashboard-table tbody tr.attach-wrap-multiple td:first-child {
    white-space: nowrap;
}

.growth-enable-table .table.entity-dashboard-table tbody tr.attach-wrap-multiple:last-child td {
    border-bottom: 1px solid #e3e5e8;
    white-space: normal;
    word-break: break-word;
}

.growth-enable-table .table.entity-dashboard-table tbody tr:last-child .attach-wrap-multiple:last-child td {
    border-bottom: 0;
}

.chapter-completed.inactive {
    opacity: .5;
    filter: grayscale(100%);
}

.mention-textarea {
    float: left;
    width: 100%;
    position: relative;
    height: 200px;
}

.mention-textarea .mentions__input {
    float: left;
    width: 100%;
    height: 100% !important;
    background: var(--white) !important;
    border-radius: 5px;
    font-size: 14px !important;
    padding: 14px 15px;
    border: 1px solid #dee1e4;
    line-height: normal;
}

.mention-textarea .mentions.mentions--multiLine {
    float: left;
    width: 100%;
    height: 200px;
}

.mention-textarea .mentions__control {
    float: left;
    width: 100%;
    height: 100%;
}

/* Learn */

.log-wrapper .project-summary-block {
    padding: 0;
}

.log-wrapper {
    float: left;
    width: 100%;
    height: 100%;
    padding-top: 20px;
}

.report-wrapper {
    float: left;
    width: 100%;
    padding-top: 20px;
}

.singline-flex {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.singline-flex .right-switch {
    margin: 0;
}

.full-width-tab .right-switch {
    margin: 0;
}

.each-sub-section.auto-height .each-course-field h3 {
    float: left;
    width: 100%;
}


.full-wid-mod .side-bar-panel.list-view-panel {
    height: calc(100vh - 71px);
    top: 70px;
}

.full-wid-mod .collection-clik-panel .discuss-inner {
    height: calc(100vh - 181px);
}

.full-wid-mod .bsy-modal.record-chat-modal .modal-dialog {
    height: auto;
    max-width: 800px;
    margin: 0 auto;
}

.full-wid-mod .bsy-modal.record-chat-modal .modal-header {
    height: 50px;
    border-radius: 6px 6px 0 0;
}

.full-wid-mod .bsy-modal.record-chat-modal .modal-body {
    height: auto;
    border-radius: 0 0 6px 6px;
    padding: 20px;
    background: transparent;
}

.full-wid-mod .bsy-modal.record-chat-modal .modal-content {
    border-radius: 6px;
}

.full-wid-mod .record-chat-modal .upload-comment {
    border: 1px solid #e4e6e9;
}

.select-chk-block.disable {
    opacity: .5;
    pointer-events: none;
}

/* Video Recorder */


.video-recorder-block {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.video-recorder-start {
    float: left;
    width: 100%;
    height: 240px;
    background: var(--white);
    border: 1px solid #e4e6e9;
    border-radius: 5px;
    max-width: 50%;
    margin-top: 11px;
    position: relative;
}

.video-recorder-start h2, .video-recorder-end h2 {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    background: var(--white);
    color: var(--default-grey);
    padding: 0 8px;
    border: 1px solid #e4e6e9;
    border-radius: 5px;
    text-transform: uppercase;
    position: absolute;
    left: 15px;
    top: -10px;
    z-index: 1;
}

.video-record-options {
    float: left;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
}

.video-record-options .record-button {
    float: left;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: -1px solid var(--default-grey);
    margin: 2px;
    background: white;
    color: #ff5722;
    padding: 0;
    line-height: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-record-options .record-button i {
    font-size: 27px;
}

.video-record-options .start-record {
    border: 2px solid #ff5722;
    color: #ff5722;
}

.video-record-options .start-record i {
    font-size: 18px;
    position: relative;
    top: 1px;
}

.video-recorder-start video, .video-recorder-end video {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.video-recorder-end {
    float: left;
    width: 100%;
    max-width: 50%;
    margin-top: 20px;
    height: 240px;
    border: 1px solid #e4e6e9;
    border-radius: 5px;
    max-width: 50%;
    position: relative;
}

.output-options {
    position: absolute;
    right: 12px;
    top: 12px;
}

.output-options .record-button {
    float: left;
    width: 25px;
    height: 25px;
    background: var(--default-color);
    padding: 0;
    border: 0;
    border-radius: 50%;
    margin-left: 6px;
    color: var(--white);
    font-size: 14px;
}

.template-1 .video-recorder-block {
    padding: 53px 20px 20px;
}

.template-3 .video-recorder-block {
    padding: 53px 20px 20px 0;
}

.template-6 .video-recorder-start {
    background: #eaebeb;
}

.template-7 .video-recorder-start {
    margin-top: 20px;
}

.template-cortiza .video-recorder-start {
    max-width: 70%;
}

.template-11 .video-recorder-block {
    min-width: 400px;
}

.template-11 .video-recorder-start {
    max-width: 100%;
}

.template-18 .video-recorder-start {
    border-radius: 0;
}

.template-20 .video-recorder-start {
    background: #f7f8fc;
    border-radius: 0;
}

.show.dropdown .form-dup-ico:before {
    top: calc(50% - 9px);
}

.modal-header .toast-header .close:before {
    content: none;
}

.theme-color {
    color: var(--default-color) !important;
}

.summary-card-tab .ind-attach-list {
    width: 100%;
}

.summary-card-tab .attach-button-container {
    right: 7px;
    top: 118px;
}


/* Pool task */

.pool-task-listing .data-holder-left.filter-accord {
    margin-top: 0;
}

.pool-task-listing .side-filter-header {
    float: left;
    width: 100%;
    margin-bottom: 16px;
}

.pool-task-listing .side-filter-header p {
    color: var(--default-text);
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    text-transform: uppercase;
    padding-right: 15px;
}

.data-view.field-form.small-no-tab .data-holder.pool-task-listing {
    padding-right: 0;
    padding-bottom: 0;
}

.pool-task-listing .data-holder-right {
    overflow-y: auto;
}

.each-multiple-wf {
    float: left;
    width: 100%;
    padding: 20px;
    border: 1px dashed #e2e5e8;
    background: var(--col-active);
    margin-bottom: 20px;
    position: relative;
    background-image: url(../../images/line-texture.png);
}

.each-multiple-wf .radio-wrap {
    margin-bottom: 0;
}

.add-multiple-workflow-box {
    position: absolute;
    right: -10px;
    bottom: -10px;
}

.each-multiple-wf .inline-select .select-box {
    margin: 0;
}

.pool-task-listing .data-holder-right .task-list-wrapper {
    height: 100%;
}

/* mini card */

.edit-template-right-body .card-container.grid-view.template-collection.mini-card {
    width: 500px;
}

.side-tab .tab-content .tab-pane .mini-card .ind-collection-card {
    padding: 20px;
    background: var(--white);
    min-height: auto;
}

.mini-card-figure {
    float: left;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
}

.mini-card-figure img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #d2d8dc;
}

.mini-card-details {
    float: left;
    width: calc(100% - 120px);
}

.mini-card-details h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: normal;
    word-break: break-word;
}

.mini-card-details p {
    float: left;
    width: 100%;
    font-size: 12px;
    text-transform: uppercase;
    line-height: normal;
    color: var(--default-grey);
    margin-bottom: 4px;
}

.without-img-card .mini-card-figure {
    display: none;
}

.without-img-card .mini-card-details {
    width: 100%;
}

.mini-card-view .ind-collection-card {
    min-height: auto;
    padding-top: 20px;
    width: 100%;
    margin-right: 0;
}

/* .mini-card-view .ind-collection-card:nth-child(3n) {
    margin-right: 20px;
}
.mini-card-view .ind-collection-card:nth-child(4n) {
    margin-right: 0;
} */
.template-container-view .data-holder-right .card-container.template-collection.mini-card-view {
    padding-right: 20px;
    display: block;
}

.mini-card-view .ind-collection-card .select-chk-block {
    position: absolute;
    right: 0px;
    bottom: 25px;
}

.each-map-field {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.each-map-field:last-child {
    margin-bottom: 0;
}

.mini-card-view .row {
    margin-left: -10px;
    margin-right: -10px;
}

.mini-card-view .col {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
}

.spinner-border-sm {
    width: 10px;
    height: 10px;
    border-width: .1em;
    margin: 2px;
    top: -1px;
    position: relative;
}

.menu-section h5 .spinner-border-sm {
    width: 8px;
    height: 8px;
    top: 0;
}

.button-container .general-btn {
    height: 35px;
    padding: 0 10px;
    margin-right: 10px;
}

.learn-permission-wrapper {
    float: left;
    width: 100%;
    height: 100%;
}

.autofill-header {
    float: left;
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.learn-permission-wrapper .scrollable {
    float: left;
    width: 100%;
}

.learn-permission-wrapper .form-table {
    max-width: 100%;
}

.learn-permission-wrapper .form-table .table tbody tr td .select-chk-block {
    margin: 0;
}

.learn-permission-wrapper .form-table .table tbody tr:first-child td {
    border-top: 0;
}

.learn-permission-wrapper .form-table .table tbody tr:last-child td {
    border-bottom: 0;
}

.learn-permission-wrapper .next-stage-selector {
    height: calc(100% - 70px);
    overflow-y: auto;
}

.button-area button {
    float: left;
    margin-left: 10px;
}

.button-area {
    display: flex;
}

.autofill-header .task-inp-container.no-texter .ind-task-input {
    width: auto;
    background: var(--white);
    min-width: 300px;
}

.autofill-header .task-inp-container .ind-task-input {
    background: var(--white);
    border: 1px solid #e2e5e8;
    padding-left: 20px;
    border-radius: 5px;
}

.autofill-header .tag-wrapper-bl input {
    margin: 5px 0;
}

.autofill-header .sl-tag {
    margin-top: 5px;
}

/* Advance Search */

.adv-search-ico {
    float: left;
    width: 43px;
    height: 35px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.adv-search-ico:after {
    content: none;
}

.adv-search-ico:before {
    content: "";
    background: var(--sprite-one) no-repeat -11px -329px;
    width: 21px;
    height: 16px;
    display: inline-block;
}

.show .adv-search-ico:before {
    background-position: -69px -329px;
}

.adv-search-ico.btn-primary:hover {
    background: transparent;
    border: 0;
}

.adv-search-block .dropdown-menu {
    float: left;
    width: 350px;
    height: 295px;
    background: #fff;
    padding: 0;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    box-shadow: 0 5px 7px 0 rgb(0 0 0 / 15%);
}

.adv-search-body {
    float: left;
    width: 100%;
    height: calc(100% - 60px);
}

.adv-search-body-inner {
    float: left;
    width: 100%;
    padding: 15px;
}

.adv-search-body-inner {
    float: left;
    width: 100%;
    padding: 15px;
}

.each-adv-search {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.each-adv-search .each-inp-box {
    width: 100%;
    margin: 0;
}

.each-adv-search > label {
    float: left;
    width: 100%;
    line-height: normal;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--default-grey);
    margin-bottom: 5px;
}

.each-adv-search:last-child {
    margin-bottom: 0;
}

.adv-search-footer {
    float: left;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;
}

.adv-search-footer .general-btn {
    min-width: auto;
    height: 30px;
    padding: 0 15px;
    margin-left: 10px;
}

.adv-search .data-search {
    padding-right: 43px;
}

.adv-search-block .show > .btn-primary.dropdown-toggle,
.adv-search-block .btn-primary:not(:disabled):not(.disabled).active,
.adv-search-block .btn-primary:not(:disabled):not(.disabled):active {
    background: transparent;
    border-color: transparent;
}

.adv-search-body-inner p {
    display: block !important;
    font-size: 10px;
    line-height: normal;
    margin-bottom: 14px;
}

/* Coumn break */

.column-break .style-view {
    margin: 0;
}

.column-break {
    margin-bottom: 0;
}

.column-break .row {
    margin-right: -10px;
    margin-left: -10px;
}

.column-break .col {
    padding-right: 10px;
    padding-left: 10px;
}

.template-5 .column-break .closed-box.form-alignment-lbl .radio-wrapper .radio-block,
.template-5 .column-break .checkbox-wrapper .select-chk-block {
    height: 55px;
}

.template-5 .ind-form-field.column-break .input-block {
    padding: 0;
    margin: 0;
}

.template-5 .ind-form-field.column-break .input-block:before {
    content: none;
}

.template-5 .ind-form-field.column-break .radio-wrapper {
    padding: 0;
    margin: 0;
}

.template-5 .ind-form-field.column-break .checkbox-wrapper {
    padding: 0;
}

.template-5 .ind-form-field.column-break .radio-wrapper:before,
.template-5 .ind-form-field.column-break .checkbox-wrapper:before {
    content: none;
}

.template-5 .ind-form-field.column-break .radio-wrapper .radio-block,
.template-5 .ind-form-field.column-break .checkbox-wrapper .select-chk-block {
    height: 56px;
    margin: 0;
}

.template-5 .column-break .style-view {
    margin-bottom: 30px;
}

.template-7 .column-break .closed-box.form-alignment-lbl .radio-wrapper .radio-block,
.template-7 .column-break .checkbox-wrapper .select-chk-block {
    height: 55px;
}

.template-7 .ind-form-field.column-break .input-block {
    padding: 0;
    margin: 0;
}

.template-7 .ind-form-field.column-break .input-block:before {
    content: none;
}

.template-7 .ind-form-field.column-break .radio-wrapper {
    padding: 0;
    margin: 0;
}

.template-7 .ind-form-field.column-break .checkbox-wrapper {
    padding: 0;
}

.template-7 .ind-form-field.column-break .radio-wrapper:before,
.template-7 .ind-form-field.column-break .checkbox-wrapper:before {
    content: none;
}

.template-7 .ind-form-field.column-break .radio-wrapper .radio-block,
.template-7 .ind-form-field.column-break .checkbox-wrapper .select-chk-block {
    height: 56px;
    margin: 0;
}

.template-7 .column-break .style-view {
    margin-bottom: 30px;
}

.template-11 .column-break .type-form-input {
    width: 100%;
}

.template-12 .column-break .each-type-question {
    flex-direction: column;
    height: 100%;
}

.template-12 .column-break .type-question-left {
    width: 100%;
    border-radius: 24px 24px 0 0;
}

.template-12 .column-break .type-question-right {
    width: 100%;
    border-radius: 0 0 24px 24px;
}

.template-12 .column-break .type-question-left > h5 {
    font-size: 20px;
}

.template-12 .column-break .each-type-question.files .type-question-right .upload-wrapper.sm-line {
    padding: 0;
    border: 0;
    margin: 0;
}

/* Template 21 */

.template-22 .detailed-form-view-right-flow-top * {
    font-family: 'Libre Franklin';
}

.template-22 .detailed-form-view-right-flow-top .detailed-form-view-right-body .container .form-preview-container {
    float: left;
    width: 100%;
}

.template-22 .detailed-form-view-container {
    background: #F1F2F6;
}

.template-22 .ind-form-field .input-block {
    border: 0;
    display: flex;
    flex-direction: column;
    background: var(--white);
    border: 1px solid #EDEDED;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 10px 16px;
}

.template-22 .ind-form-field .anim-text {
    position: relative;
    left: 0;
    top: 0;
    color: #979797;
}

.template-22 .label-title-block {
    order: 0;
}

.template-22 .ind-form-field .fiels-inp-box {
    order: 1;
    height: 24px;
    padding: 0;
}

.template-22 .ind-form-field .input-block textarea {
    height: 160px;
    padding-top: 5px;
}

.template-22 .ind-form-field .radio-wrapper {
    float: left;
    width: 100%;
    padding: 0;
    border: 0;
    margin-bottom: 10px;
}

.template-22 .ind-form-field .radio-wrapper .radio-block {
    padding-right: 25px;
}

.template-22 .radio-block label:before {
    width: 24px;
    height: 24px;
    border: 2px solid #D3D3D3;
    background: transparent;
}

.template-22 .ind-form-field .radio-wrapper .radio-block label {
    padding-left: 32px;
    line-height: 24px;
    color: var(--black);
}

.template-22 .radio-block input:checked + label:before {
    border: 2px solid #333333;
}

.template-22 .radio-block input:checked + label:after {
    content: "";
    width: 14px;
    height: 14px;
    background-color: #333333;
    left: 5px;
    top: 5px;
}

.template-22 .ind-form-field .checkbox-wrapper {
    float: left;
    width: 100%;
    padding: 0;
    border: 0;
    margin-bottom: 10px;
}

.template-22 .ind-form-field .checkbox-wrapper .select-chk-block .control {
    line-height: 24px;
    padding-left: 32px;
}

.template-22 .ind-form-field .checkbox-wrapper .select-chk-block .control .checkbox-indicator {
    width: 24px;
    height: 24px;
    left: 0;
    top: 0;
    border: 2px solid #D3D3D3;
    background: transparent;
}

.template-22 .input-check-box.active + .checkbox-indicator {
    background: transparent;
}

.template-22 .checkbox-indicator:after {
    background: none;
    transform: rotate(45deg);
    height: 14px;
    width: 8px;
    border-bottom: 2px solid #333333;
    border-right: 2px solid #333333;
    left: 6px;
    top: 0;
    display: none;
}

.template-22 .input-check-box.active + .checkbox-indicator {
    border-color: #333333 !important;
}

.template-22 .ind-form-field .input-block.drop {
    background: transparent;
    border: 0;
    padding: 0;
}

.template-22 .ind-form-field .input-block.drop select {
    height: 48px;
    background: var(--white) !important;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    margin-top: 10px;
}

.template-22 .ind-form-field .input-block.drop:before {
    z-index: 10;
    top: 47px;
}

.template-22 .toggle .field-common.field-req-block {
    padding: 0;
    height: auto;
}

.template-22 .ind-form-field .input-block.toggle {
    background: transparent;
    border: 0;
    padding: 0;
}

.template-22 .btn-toggle {
    width: 48px;
    border: 2px solid #D3D3D3;
    height: 24px;
    background: transparent;
}

.template-22 .handle {
    width: 14px;
    height: 14px;
    box-shadow: none;
    background: #D3D3D3;
    left: 3px;
    top: 3px;
    transition: .3s ease-out;
}

.template-22 .btn-toggle:before {
    content: none;
}

.template-22 .btn-toggle:after {
    content: none;
}

.template-22 .btn-toggle.active {
    background: #4f4f4f;
}

.template-22 .ind-form-field .input-block.signature {
    padding: 0;
}

.template-22 .ind-form-field .input-block.signature .general-btn.grey {
    box-shadow: none;
}

.template-22 .ind-form-field .signature-left label {
    font-size: 14px;
    color: #979797;
}

.template-22 .ind-form-field .input-block.html-editor {
    padding: 0;
    background: transparent;
    border: 0;
}

.template-22 .btn-toggle.active .handle {
    left: 27px;
}

.template-22 .toggle .field-common label {
    font-size: 14px;
}

.template-22 .label-title-block .field-header {
    margin-bottom: 10px;
    font-size: 14px;
    color: #979797;
}

.template-22.detailed-form-view .input-block .quill {
    border-color: #ededed;
    border-radius: 4px;
}

.template-22.detailed-form-view .input-block .ql-container.ql-snow {
    border-color: #ededed;
}

.template-22 .input-block.date-field .form-type-input {
    padding: 0;
}

.template-22 .ind-form-field .form-type-input .form-control {
    height: 24px;
    padding: 0;
}

.template-22 .ind-form-field .input-block.attachment-box {
    background: transparent;
    padding: 0;
    border: 0;
}

.template-22 .ind-form-field .upload-wrapper.sm-line {
    background: var(--white);
    border-color: #ededed;
    border-radius: 4px;
    height: 60px;
}

.template-22 .ind-form-field .input-block.video-capture {
    padding: 10px 16px 16px;
}

.template-22 .preview-component {
    margin-top: 10px;
}

.template-22 .otp-button-block {
    order: 1;
    padding: 0;
}

.template-22 .ind-form-field .input-block.otp-field .fiels-inp-box::placeholder {
    opacity: 1;
}

.template-22 .ind-form-field .input-block.feedback-field {
    padding: 10px 16px 16px;
}

.template-22 .ind-form-field .input-block.feedback-field .feedback-block {
    height: auto;
    display: flex;
    align-items: center;
}

.template-22 .display-component {
    margin-top: 10px;
}

.template-22 .ind-form-field .input-block.payment {
    background: transparent;
    border: 0;
    padding: 0;
}

.template-22 .ind-form-field .input-block.resources {
    background: transparent;
    border: 0;
    padding: 0;
}

.template-22 .ind-form-field .input-block.resources .field-header {
    display: none;
}

.template-22 .ind-form-field .mandatory-box .fiels-inp-box {
    border-bottom: transparent;
}

.template-22 .ind-form-field .mandatory-box {
    border: 1px solid #FFBBBB;
}

.template-22 .ind-form-field .input-block.video-capture .video-recorder-block {
    margin-top: 10px;
}

.template-22 .video-record-options .start-record i {
    font-family: 'FontAwesome';
}

.template-22 .date-field .label-title-block .field-header {
    margin: 0;
}

.template-22 .upload-image-container {
    margin-top: 20px;
}

.template-22 .ind-form-field .input-block.photo-capture-block {
    padding: 10px 16px 16px 16px;
}

.template-22 .ind-form-field .fieldset:before {
    background-color: rgb(53 123 183 / 20%);
}

.template-22 .ind-form-field h5.field-header {
    font-weight: 400;
    font-size: 14px;
    color: #979797;
    margin-bottom: 0;
}

.template-22 .ind-form-field .otp-button-block p {
    font-size: 12px;
}

.template-22 .otp-button-block a {
    font-size: 12px;
}

.template-22 .ind-form-field .feedback-field h5.field-header {
    margin-bottom: 10px;
}

.template-22 .phonenumber .phone-number-field {
    margin-top: 10px;
    background: var(--white);
}

.template-22 .phone-number-field .select-box select {
    background: transparent;
    border: 0;
    padding-left: 0;
    height: 24px;
}

.template-22 .phone-number-field .select-box {
    width: 80px;
}

.template-22 .phone-number-field .fiels-inp-box::placeholder {
    opacity: 1;
}

.template-22 .ind-form-field .radio-wrapper h5, .template-22 .ind-form-field .checkbox-wrapper h5 {
    font-size: 14px;
}

.template-22 .btnsubIconsadd {
    width: 24px;
    height: 24px;
    background: #D3D3D3;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.template-22 .ind-form-field .btnsubIconsadd:before {
    content: "+";
    background-position: unset;
    background: none;
    width: auto;
    height: auto;
    font-size: 20px;
}

.template-22 .ind-form-field .input-block .rightButtons,
.template-22 .ind-form-field .upload-wrapper.sm-line .rightButtons {
    right: 8px;
    bottom: -12px;
}

.template-22 .btnsubIcon {
    width: 24px;
    height: 24px;
    background: #D3D3D3;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.template-22 .ind-form-field .btnsubIcon:before {
    content: "";
    background-position: unset;
    background: none;
    width: 8px;
    height: 2px;
    background-color: #000000;
    font-size: 18px;
    display: inline-block
}

.template-22 .ind-form-field .fieldset .input-block .rightButtons {
    left: unset;
    right: 8px;
    bottom: -12px;
    transform: rotate(0deg);
}

.template-22 .form-input-block-left .form-type-input {
    border: 0;
    padding: 0;
    min-height: 24px;
}

.template-22 .ind-form-field .input-block.computed-field .field-header {
    margin: 0;
}

.template-22 .label-title-block .autofill-label {
    margin-bottom: 0;
    font-size: 14px;
    color: #979797;
    font-weight: 400;
}

.template-22 .ind-form-field .input-block .ind-task-input.selectize-custom {
    padding: 0;
    height: auto;
}

.template-22 .ind-form-field .tag-wrapper-bl input {
    padding-left: 0;
}

.template-22 .form-preview-header .preview-subheader {
    font-weight: 700;
}

.template-22 .form-preview-header p {
    font-weight: 300;
    color: #979797;
}

/* Template 21 */

/* column break */

.template-1 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: 4px;
    bottom: -36px;
    max-width: calc(100% - 18px);
}

.template-1 .column-break .upload-image-container {
    margin: 10px;
    margin-top: 50px;
    max-width: calc(100% - 20px);
}

.template-1 .column-break .limit-text {
    max-width: 100%;
}

.template-2 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: 0px;
    bottom: -36px;
    max-width: 100%;
}

.template-2 .column-break .upload-image-container {
    margin-top: 50px;
}

.template-2 .column-break .limit-text {
    max-width: 100%;
}

.template-3 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: 4px;
    bottom: -36px;
    max-width: calc(100% - 18px);
}

.template-3 .column-break .upload-image-container {
    margin: 10px;
    margin-top: 50px;
    max-width: calc(100% - 20px);
}

.template-3 .column-break .limit-text {
    max-width: 100%;
}

.template-4 .column-break .toggle .field-common label {
    margin-bottom: 0;
}

.template-4 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: -6px;
    bottom: -36px;
    max-width: 100%;
}

.template-4 .column-break .upload-image-container {
    margin-top: 50px;
}

.template-5 .column-break .toggle .field-common label {
    margin-bottom: 15px;
}

.template-5 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: 0;
    top: auto;
    bottom: -34px;
    max-width: 100%;
}

.template-5 .column-break .upload-image-container {
    margin-top: 50px;
}

.template-6 .column-break .toggle .field-common label {
    margin-bottom: 5px;
}

.template-6 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: -5px;
    top: auto;
    bottom: -36px;
    max-width: 100%;
}

.template-6 .column-break .upload-image-container {
    margin-top: 50px;
}

.template-7 .column-break .toggle .field-common label {
    margin-bottom: 15px;
}

.template-7 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: 0;
    top: auto;
    bottom: -34px;
    max-width: 100%;
}

.template-7 .column-break .upload-image-container {
    margin-top: 50px;
}

.template-13 .column-break .toggle .field-common label {
    margin-bottom: 0;
    line-height: 20px;
    margin-bottom: 8px;
}

.template-13 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: -4px;
    top: auto;
    bottom: -36px;
    max-width: 100%;
}

.template-13 .column-break .upload-image-container {
    margin-top: 50px;
}

.template-14 .column-break .toggle .field-common label {
    margin-bottom: 0;
    line-height: 20px;
    margin-bottom: 8px;
}

.template-14 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: -4px;
    top: auto;
    bottom: -36px;
    max-width: 100%;
}

.template-14 .column-break .upload-image-container {
    margin-top: 50px;
}

.template-18 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: -4px;
    top: auto;
    bottom: -36px;
    max-width: 100%;
}

.template-18 .column-break .upload-image-container {
    margin-top: 50px;
}

.template-19 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: -4px;
    top: auto;
    bottom: -36px;
    max-width: 100%;
}

.template-19 .column-break .upload-image-container {
    margin-top: 50px;
}

.template-20 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: -4px;
    top: auto;
    bottom: -36px;
    max-width: 100%;
}

.template-20 .column-break .upload-image-container {
    margin-top: 50px;
}

.template-22 .ind-form-field .column-break .upload-wrapper .file-tag {
    position: absolute;
    right: -4px;
    top: auto;
    bottom: -36px;
    max-width: 100%;
}

.template-22 .column-break .upload-image-container {
    margin-top: 50px;
}

.left-panel.multi-card-record.fieldset-template {
    padding: 20px;
    width: calc(100% - 270px);
    margin: 0;
    padding-left: 60px;
}

.entity-preview-right {
    background: transparent;
}

.audio-ico:before {
    background-position: -505px -143px;
    width: 13px;
    height: 11px;
}

.audio-player {
    float: left;
    width: 100%;
    margin: 7px 0px;
}

.audio-player audio {
    height: 35px;
    padding: 0;
    border: 1px solid #e2e5e8;
    border-radius: 14px;
}

.data-learner-course-right-header .audio-player {
    width: auto;
    margin: 0;
}

.left-panel.multi-card-record.fieldset-template .table.form-preview-table tbody tr td,
.left-panel.multi-card-record.fieldset-template .table.form-preview-table tbody tr th {
    padding-top: 10px;
    padding-bottom: 10px;
}

.left-panel.multi-card-record.fieldset-template .fixed-container {
    width: 60%;
}

.left-panel.multi-card-record.fieldset-template .card-container {
    width: 40%;
}

/* Column break end */

.inine-btn .general-btn {
    height: 35px;
}

.template-extra-proprties-head.inine-btn {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.outer-scroll .collection-template-body {
    height: auto;
    overflow-y: unset;
}

.outer-scroll .template-extra-proprties {
    height: 500px;
}

.propogation-container.learn-section-body.outer-scroll {
    height: auto;
}

.builder-mod .modal-header {
    background: var(--theme-second);
}

.builder-mod .modal-header h5 {
    padding: 0;
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
}

.builder-mod .modal-header-options {
    position: relative;
    top: -3px;
    right: 0;
}

.ind-small-tab-dashboard.payment {
    height: auto;
    min-height: 90px;
}

.ind-small-tab-dashboard.payment .small-tab-header {
    margin: 0;
}

.ind-small-tab-dashboard.payment .small-tab-header h5 {
    margin-bottom: 10px;
}

.ind-small-tab-dashboard.payment .small-tab-header p {
    line-height: normal;
    margin-bottom: 5px;
    word-break: break-word;
}

.ind-small-tab-dashboard.payment .small-tab-header.with-desc-box .general-btn {
    height: 35px;
    margin-top: 8px;
}

.ind-small-tab-dashboard.payment .small-tab-header p a {
    color: var(--default-color);
}

.full-width.growth-mod .payment-modal-wrap .modal-header {
    background: var(--theme-second);
    border-radius: 5px 5px 0 0;
    margin: 0;
}

.full-width.growth-mod .payment-modal-wrap .modal-header h5.task-head-text {
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--white);
}

.full-width .payment-modal-wrap .modal-lg {
    max-width: 800px;
    margin: 0 auto;
}

.full-width.growth-mod .payment-modal-wrap .modal-content {
    height: 400px;
}

.payment-absolute {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: var(--default-grey);
}

.payment-selector-wrap {
    float: left;
    width: 100%;
    height: 100%;
}

.data-search-box .payment-selector-wrap {
    float: left;
    width: 100%;
}

.payment-selector-wrap .data-search-box {
    width: 100%;
    padding: 0 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.payment-selector-wrap .data-search-box .search-ico:before {
    left: 14px;
}

.payment-selector-wrap .data-search-box .data-search {
    border-radius: 5px;
}

.payment-selector-wrap .payment-mode {
    float: left;
    width: 100%;
    height: calc(100% - 60px);
    padding: 5px 20px 20px 20px;
    overflow-y: auto;
}

.payment-selector-wrap .payment-mode .radio-wrapper .radio-block {
    width: 100%;
    margin-right: 0;
}


.growth-enable-table.auto-width-table {
    overflow: auto;
}

.growth-enable-table.auto-width-table .table.entity-dashboard-table tbody tr td {
    width: auto;
    white-space: nowrap;
    min-width: 200px;
}

.growth-enable-table.auto-width-table .table.entity-dashboard-table tbody tr td:first-child {
    position: sticky;
    left: 0;
    background: var(--white);
}

.growth-unique-record .editor-content p {
    color: var(--default-text);
    text-transform: none;
    font-size: 16px;
    margin-bottom: 15px;
    float: none;
}

.editor-content ol {
    list-style: decimal;
    padding-left: 15px;
}

.editor-content ul {
    list-style: disc;
    padding-left: 15px;
}

.editor-content ol li {
    margin-bottom: 10px;
    line-height: 20px;
}

.editor-content ul li {
    margin-bottom: 10px;
    line-height: 20px;
}

.editor-content p strong {
    font-weight: 600 !important;
}

.course-preview-box .rich-text-preview p {
    float: left;
    width: 100%;
}

.task-wrapper .course-preview-box .rich-text-preview img {
    max-width: 430px;
    margin: 20px 0;
    padding: 0;
    display: block;
    float: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.data-learner-course-right-footer .audio-player {
    width: auto;
}

.data-learner-course-right-footer.left-aligned {
    justify-content: space-between;
}

.task-wrapper .data-learner-course-right-body .course-preview-box iframe.ql-video {
    width: 430px;
    min-height: 242px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
}

/* Summary panel */

.each-figure-entry figure {
    float: left;
    width: 100%;
    height: 200px;
}

.each-figure-entry figure img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-entry-body .collection-headers {
    margin-bottom: 0;
}

.profile-entry-body.summary-edit .collection-headers {
    margin-bottom: 15px;
}

.form-single-value-box h5 .form-resource-preview.preview-mode {
    margin-top: 10px;
}

.table.form-preview-table tbody tr td .form-resource-preview.preview-mode {
    margin-top: 5px;
}

.table.form-preview-table tbody .col-tr .col-td .form-resource-preview.preview-mode {
    margin-top: 2px;
}

.with-card-preview .table.form-preview-table tbody tr td .form-resource-preview.preview-mode {
    margin-top: 8px;
}

.inline-permission-option {
    float: left;
    width: 100%;
    position: relative;
    padding-right: 20px;
    /* display: flex; */
}

.inline-permission-edit {
    float: left;
    width: 20px;
    height: 16px;
    font-size: 0;
    background: transparent;
    border: 0;
    padding: 0;
    line-height: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.inline-permission-edit:before {
    content: "";
    background: var(--sprite-two) no-repeat -179px -203px;
    width: 16px;
    height: 16px;
    display: inline-block;
}

.inline-permission-edit:hover:before {
    background-position: -179px -226px;
}

.form-table .table tbody tr td .more-option-box {
    float: left;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.form-table .table tbody tr td .more-option-box .select-chk-block {
    margin-bottom: 15px;
}

.form-table .table tbody tr td .more-option-box .select-chk-block:last-child {
    margin-bottom: 0;
}

.form-table .table tbody tr td .more-option-box .select-chk-block .control {
    font-size: 16px;
    color: var(--default-grey);
}

.form-set .form-table .print-settings tbody tr td:first-child {
    padding-left: 25px;
    width: 20%;
    vertical-align: middle;
}

.form-set .form-table .print-settings tbody tr td:first-child .wf-btn-block {
    position: absolute;
    right: 0;
    top: 22px;
}

.form-set .form-table .print-settings tbody tr td:last-child {
    text-align: right;
}

.form-set .form-table .print-settings tbody tr td:last-child .general-btn.focus-resolve {
    padding: 4px 15px;
    line-height: 30px;
}

.settings-head h5 {
    float: left;
    width: auto;
    max-width: 80%;
    white-space: normal;
    font-size: 14px;
    font-weight: 600;
}

.form-set .form-table .print-settings tbody tr td:last-child .general-btn.focus-resolve:hover {
    color: var(--white);
}

.mobile-setting-wrapper {
    float: left;
    width: 100%;
    padding: 20px 0;
}

.mobile-setting-header {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.mobile-setting-header h5 {
    font-size: 18px;
    font-weight: 600;
}

.right-btn .general-btn {
    margin-left: 10px;
    padding: 0 15px;
}

.form-set .side-tab .tab-content .form-table .set-permission tbody tr td {
    min-width: 130px;
}

.post-param-panel .each-inp-field label {
    color: var(--default-grey);
}

/* Blog template */

.blog .right-panel {
    display: none;
}

.blog .left-panel {
    width: 100%;
    margin-right: 0;
}

.blog .right-panel-white-box {
    margin-right: 30px;
    padding: 45px;
}

.blog .right-panel-white-box .collection-card-right-header h5 > div > p {
    float: left;
    width: 100%;
    color: var(--default-text);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-transform: unset;
    margin-bottom: 10px;
}

.collection-meta {
    float: left;
    width: auto;
    height: 35px;
    padding: 0 20px;
    margin-left: 14px;
    background: #fff;
    border: 1px solid var(--default-color);
    border-radius: 3px;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin-right: 14px;
}

.collection-meta:before {
    content: "";
    background: var(--sprite-two) no-repeat -147px -430px;
    width: 12px;
    height: 12px;
    display: inline-block;
}

.blog.active .left-panel {
    width: calc(100% - 350px);
    margin-right: 30px;
}

.blog.active .right-panel {
    display: block;
}

.meta-content-box {
    float: left;
    width: 320px;
    background: #fff;
    padding: 25px 30px;
    border-radius: 5px;
    border: 1px solid #e4e6e9;
    box-shadow: 0 1px 13px 2px rgb(0 0 0 / 5%);
    margin-bottom: 15px;
    position: relative;
}

.meta-content-box .collection-card-right {
    height: auto;
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.meta-content-box .form-resource-preview {
    margin-top: 10px;
}

.blog-record-details {
    float: left;
    width: 100%;
}

.blog-record-details .collection-card-right {
    float: left;
    width: 100%;
    margin-bottom: 25px;
}

.meta-content-box .collection-card-right-header h5 {
    font-size: 12px;
    text-transform: uppercase;
    color: var(--default-grey);
    margin-bottom: 0;
}

.left-panel .meta-content-box .collection-card-right-header p {
    font-size: 16px;
    color: var(--default-text);
    font-weight: 500;
    text-transform: unset;
    word-break: break-word;
}

.left-panel .meta-content-box .collection-card-right-header p .rate-star {
    margin: 5px 0 0 0;
}

.meta-content-box .collection-card-right-header {
    margin-bottom: 15px;
}

.entity-preview-wrapper.blog {
    height: auto;
}

.switch-mode.radio-wrapper.type-wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
}

.type-header-wrapper {
    color: var(--default-grey);
}

.list-viewer.board-list .tile-each {
    min-height: 124px;
}

.category-filter-title {
    float: left;
    width: 100%;
    font-size: 12px !important;
    color: var(--default-grey) !important;
    font-weight: 600 !important;
    text-transform: none;
    margin-bottom: 7px;
}

.blog-title-block .collection-card-right,
.blog-subtitle-block .collection-card-right {
    margin-bottom: 0;
}

.blog-title-block .collection-card-right .collection-card-right-header {
    margin: 0;
}

.blog-title-block .collection-card-right .collection-card-right-header h5 {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
}

.blog-title-block {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.blog-subtitle-block {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.blog-subtitle-block .collection-card-right .collection-card-right-header h5 {
    font-size: 14px;
    line-height: 21px;
}

.blog .collection-card-right-header h5 {
    line-height: 27px;
    overflow: unset;
    max-height: none;
}

.collection-card-left.article-upload {
    width: 100%;
    margin: 0 0 25px 0;
}

.article-upload .form-resource-preview {
    margin-top: 0;
}

.article-upload .form-resource-block .file-each {
    width: 100%;
    margin: 0 0 25px 0;
    height: auto;
    border-radius: 0;
    border: 0;
}

.article-upload .collection-card-right-header {
    margin-bottom: 0;
}

.article-upload .collection-card-image {
    height: auto;
}

.article-upload .collection-card-right {
    margin-bottom: 0;
}

.article-upload .form-resource-block .file-each img {
    object-fit: contain;
}

.article-upload .form-resource-block .file-each:last-child {
    margin-bottom: 0;
}

.article-upload .form-resource-preview.preview-mode .file-each.image-type img {
    border-radius: 0;
}

.blog .custom-image {
    width: 100%;
    max-width: 30%;
    object-fit: contain;
    margin-right: 0;
}

.blog .collection-card-right-header h5 video {
    width: 30%;
    height: auto;
}

.blog .collection-card-right-header {
    margin-bottom: 0;
}

.blog .left-panel .collection-card-image .format {
    height: 34px;
    position: relative;
    left: 0;
    top: 0;
}

.blog .form-resource-preview.preview-mode {
    margin-top: 0;
}

.article-resource-upload .collection-card-image {
    height: auto;
}

.blog .form-resource-preview.preview-mode .file-each .format {
    padding-bottom: 2px;
}

.blog .article-upload .file-each-opt button {
    width: 21px;
    height: 21px;
    zoom: 2.0;
}

.blog .article-upload .file-each.image-type:hover:before {
    opacity: 0;
}

/* H-card */

.side-tab .tab-content .tab-pane .h-card .ind-collection-card {
    padding: 20px;
    background: var(--white);
    min-height: auto;
    padding-left: 55px;
    position: relative;
}

.h-card-details h5 {
    float: left;
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 2px;
}

.h-card-details p {
    font-size: 16px;
    line-height: normal;
}

.edit-template-right-body .card-container.grid-view.template-collection.h-card {
    width: 100%;
}

/* Team */

.settings-edit {
    float: left;
    width: 12px;
    height: 13px;
    background: transparent;
    padding: 0;
    border: 0;
    margin-left: 8px;
    position: absolute;
    right: 10px;
    top: 7px;
    z-index: 9;
    display: none;
}

.settings-edit:before {
    background-position: -531px -453px;
    width: 12px;
    height: 13px;
}

.permission-checkbox.active:hover .settings-edit {
    display: block;
}

.collection-summary-chat {
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
}

.collection-clik-panel .collection-summary-chat .discuss-inner {
    height: calc(100vh - 249px);
}

.team-permission .permission-checkbox {
    transition: .3s ease-out;
}

.team-permission .permission-checkbox.active:hover {
    padding-right: 30px;
}

.permission-settings.permission-main.team-full-width {
    width: 100%;
    max-width: calc(100vw - 50px);
}

.checkbox-down {
    float: left;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.checkbox-down .select-chk-block {
    margin-bottom: 15px;
}

.checkbox-down .select-chk-block:last-child {
    margin-bottom: 0;
}

.date-mod.action-sets .modal-body {
    float: left;
    width: 100%;
    justify-content: flex-start;
}

.checkbox-down .select-chk-block .control {
    font-size: 16px;
    color: var(--default-grey);
}

.container-full-width .chart-settings-splitter {
    width: 100%;
}

/* Learn */

.each-course-field h4,
.instruction-block h4 {
    margin-top: 3px;
    margin-bottom: 10px;
}

.each-course-field em,
.instruction-block em {
    font-style: italic;
}

.course-preview-box .rich-text-preview * {
    line-height: normal;
}

.ql-snow .ql-editor h2,
.ql-snow .ql-editor p,
.rich-text-preview h2,
.rich-text-preview p {
    line-height: normal;
}

.ql-snow .ql-editor p img {
    margin: 15px 0;
}


/* record preview */

.right-panel-white-box {
    padding: 30px 40px;
}

.table.form-preview-table tbody tr td {
    line-height: normal;
}

.table.form-preview-table tbody tr td .custom-image {
    margin: 5px 0;
}

.right-panel-white-box .form-single-value-box .custom-image,
.right-panel-white-box .form-single-value-box video {
    margin-top: 8px;
}

/* Attendance */

.apply-panel-header .general-btn {
    height: 35px;
    min-width: auto;
    padding: 0 20px;
    box-shadow: none;
    border-radius: 36px;
    line-height: 35px;
    border: 0;
}

.mt-20 {
    margin-top: 5px;
}

.user-prof {
    color: var(--white);
    font-size: 12px;
    line-height: 32px;
    background: var(--default-color);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -1px;
    padding: 0;
}

.user-profile-thumb {
    float: left;
    width: 360px;
    height: 320px;
    object-fit: cover;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 100px;
    letter-spacing: -8px;
    font-weight: 300;
    border: 3px solid #EAEAEA;
    background: var(--default-color);
    color: var(--white);
}


/* filter */

.without-filter .label-menu-right-block {
    display: none;
}

.without-filter .data-holder-left {
    display: none;
}

.without-filter .data-holder-right {
    width: 100%;
}

.without-filter .form-table {
    max-width: calc(100vw - 70px);
}

/* Learn */

.learn-detail-panel .form-detail-top.learn-top h5 {
    font-size: 24px;
    line-height: normal;
    margin-bottom: 5px;
}

.learn-detail-panel .common-bk-button:before {
    top: 10px;
}

.learn-detail-panel .common-align .form-detail-top-left {
    padding-left: 28px !important;
    width: 100%;
}

.learn-detail-panel .data-view .common-align .form-detail-top p {
    font-size: 16px;
}

.learn-detail-panel .form-detail-top p {
    font-size: 16px;
    margin-bottom: 8px;
}

.learn-detail-panel .form-detail-top .course-date-info {
    font-size: 12px;
}

.learn-detail-panel .learn-course-info {
    position: absolute;
    right: 65px;
    top: 0px;
    width: auto;
}

.learn-detail-panel .form-detail-top-inside-wrapper {
    position: relative;
}

.learn-detail-panel .height-210 .learn-course-info {
    border: 1px solid #e2e5e8;
    margin: 0;
    border-radius: 5px;
    background: #FAFAFA;
}

.learn-detail-panel .learn-course-info li:after {
    content: none;
}

.learn-detail-panel .learn-course-info li {
    margin-right: 0;
    border-right: 1px solid #e2e5e8;
    padding-left: 10px;
    line-height: 30px;
}

.learn-detail-panel .learn-course-info li:last-child {
    border-right: 0;
}

.learn-detail-panel .learn-option-block {
    margin-top: 8px;
}

.learn-detail-panel .form-option-block {
    top: 33px;
}

.data-view.learn-detail-panel .with-publish .form-detail-top.learn-top.height-210 {
    height: auto;
    padding-bottom: 25px;
    max-height: none;
}

.data-view .form-detail-panel.with-publish.learn-tab-block + .data-holder.learn-holder.more-height {
    height: 100%;
}

.learn-detail-panel {
    display: flex;
    flex-direction: column;
}

.preview-drop {
    display: inline-block;
}

.preview-drop .dropdown-toggle {
    font-size: 14px;
    font-weight: 600;
    border: 1px solid var(--default-color);
    display: flex;
    align-items: center;
}

.preview-drop .dropdown-menu {
    border: 1px solid #eaeaea;
    box-shadow: 0 5px 7px 0 rgb(0 0 0 / 15%);
}

.preview-drop .dropdown-menu a {
    color: var(--default-grey);
    padding: 0px 20px;
}

.preview-drop .dropdown-menu a:hover,
.preview-drop .dropdown-menu a:focus {
    color: var(--default-color);
    background: #FAFAFA;
}

.learn-detail-panel .each-learn-section {
    padding: 0;
    background: transparent;
    box-shadow: none;
    border: 0;
}

.learn-detail-panel .learn-section-header {
    margin-bottom: 0;
}

.learn-detail-panel .ind-draggable-item {
    padding-bottom: 10px;
}

.learn-detail-panel .each-sub-section.auto-height {
    box-shadow: aliceblue;
}

.learn-detail-panel .learn-section-header h5 {
    font-size: 16px;
}

.learn-detail-panel .learn-section-header-inner > p {
    font-size: 14px;
    margin-bottom: 5px !important;
}

.no-learn-header .data-learner-course-body {
    height: 100%;
}

.learn-detail-panel .each-chapter-list nav {
    border-bottom: 1px solid #e2e5e8;
    padding-right: 40px;
}

.no-learn-header .data-learner-course-body {
    height: 100%;
}

.learn-detail-panel .each-chapter-list:last-child nav {
    border-bottom: 0;
}

.learn-detail-panel .each-chapter-list nav.chapter-completed h5:before {
    left: auto;
    right: -30px;
    top: 5px;
}

.learn-detail-panel .each-chapter-list nav p {
    font-size: 0;
    position: absolute;
    left: 20px;
    top: 18px;
}

/* .learn-detail-panel .each-chapter-list nav.current-chapter h5 {
    padding-left: 20px;
} */
.learn-detail-panel .each-chapter-list nav p span:before {
    zoom: 1.5;
}

.learn-detail-panel .each-chapter-list nav h5 {
    margin-bottom: 0;
    padding-left: 25px;
}

.learn-detail-panel .each-chapter-list nav {
    position: relative;
}

.learn-detail-panel .each-chapter-list nav p .assessment-chapter:before {
    zoom: .7;
}

.learn-detail-panel .each-chapter-list nav p .richtext-chapter:before {
    zoom: 1;
}

.learn-detail-panel .each-chapter-list nav p .forms-chapter:before {
    background-position: -457px -165px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    zoom: .8;
}

.learn-detail-panel .learner-accordion .accordion-btns {
    cursor: pointer;
}

.learn-detail-panel .each-sub-section.auto-height .each-course-field {
    width: 100%;
}

.learn-detail-panel .each-sub-section.auto-height .each-course-field p.created-text {
    width: auto;
    position: absolute;
    right: -30px;
    bottom: 15px;
    margin: 0;
    font-size: 12px;
}

.learn-detail-panel .each-sub-section.auto-height .each-course-field {
    padding-bottom: 35px;
}

.learn-detail-panel .course-report-header {
    margin-bottom: 0;
}

.data-view.field-form.learn-detail-panel .course-report-header + .data-holder.learn-holder {
    height: calc(100% - 286px);
}

/* .learn-detail-panel .log-wrapper {
    height: auto;
} */
.learn-detail-panel.show-sm .each-chapter-list nav p,
.mob-lg-preview .each-chapter-list nav p {
    left: 15px;
}

.learn-detail-panel .course-detailed-info h5 {
    font-size: 24px;
    margin-bottom: 6px;
}

.learn-detail-panel .course-detailed-info p {
    font-size: 16px;
    line-height: normal;
}

.course-player-box .course-detailed-info h5 {
    font-size: 24px;
}

.course-player-box .course-detailed-info p {
    font-size: 16px;
    margin-bottom: 10px;
}

.tab-wrap-block-right.rt-switch .right-switch {
    margin-right: 15px;
}

/* Copy link */

.input-block.copy-link {
    margin-top: 20px;
    margin-bottom: 10px;
}

.copy-link .copy-ico {
    position: absolute;
    right: 0;
    top: 0;
    height: 45px;
    box-shadow: none;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.copy-ico:before {
    background-position: -396px -386px;
    width: 12px;
    height: 14px;
    margin-right: 8px;
}

.copy-link input {
    padding-right: 130px;
}

.copy-link .success-fdls {
    text-align: right;
    font-size: 14px;
}

/* @media (max-width: 1200px) {
    .blog.active .left-panel {
        width: 100%;
    }
    .meta-content-box {
        width: 100%;
    }
} */

/* Calendar update */

.sticky-calendar .calendar th:before {
    content: "";
    width: 100%;
    height: 1px;
    background: #dee0e3;
    position: absolute;
    left: 0;
    top: 0;
}

.sticky-calendar .calendar th:after {
    content: "";
    width: 100%;
    height: 1px;
    background: #dee0e3;
    position: absolute;
    left: 0;
    bottom: 0;
}

.sticky-calendar .calendar th {
    position: sticky;
    left: 0;
    top: 0;
    background: var(--white);
    z-index: 1;
}

.sticky-calendar .calendar .day-headers th {
    border: 0;
}

.table.form-preview-table.v-align tbody tr:last-child td {
    border: 0;
    padding-bottom: 0;
}

.table.form-preview-table tbody tr:last-child th,
.table.form-preview-table tbody tr:last-child td {
    border: 0;
    padding-bottom: 0;
}

.action-assign {
    float: left;
    display: flex;
    align-items: center;
}

.remove-assign {
    float: left;
    width: 32px;
    height: 32px;
    background: transparent;
    border: 1px solid red;
    margin-right: 10px;
    border-radius: 5px;
}

.remove-assign:before {
    background-position: -348px -365px;
    width: 9px;
    height: 10px;
}

.inline-color-picker {
    float: left;
    width: 100%;
    padding: 0 15px;
}

.inline-color-picker .each-inp-field {
    flex-direction: row;
    align-items: center;
}

.inline-color-picker .each-inp-field label {
    margin-right: 12px;
}

.couser-index .card-header {
    border-bottom: 0;
}

.couser-index .card-header {
    cursor: auto;
}

.course-user {
    cursor: pointer;
}

.course-user figure {
    flex-shrink: 0;
}

.min-height-preview .form-table {
    height: 250px;
}

/* Listing preview */

.multiple-box {
    float: left;
    width: 100%;
    display: flex;
    margin-top: 30px;
}

.multiple-box-wrap {
    float: left;
    width: 50%;
}

.multiple-box-wrap:first-child {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #e3e5e8;
}

.multiple-box-wrap .checkbox-wrapper {
    margin: 0;
}

.multiple-box-header {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.multiple-box-header h5 {
    font-size: 16px;
    font-weight: 600;
}

.multiple-box-header .general-btn {
    height: 30px;
    min-width: auto;
    padding: 0 12px;
}

.multiple-box-body {
    float: left;
    width: 100%;
}

.multiple-box-body .select-chk-block {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.resources-filters-right .general-btn {
    min-width: auto;
    padding: 0 15px;
    margin-left: 10px;
}

.summary-card-tab.no-header .nav-tabs .nav-link {
    font-size: 0;
    font-weight: 400;
}

.summary-tab:before {
    content: "\f0ca";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.smap-tab:before {
    content: "\f0e8 ";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.payment-tab:before {
    content: "\f0d6";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.flag-tab:before {
    content: "\f024 ";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.email-tab:before {
    content: "\f003";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.sms-tab:before {
    content: "\f27b";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.payment-tab:before {
    content: "\f0d6";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.wa-tab:before {
    content: "\f232";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.chat-tab:before {
    content: "\f086";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.dashboard-tab:before {
    content: "\f0e4";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.notes-tab:before {
    content: "\f249";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.share-form-tab:before {
    content: "\f064";
    font-family: 'FontAwesome';
    background: none;
    font-size: 14px;
}

.summary-card-tab .persitant-top.padding-0 {
    padding: 0;
}

.radio-container.sub-action-wrap {
    flex-direction: column;
}

.next-stage-selector .multiple-box-body .select-chk-block {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.ge-filter {
    float: left;
    display: flex;
    align-items: center;
}

.ge-filter .select-box select {
    height: 30px;
    min-width: 140px;
}

.ge-header {
    display: flex;
    align-items: center;
}

.ge-filter .select-box {
    margin-right: 10px;
}

.preview-area.chat-panel .format {
    height: 50px;
}

.learn-detail-panel .ind-draggable-item {
    background: #e4e4e4;
    border: 1px solid #d9d9d9;
    padding: 20px;
}

.system-task-holder-body .expandable-textarea .ql-toolbar.ql-snow {
    position: sticky;
    left: 0;
    top: 0;
    background: #FFF;
    z-index: 9;
    border-bottom: 1px solid #eceff1;
    margin-bottom: -1px;
}

.template-container-view .data-holder-right .card-container.template-collection.long-card-view {
    padding-right: 20px;
    display: block;
}

/* Long Card View */
.long-card-view .ind-collection-card {
    min-height: auto;
    padding-top: 020px;
    width: 100%;
    margin-right: 0;
    padding: 20px;
    background: var(--white);
    min-height: auto;
    padding-left: 55px;
    position: relative;
}

.long-card-view .col {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
}

.long-card-view .row {
    margin-left: -10px;
    margin-right: -10px;
}

.long-card-view .h-card-details .select-chk-block {
    position: absolute;
    left: 25px;
    top: 33px;
}

.long-card-view .form-option-block {
    top: 42px;
}

.long-card-view .h-card-details h5 {
    padding-right: 35px;
}

.payment-button-container {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
}

.payment-button-container .general-btn {
    margin-right: 10px;
}

/* Long card */

.long-card-figure {
    float: left;
    width: 115px;
    height: 115px;
    margin-right: 20px;
    margin-top: 5px;
    background: #e4e4e4;
    border-radius: 5px;
    flex-shrink: 0;
}

.long-card-figure img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.edit-template-right-body .card-container.grid-view.template-collection.h-card .ind-collection-card {
    display: flex;
}

.edit-template-right-body .card-container.grid-view.template-collection.h-card .with-img-card .ind-collection-card .h-card-details {
    float: left;
    width: calc(100% - 135px);
}

.data-holder-right .card-container.template-collection.long-card-view.with-img-card .ind-collection-card {
    display: flex;
}

.data-holder-right .card-container.template-collection.long-card-view.with-img-card .ind-collection-card .h-card-details {
    float: left;
    width: calc(100% - 135px);
    padding-right: 30px;
}

/* Whatsapp */

.custom-text-field .each-details-entry {
    height: auto;
    min-height: 35px;
}

.custom-text-field .details-entry-left {
    padding: 10px;
    border-right: .5px solid #dee0e3;
}

.custom-text-field .details-entry-right {
    border-left: 0;
}

/* project */

.ibt-header .float-button-block .general-btn {
    padding: 0 15px;
    height: 35px;
}

.ibt-header-bottom {
    padding-bottom: 40px;
}

/* Data Binding */

.data-binding-wrapper {
    float: left;
    width: 100%;
}

.data-binding-table {
    float: left;
    width: 100%;
    margin: 0;
}

.data-binding-table thead tr th,
.table.form-preview-table tbody tr td .data-binding-table thead tr th {
    border-bottom: 1px solid #e4e6e9;
    padding: 10px 15px;
    color: var(--default-text);
    line-height: 25px;
    font-weight: 600;
    font-size: 14px;
    vertical-align: middle;
}

.data-binding-table tbody tr td,
.table.form-preview-table tbody tr td .data-binding-table tbody tr td {
    border-bottom: 1px solid #e4e6e9;
    font-size: 16px;
    padding: 10px 15px;
    vertical-align: middle;
    color: var(--default-grey);
    white-space: nowrap;
    min-width: 150px;
}

.table.form-preview-table.v-align tbody tr:last-child td .data-binding-table tbody tr td {
    border-bottom: 1px solid #e4e6e9;
}

.table.form-preview-table tbody tr td .data-binding-table {
    border: 1px solid #e4e6e9;
}

.table.form-preview-table.v-align tbody tr:last-child td .data-binding-table tbody tr:last-child td {
    border-bottom: 0;
}

.table.form-preview-table.v-align tbody tr .data-binding-table thead tr,
.table.form-preview-table.v-align tbody tr .data-binding-table tbody tr {
    display: table-row;
    width: 100%;
}

.table.form-preview-table.v-align tbody tr .data-binding-table thead tr th,
.table.form-preview-table.v-align tbody tr .data-binding-table tbody tr td {
    width: auto;
    padding: 10px 15px;
}

.table.form-preview-table.v-align tbody tr td.db-header-padding {
    padding: 0;
}

.table.form-preview-table.v-align tbody tr td.db-header-padding .db-fieldset-heading {
    padding-left: 0;
    padding-right: 0;
}

.data-binding-table tbody tr td.selection-db {
    min-width: auto;
    width: 20px;
}

.data-binding-table tbody tr td .fiels-inp-box {
    padding: 0;
    height: 35px !important;
    font-size: 16px;
    color: var(--default-grey);
}

.data-binding-table tbody tr td .input-block {
    width: 100% !important;
    justify-content: center !important;
    min-height: auto !important;
    margin: 0 !important;
}

.data-binding-table tbody tr td .input-block > input {
    padding: 0 15px;
    color: var(--default-text);
    background: var(--white) !important;
}

.data-binding-table tbody tr td .display-component .display-inner > .display-text-content {
    padding: 0;
    margin: 0;
}

.data-binding-table tbody tr td .display-inner {
    margin: 0 !important;
}

.data-binding-table tbody tr td .display-component {
    margin-bottom: 0 !important;
}

.data-binding-table tbody tr td .display-component .display-inner > .display-text-content h5 {
    margin: 0;
    font-weight: 400;
    color: var(--default-grey);
    font-size: 16px !important;
}

.data-binding-table tbody tr td.selected {
    background: var(--col-active) !important;
}

.data-binding-table tbody tr td .input-block.drop:before {
    top: 15px !important;
}

.data-binding-table tbody tr td .checkbox-indicator {
    top: calc(50% - 7.5px);
}

.db-fieldset-heading {
    float: left;
    width: 100%;
    padding: 20px 15px 15px 15px;
}

.db-fieldset-heading h5 {
    font-weight: 600;
}

.db-header-padding {
    padding: 0 !important;
}

.db-header-padding .db-fieldset-heading {
    padding-left: 0;
}

.db-table .form-preview-table {
    table-layout: fixed;
}

.data-binding-wrapper.data-binding-padding .db-fieldset-heading {
    padding: 0 0 20px;
}

.data-binding-padding .data-binding-table {
    border: 1px solid #e4e6e9;
}

.p-h-0 .db-fieldset-heading {
    padding-top: 0;
}

.p-h-0 .db-fieldset-heading {
    padding-top: 0;
}

.p-h-0 .data-binding-table {
    border: 1px solid #e4e6e9;
}

.template-1 .data-binding-table tbody tr td .display-component {
    margin: 0;
    padding: 0;
}

.template-1 .data-binding-table tbody tr td .input-block.display-text {
    padding: 0;
    border: 0 !important;
}

.template-3 .data-binding-table tbody tr td .display-component {
    margin: 0;
    padding: 0;
}

.template-3 .data-binding-table tbody tr td .input-block.display-text {
    padding: 0;
    border: 0 !important;
}

.template-1 .data-binding-table thead tr th {
    padding: 10px 15px;
}

.template-1 .data-binding-table tbody tr td {
    padding: 10px 15px;
}

.template-1 .data-binding-table tbody tr td .input-block {
    border: 1px solid #e4e6e9 !important;
}

.template-2 .data-binding-table {
    border: 1px solid #e4e6e9;
}

.template-2 .data-binding-table thead tr th,
.template-2 .data-binding-table tbody tr td {
    background: var(--white);
}

.template-3 .data-binding-table {
    border: 1px solid #e4e6e9;
}

.template-3 .data-binding-table tbody tr td .input-block {
    height: auto !important;
    border: 1px solid #e4e6e9 !important;
}

.template-4 .data-binding-table {
    border: 1px solid #e4e6e9;
}

.template-5 .data-binding-table tbody tr td .fiels-inp-box {
    color: var(--white);
}

.template-5 .data-binding-table tbody tr td .fiels-inp-box::placeholder,
.template-7 .data-binding-table tbody tr td .fiels-inp-box::placeholder {
    opacity: 0 !important;
}

.template-5 .data-binding-table thead tr th {
    color: var(--white);
}

.template-5 .data-binding-table tbody tr td.selected {
    background: rgb(0 92 255 / 50%) !important;
}

.template-5 .data-binding-table {
    border: 1px solid var(--white);
}

.template-5 .data-binding-table tbody tr td .checkbox-indicator:before {
    content: "";
    width: 25px;
    height: 25px;
    border: 2px solid #0091ff;
    position: absolute;
    border-radius: 5px;
    left: 0px !important;
    top: 1px !important;
    background-color: #192c54;
    z-index: 9;
}

.template-5 .data-binding-table tbody tr td .checkbox-indicator {
    opacity: 1;
    border: 0;
    left: 0;
    top: -1px;
    width: 25px !important;
    height: 25px !important;
}

.template-5 .data-binding-table tbody tr td .select-chk-block {
    width: 25px;
    height: 25px;
}

.template-5 .data-binding-table tbody tr td .control {
    width: 25px;
    height: 25px;
}

.template-5 .data-binding-table tbody tr td .input-check-box.active + .checkbox-indicator {
    width: 25px;
    height: 25px;
    opacity: 1;
    z-index: 1;
    background: transparent;
    left: 0;
    top: -1px;
}

.template-5 .data-binding-table tbody tr td .checkbox-indicator:after {
    left: 7px;
    top: 9px;
}

.template-6 .data-binding-table tbody tr td .fiels-inp-box {
    background: transparent !important;
    padding: 0;
}

.template-6 .data-binding-table tbody tr td .input-block > input {
    background: #eaebeb !important;
    padding: 0 15px;
}

.template-6 .data-binding-table {
    border: 1px solid #e4e6e9;
}

.template-7 .data-binding-table tbody tr td .input-block > input {
    border-color: #e4e6e9 !important;
}

.template-7 .data-binding-table {
    border: 1px solid #e4e6e9;
}

.template-7 .data-binding-table tbody tr td .checkbox-indicator:before {
    content: "";
    width: 25px;
    height: 25px;
    border: 2px solid #0091ff;
    position: absolute;
    border-radius: 5px;
    left: 0px !important;
    top: 1px !important;
    background-color: #fff;
    z-index: 9;
}

.template-7 .data-binding-table tbody tr td .checkbox-indicator {
    opacity: 1;
    border: 0;
    left: 0;
    top: -1px;
    width: 25px !important;
    height: 25px !important;
}

.template-7 .data-binding-table tbody tr td .select-chk-block {
    width: 25px;
    height: 25px;
}

.template-7 .data-binding-table tbody tr td .control {
    width: 25px;
    height: 25px;
}

.template-7 .data-binding-table tbody tr td .input-check-box.active + .checkbox-indicator {
    width: 25px;
    height: 25px;
    opacity: 1;
    z-index: 1;
    background: transparent;
    left: 0;
    top: -1px;
}

.template-7 .data-binding-table tbody tr td .checkbox-indicator:after {
    left: 7px;
    top: 9px;
}

.template-11 .data-binding-table tbody tr td {
    padding: 10px 15px;
}

.template-11 .data-binding-table thead tr th {
    padding: 10px 15px;
}

.template-11 .data-binding-table tbody tr td .type-form-input > label {
    display: none;
}

.template-11 .data-binding-table tbody tr td .type-form-input {
    margin: 0;
}

.template-13 .data-binding-table thead tr th {
    padding: 10px 15px;
}

.template-13 .data-binding-table tbody tr td {
    padding: 10px 15px;
}

.template-13 .data-binding-table tbody tr td .fiels-inp-box {
    background: transparent !important;
    border: 0;
}

.template-13 .data-binding-table tbody tr td .input-block > input {
    background: #FAFAFA !important;
    border: 1px solid #e0e0e0;
}

.template-14 .data-binding-table thead tr th {
    padding: 10px 15px;
}

.template-14 .data-binding-table tbody tr td {
    padding: 10px 15px;
}

.template-14 .data-binding-table tbody tr td .fiels-inp-box {
    background: transparent !important;
    border: 0;
}

.template-14 .data-binding-table tbody tr td .input-block > input {
    background: #FAFAFA !important;
    border: 1px solid #e0e0e0;
}

.template-18 .data-binding-table tbody tr td .fiels-inp-box,
.template-19 .data-binding-table tbody tr td .fiels-inp-box,
.template-20 .data-binding-table tbody tr td .fiels-inp-box {
    border: 0 !important;
    background: transparent !important;
}

.template-18 .data-binding-table tbody tr td .input-block > input,
.template-19 .data-binding-table tbody tr td .input-block > input,
.template-20 .data-binding-table tbody tr td .input-block > input {
    border: 1px solid #e4e4e4 !important;
    background: var(--white) !important;
}

.template-18 .data-binding-table,
.template-19 .data-binding-table,
.template-20 .data-binding-table {
    border: 1px solid #e4e6e9;
}

.template-20 .data-binding-table tbody tr td .fiels-inp-box {
    background: transparent !important;
}

.template-20 .data-binding-table tbody tr td .input-block > input {
    background: #F7F8FC !important;
}

/* Board */

.max-modal .board-modal-header {
    background: var(--white);
}

.max-modal .board-modal-header .input-box {
    background: var(--white);
    color: var(--default-text);
}

.max-modal .mod-close-ico:before {
    content: "";
    background: var(--sprite-one) no-repeat -379px -71px;
    width: 13px;
    height: 13px;
    display: inline-block;
}

.bsy-modal.large-modal.max-modal .modal-lg {
    max-width: 90%;
    height: 100;
}

.bsy-modal.large-modal.max-modal .modal-body {
    height: 100%;
}

.bsy-modal.max-modal .modal-content {
    height: calc(100vh - 57px);
}

.white-theme .ind-board-list.newcard {
    border: 0;
    padding: 0;
    box-shadow: none;
}

.white-theme .ind-board-list.newcard:hover {
    box-shadow: none;
}

.white-theme .ind-board-list.newcard textarea {
    height: 182px;
}

.bsy-modal.white-theme .modal-header {
    background: var(--white);
}

.white-theme .task-head-text {
    color: var(--default-text);
}

.white-theme .icon-in.icon-close-in {
    background-position: -379px -71px;
    width: 13px;
    height: 13px;
}

/* Kanban view */

.kanban-wrapper {
    float: left;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 20px 0 20px;
    display: flex;
    overflow: auto;
}

.kanban-category {
    float: left;
    width: calc(100% / 4 - 20px);
    flex-shrink: 0;
}

.kanban-category-header {
    float: left;
    width: 100%;
    height: 40px;
    padding: 0 20px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.kanban-category-body {
    float: left;
    width: 100%;
    height: calc(100% - 50px);
    padding-top: 3px;
}

.kanban-category-body-inner {
    float: left;
    width: 100%;
    padding: 0 20px 0 10px;
}

.each-kanban-task {
    float: left;
    width: 100%;
    background: var(--white);
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
    margin-right: 8px;
}

.each-kanban-task:last-child {
    margin-bottom: 3px;
}

.kanban-category:first-child .kanban-category-header {
    padding-left: 0;
}

.kanban-category:first-child .kanban-category-body-inner {
    padding-left: 0;
}

.kanban-task-header {
    float: left;
    width: 100%;
    background: transparent;
    border-bottom: 1px solid #e4e5e9;
    padding: 15px;
    position: relative;
}

.kanban-task-header h5 {
    float: left;
    width: 100%;
    padding-left: 27px;
    font-size: 15px;
    font-weight: 600;
    color: var(--default-text);
}

.kanban-task-header .act-priority {
    position: absolute;
    left: 15px;
    top: 13px;
}

.task-footer {
    float: left;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.task-footer p {
    font-size: 12px;
    text-transform: uppercase;
    color: var(--default-grey);
}

.by-status {
    font-size: 15px;
    font-weight: 600;
    color: var(--default-grey);
}

.kanban-category .team-perform-thumb {
    width: 25px;
    height: 25px;
}

.team-perform-det h6 {
    font-size: 15px;
    font-weight: 600;
    color: var(--default-grey);
}


.kanban-card-wrapper {
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
}

.each-kanban-card {
    border: 1px solid #d4d4d4;
    background: #ecf0f1;
    border-radius: 5px;
    width: 350px;
}

.kanban-card-wrapper .board-wrapper {
    padding: 0;
    padding-bottom: 20px;
}

.each-kanban-card .board-header {
    float: left;
    width: 100%;
    margin: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.board-body {
    float: left;
    width: 100%;
    height: calc(100% - 40px);
}

.board-body .dropdown-menu-cont-inner {
    padding-top: 5px;
}

.each-kanban-card:last-child {
    margin-right: 0;
}


/* Resources */

.form-more-option .form-option-block {
    width: 40px;
    height: 40px;
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-more-option .form-option-block .opt-form-ico {
    position: relative;
    right: 0;
    top: 0;
}

.flags-ico:before {
    background-position: -33px -403px;
    width: 17px;
    height: 17px;
}

.flags-ico:hover:before {
    background-position: -33px -378px;
}

.form-more-option .form-option-block .panel-option {
    top: 31px;
    right: -3px;
}

.flag-block {
    float: left;
    width: 100%;
    height: 100%;
}

.flag-block-header {
    float: left;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
}

.flag-block-header button.general-btn.focus-resolve {
    height: 30px;
    padding: 0 15px;
    min-width: auto;
}

.flag-block-body {
    float: left;
    width: 100%;
    height: calc(100% - 55px);
    padding: 20px;
    overflow-y: auto;
}

.each-flag-list {
    float: left;
    width: 100%;
    border: 1px solid #e4e4e4;
    padding: 15px;
    border-radius: 5px;
    background: #FAFAFA;
    margin-bottom: 15px;
    position: relative;
}

.each-flag-list h5 {
    float: left;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 5px;
}

ul.flat-list li {
    float: left;
    width: auto;
    font-size: 14px;
    border: 1px solid var(--default-color);
    color: var(--default-color);
    background: var(--white);
    padding: 5px 8px 5px 28px;
    border-radius: 17px;
    margin-right: 10px;
    transition: .3s ease-out;
    position: relative;
}

.flat-list li .wf-btn-block {
    position: absolute;
    right: 8px;
    top: 7px;
}

.flat-list li:hover .wf-btn-block {
    opacity: 1;
    pointer-events: auto;
}

.flat-list li:hover {
    padding-right: 44px;
}

.flat-list li .wf-btn-block button:first-child {
    margin-left: 0;
}

.flat-list li .wf-btn-block button {
    zoom: .9;
}

.flat-list li:before {
    content: "";
    background: var(--sprite-one) no-repeat -33px -378px;
    width: 17px;
    height: 17px;
    position: absolute;
    left: 10px;
    top: 7px;
    zoom: .8;
}

.flat-list {
    float: left;
    width: 100%;
    margin-top: 6px;
}

.bsy-modal.height-520 .modal-body.padding-20 {
    padding: 20px;
}

.modal-body.padding-20 .ind-task-input {
    padding-left: 0;
}

.modal-body.padding-20 .hint-label.addnew-hint {
    padding: 15px 0;
    margin: 0;
}

.bsy-modal.height-520 .modal-body.no-padding {
    padding: 0;
}


/* course */

.info-icon {
    float: left;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 0;
    padding: 0;
    line-height: 0;
    position: absolute;
    right: 30px;
    top: 5px;
}

.info-icon:before {
    background-position: -147px -430px;
    width: 12px;
    height: 12px;
    zoom: 1.5;
}

/* Learn */

.learn-detail-panel .side-bar-panel.list-view-panel {
    height: calc(100vh);
    top: 0;
}

.learn-summary {
    float: left;
    width: 100%;
    height: 100%;
    padding: 10px 20px 20px 20px;
}

.learn-summary > ul {
    background: var(--white);
    float: left;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    margin-bottom: 20px;
}

.learn-summary > ul li {
    float: left;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.learn-summary > ul li:last-child {
    margin: 0;
}

.learn-summary > ul li p {
    float: left;
    width: 100%;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--default-grey);
    margin-bottom: 5px;
}

.learn-summary > ul li h5 {
    float: left;
    width: 100%;
    font-size: 16px;
    color: var(--default-text);
    margin-bottom: 5px;
}

.header-label-top {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.header-label-top .select-chk-block > label {
    font-size: 14px;
}

.upload-text-area {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
}

.upload-media {
    float: left;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    bottom: -13px;
}

.upload-media button {
    float: left;
    width: 100%;
    height: 100%;
    border: 0;
    background: var(--default-color);
    border-radius: 50%;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-media button input {
    float: left;
    width: 1005;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.upload-media button:before {
    content: "\f093";
    font-family: 'FontAwesome';
    font-size: 12px;
}

.header-label-top .select-chk-block {
    position: relative !important;
    right: 0 !important;
    top: 0 !important;
    margin: 0 !important;
}

.file-tag-wrapper .file-tag {
    padding-right: 35px;
}

/* Batch */

.mode-wrapper {
    float: left;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #eceff1;
}

.mode-wrapper label {
    margin-bottom: 20px;
    font-size: 15px;
}

.mode-wrapper .each-field-block .select-chk-block.task-select {
    width: auto;
    padding: 0;
}

.mode-wrapper .each-field-block .select-chk-block.task-select label {
    margin-bottom: 0;
}

/* Time line */

.each-timeline-list {
    float: left;
    width: 100%;
    padding-left: 0;
    display: flex;
    align-items: flex-start;
    position: relative;
}

.timeline-list-wrapper {
    float: left;
    width: 100%;
}

.timeline-list-wrapper .ind-collection-card {
    width: 100%;
}

.timeline-slot {
    float: left;
    width: auto;
    padding: 15px;
    background: var(--white);
    border: 1px solid #e2e5e8;
    border-radius: 5px;
    flex-shrink: 0;
    /* left: 0; */
    margin-right: 20px;
    z-index: 1;
}

.each-timeline-list:before {
    content: "";
    width: 1px;
    height: 100%;
    background: #ccc;
    position: absolute;
    left: 44px;
}

.timeline-slot h5 {
    font-size: 18px;
    font-weight: 600;
}

.col.col-lg-12.col-md-6.col-sm-12:last-child .each-timeline-list:before {
    content: none;
}

/* Learn */

.each-assessment .file-tag-wrapper {
    float: left;
    width: 100%;
}

.each-assessment .upload-image-container {
    margin-top: 25px;
}

.form-table.fixed-header .table thead th.col-calendar {
    width: 1%;
    padding: 0;
}

.form-table.fixed-header .table tbody tr td.col-calendar .col-calendar-ico {
    cursor: pointer;
}

.scehdule-mod .patient-wrapper {
    padding: 0;
}

.scehdule-mod .modal-lg {
    max-width: 1140px;
}

.scehdule-mod .book-appointment-modal .e-popup.e-popup-open.e-dialog {
    box-shadow: none;
}

.scehdule-mod .event-editor-block-3 {
    width: calc(100% - 443px);
}

.scehdule-mod .even-time-block {
    max-width: 50%;
}

.scehdule-mod .event-editor-block-2 .react-datepicker-popper[data-placement^="top"] {
    margin-top: 20px;
}

.scehdule-mod .book-appointment-modal .e-btn.e-flat.e-primary,
.scehdule-mod .e-css.e-btn.e-flat.e-primary {
    color: white !important;
}

.scehdule-mod .even-time-block h5 {
    font-size: 16px;
}

.schedule-call-right-footer {
    float: left;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
}

.schedule-call-right-inner .each-inp-field > label {
    color: var(--default-grey);
}


/* Learn */

.assessment-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
}

.assessment-options .radio-block {
    width: auto;
}

.each-inp-box input + .upload-media {
    bottom: 5px;
}

.each-inp-box input + .upload-media {
    bottom: 5px;
}

.assessment-img {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    z-index: 99;
}

.assessment-img img {
    float: left;
    width: auto;
    object-fit: contain;
    max-width: 200px;
    border-radius: 5px;
}

.assessment-img video {
    float: left;
    width: auto;
    object-fit: contain;
    max-width: 350px;
    border-radius: 5px;
}

.assessment-img audio {
    float: left;
    width: 100%;
    min-width: 320px;
}

.each-assessment-preview ul li .assessment-img {
    margin-top: 5px;
}

.correct-answer .assessment-img {
    margin-top: 10px;
}

.upload-image-container audio {
    width: 100%;
}

.assess-correct-ans .assessment-img {
    width: auto;
}

.assessment-answer-title {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.course-index-table.learn-inline-table .learn-table tbody tr td {
    min-width: 200px;
}

.course-index-table .learn-table tbody tr.section-learn td {
    background: var(--col-active);
}

.each-assessment-preview ul li .assessment-img audio {
    max-width: 320px;
}

.assessment-img.audio-assessment {
    width: auto;
}

/* Live Call */

.call-button-block {
    float: left;
    width: 100%;
    margin-top: 10px;
}

.call-button-block .general-btn {
    height: 35px;
    min-width: auto;
    padding: 0 15px;
    box-shadow: none;
}

.call-button-block .general-btn i {
    margin-right: 5px;
    font-size: 14px;
}

.schedule-call-right-inner {
    float: left;
    width: 100%;
    height: calc(100% - 70px);
    padding: 20px;
    overflow-y: auto;
}

.call-summary-list li {
    float: left;
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
    color: var(--default-grey);
}

.call-summary-list li.caller-name {
    color: var(--default-text);
    font-weight: 600;
    font-size: 18px;
    line-height: normal;
}

.call-summary-list li i {
    margin-right: 5px;
    min-width: 14px;
    display: inline-flex;
    align-items: center;
}

.assessment-options .radio-block label {
    display: flex;
}

.summary-card-tab .tab-content > .tab-pane .ind-small-tab-dashboard.payment {
    box-shadow: none;
}

.schedule-list-details h5 {
    float: left;
    width: 100%;
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    color: var(--default-text);
    margin-bottom: 3px;
    transition: .3s ease-out;
}

.schedule-list-details p {
    float: left;
    width: 100%;
    font-size: 14px;
    line-height: normal;
    color: var(--default-grey);
    margin-bottom: 3px;
}

.bsy-table tbody tr:hover td .schedule-list-details h5 {
    color: var(--default-color);
}

.bsy-table tbody tr td .call-button-block {
    margin: 0;
}

.correct-answer .assessment-img audio {
    width: auto;
}

.e-dlg-header .toast-header * {
    color: white;
}

.e-dlg-header .toast-header .close:before {
    content: none;
}

.e-dlg-header .toast-header .close {
    width: 23px;
    height: 23px;
}

.schedule-inner .main-wrapper-no-layout {
    position: relative;
    z-index: 1;
}

.schedule-inner .event-editor-block-3 {
    width: 60%;
}

.schedule-inner .even-time-block {
    width: 100%;
    max-width: 100%;
}

.schedule-inner .event-editor-block-2 .react-datepicker__day--today {
    background: transparent;
    border: 1px solid var(--default-color);
    color: var(--default-color) !important;
}

.schedule-inner .event-editor-block-2 .react-datepicker__day--today:hover {
    background: var(--default-color);
}

.schedule-inner .react-datepicker__day.react-datepicker__day--today.react-datepicker__day--selected-sc {
    color: var(--white) !important;
}

.schedule-inner .event-editor-block-2 .react-datepicker__day--selected {
    background: var(--default-grey);
    color: white !important;
}

.scehdule-mod .react-datepicker__day.react-datepicker__day--selected-sc {
    background: var(--default-color);
}

.schedule-inner .react-datepicker__day.react-datepicker__day--selected-sc {
    background: var(--default-color);
}

.bsy-table.schedule-table tbody tr td:first-child {
    width: 50%;
}

.assessment-options .radio-block input.active + label:after {
    content: "";
    width: 7px;
    height: 7px;
    background-color: var(--default-color);
    position: absolute;
    left: 4px;
    top: 4px;
    border-radius: 50%;
}

.schedule-call-right-inner .radio-wrap .radio-block {
    margin-bottom: 10px;
}

.schedule-call-right-inner .radio-wrap {
    margin-top: 10px;
}

.schedule-call-right-inner .tag-wrapper-bl input {
    min-width: 150px;
    max-width: 150px;
}

.right-panel-group-each img {
    float: left;
    width: 100%;
    max-width: 50%;
}

.schedule-inner .time-slot {
    width: auto;
    padding: 0 20px;
}

.schedule-inner .time-slot:nth-child(3n) {
    margin-right: 20px;
}

.schedule-inner .even-time-block .time-slots h5 {
    font-weight: 600;
    color: var(--default-grey);
}

.schedule-inner .day-slots-avaible-cnt {
    color: #03a9f4;
    margin-bottom: 20px;
}

.schedule-inner.inner .even-time-block {
    overflow-y: auto;
}


.schedule-inner .book-appointment-modal .e-dialog .e-dlg-header-content {
    justify-content: space-between;
}

.schedule-inner .book-appointment-modal .e-dialog .e-dlg-header-content .general-btn {
    height: 35px;
}

.booking-date-header {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.booking-date-header .general-btn {
    height: 30px;
    padding: 0 15px;
    min-width: auto;
    flex-shrink: 0;
}

.schedule-inner .even-time-block .booking-date-header h5 {
    margin-bottom: 0;
}

.time-slots .select-chk-block.task-select {
    margin-bottom: 20px;
    padding: 0;
}

.time-slot.lv-applied {
    background: #e0f7fa;
}

.lv-added {
    background: #e0f7fa;
    border: 1px solid #ccc;
}

.schedule-call-right-footer .general-btn {
    height: 35px;
    margin: 0 0 0 15px;
    min-width: auto;
    padding: 0 12px;
    box-shadow: none;
}

.collection-call {
    float: left;
    width: auto;
    height: 35px;
    padding: 0 20px;
    margin-left: 14px;
    background: #fff;
    border: 1px solid var(--default-color);
    color: var(--default-color);
    border-radius: 3px;
    line-height: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.collection-call:hover {
    background: var(--default-color);
    color: var(--white);
}

/* Resources */

.each-note-added {
    float: left;
    width: 100%;
    margin-bottom: 15px;
    background: #E9EDB0;
    padding: 20px;
    border-radius: 4px 4px 0 4px;
    /* border: 1px solid #4AAED8; */
    /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%); */
    position: relative;
}

.white-bg {
    background: white !important;
}

.each-note-added:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 0;
    border-color: #e4cf7a transparent transparent transparent;
    position: absolute;
    right: 0;
    bottom: 0;
}

.each-note-added:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    right: 0;
    bottom: 0;
}

.each-note-added:hover .wf-btn-block {
    opacity: 1;
    pointer-events: auto;
}

.each-note-added h5 {
    float: left;
    width: 100%;
    font-size: 14px;
    line-height: 21px;
}

.each-flag-list .form-option-block.nav-option {
    top: 34px;
    right: 20px;
}

/* report */

.report-table {
    max-width: calc(100vw - 310px);
    margin-top: 20px;
    max-height: calc(100vh - 160px);
}

.report-header-top {
    float: left;
    width: 100%;
    height: 70px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-panel-holder.has-filter.full-height-panel {
    height: calc(100% - 60px);
    padding: 0px;
    overflow-x: auto;
    overflow-y: auto;
    max-width: calc(100vw - 270px);
}

.report-header-top.form-detail-top-right .general-btn {
    margin: 0;
}

.report-table .table-responsive {
    height: 100%;
}

.report-table-wrapper .table-responsive {
    background: white;
    height: 100%;
}

.report-tbl {
    background: white;
    border: 1px solid #e4e6e9;
}

.report-tbl thead tr th {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    white-space: nowrap;
    border-width: 1px;
    border-color: #e4e6e9;
    border-bottom: 0;
    background: white;
    position: sticky;
    left: 0;
    top: 0;
}

.report-tbl thead tr th:before {
    content: "";
    width: 100%;
    height: 1px;
    background: #e4e6e9;
    position: absolute;
    left: 0;
    bottom: 0;
}

.report-tbl tbody tr td {
    font-size: 16px;
    color: var(--default-grey);
    white-space: nowrap;
    min-width: 150px;
}

.report-tbl tbody tr:first-child td {
    border-top: 0;
}

.report-table-wrapper {
    float: left;
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: auto;
    background: white;
}


/* Show Form */

.summary-modal .full-width .modal-lg {
    max-width: 800px;
    margin: 20px auto;
    height: calc(100% - 46px);
}

.summary-modal .modal:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 50%);
    position: absolute;
    left: 0;
    top: 0;
}

.summary-modal .form-full-height.alone-page .detailed-form-view-right-bottom {
    left: calc(50% - 400px);
    bottom: 25px;
    max-width: 800px;
    padding: 0;
}

.summary-modal .detailed-form-view.direct-flow .modal-body .form-preview-header .close {
    background: white;
    right: 20px;
    top: 20px;
}

.summary-modal .detailed-form-view-right-flow-top .detailed-form-view-right-top {
    padding: 15px 0 0 0;
}

.summary-modal .detailed-form-view.direct-flow .modal-body .form-preview-header .close:hover {
    background: white;
    opacity: 1;
}

/* Record preview  */

.right-panel-white-box .table.form-preview-table tbody tr td p img {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.completed-ico:before {
    background: url(https://d22znjc9y9upyw.cloudfront.net/assets/bsynapse/images/thumbs-up-ico.png) no-repeat;
    background-size: cover;
    width: 32px;
    height: 32px;
}

.error .completed-ico:before {
    background: url(https://d22znjc9y9upyw.cloudfront.net/assets/bsynapse/images/sad-icon.png) no-repeat;
    background-size: cover;
    width: 32px;
    height: 32px;
}

.flag-add-ico:before {
    background-position: -459px -88px;
    width: 16px;
    height: 18px;
}

.flag-add-ico:hover:before {
    background-position: -459px -64px;
    width: 16px;
    height: 18px;
}

.icon-report {
    background: var(--sprite-two) no-repeat -583px -301px;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
}

/* Collection */

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-between .general-btn {
    height: 35px;
    min-width: auto;
    padding: 0 15px;
}

.max-50 {
    float: left;
    width: 50%;
}

.each-inp-box.flex-indent {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 60px;
}

.each-inp-box.flex-indent label {
    margin: 0 20px 0 0;
}

.profile-card {
    position: relative;
}

.profile-card h5 {
    padding-right: 30px;
}

.profile-card .form-option-block.nav-option {
    top: 35px;
    right: 28px;
}

.bottom-edit-block {
    float: left;
    width: 100%;
    margin: 5px 0;
}

.bottom-edit-block .pj-edit-ico {
    position: relative;
    display: inline;
    font-size: 14px;
    color: #f89a00;
    top: 0;
}

.bottom-edit-block .pj-edit-ico:before {
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
    margin-right: 10px;
    zoom: .8;
}

/* SPV */

.cardblock-tbl .each-growth-record {
    margin: 0;
}

.cardblock-tbl .each-growth-record ul li {
    align-items: flex-start;
}

.cardblock-tbl .each-growth-record ul li p {
    line-height: 25px;
}

.cardblock-tbl .each-growth-record ul li h5 {
    line-height: 25px;
}

.template-4.usr-profile .modal-body .modal-header {
    background: var(--default-color);
}

.template-4.usr-profile .modal-body .modal-header h5 {
    color: var(--white);
    padding: 0;
}

.each-settings-panel-wrap {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
}

.template-4.usr-profile .modal-body .modal-header {
    background: var(--default-color);
}

.template-4.usr-profile .modal-body .modal-header h5 {
    color: var(--white);
    padding: 0;
}

.template-4.usr-profile .modal-body .modal-header .modal-header-options {
    float: left;
    width: 35px;
    height: 35px;
}

.data-holder-right .card-container.template-collection {
    height: auto;
    align-items: flex-start;
}

/* Form */

.pw-ico:before {
    background-position: -798px -243px;
    width: 15px;
    height: 13px;
}

.rating-ico:before {
    background-position: -745px -243px;
    width: 14px;
    height: 11px;
}

.payment-ico:before {
    background-position: -762px -244px;
    width: 15px;
    height: 10px;
}

.lr-ico:before {
    background-position: -781px -242px;
    width: 14px;
    height: 16px;
}

.find-phone:before {
    content: "\f095";
    font-family: FontAwesome;
    background: 0 0;
    font-size: 14px;
    width: auto;
    height: auto;
    color: #aeafaf;
}

.ico-age:before {
    content: "\f007";
    font-family: FontAwesome;
    background: 0 0;
    font-size: 14px;
    width: auto;
    height: auto;
    color: #aeafaf;
}

.form-field-categories-block {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.dropdown-menu-cont {
    padding: 0;
}

.dropdown-menu-cont-inner {
    float: left;
    width: 100%;
    padding: 20px;
}

.bs-modal.bs-conformation .bs-modal-content .bs-modal-body {
    flex-shrink: 0;
}

.bs-modal.bs-conformation .bs-modal-content .bs-modal-title h3 {
    text-align: left;
    padding-right: 10px;
}

/* Setting */

.table-with-scroll {
    padding: 0 0 0 20px;
}

.table-with-scroll .scrollable {
    height: 100%;
}

.table-with-scroll .scrollable .form-table {
    height: 100%;
}

.row-btn-block .bs-modal-content.text-center {
    flex-direction: column;
    justify-content: center;
}

.bs-modal.bs-conformation.row-btn-block .bs-modal-content {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.bs-modal.bs-conformation.row-btn-block .bs-modal-content .bs-modal-title {
    margin-bottom: 15px;
}

.bs-modal.bs-conformation.row-btn-block .bs-modal-body .btn-danger {
    background: #da5566;
    border-color: #da5566;
    color: var(--white);
}

/* full-width */

.width-full .container {
    max-width: calc(100% - 30px);
}

.width-full .detailed-form-view-right-top {
    padding-left: 0;
    padding-right: 15px;
}

.width-full .detailed-form-view-right-bottom {
    padding: 0;
}

/* Template 6 */

.template-6 .ind-form-field .mandatory-box .field-common.field-req-block,
.template-6 .ind-form-field .mandatory-box .ind-task-input.selectize-custom {
    border: 1px solid #e14745;
}

.template-6.embedded-form .ind-form-field .input-block.drop.mandatory-box select {
    border: 1px solid #e14745;
}

.template-6.embedded-form .ind-form-field .mandatory-box .fiels-inp-box {
    border: 1px solid #e14745;
}

/* Resource */

.note-more-info {
    float: left;
    width: 100%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.note-more-info p {
    font-size: 12px;
    color: var(--default-grey);
    text-transform: uppercase;
}

.note-more-info h5 {
    width: auto;
    font-size: 12px;
    color: var(--default-grey);
    text-transform: uppercase;
}

.each-note-added .wf-btn-block {
    position: absolute;
    right: 12px;
    top: 12px;
}

.each-note-added {
    padding-right: 25px;
}

/* attendance */

.each-leave-card .medical-leave:before {
    background: #ffab91;
}

.medical {
    background: #ffab91;
    cursor: pointer;
}

.each-leave-card .privilge-leave:before {
    background: #F3E5F5;
}

.privilge {
    background: #F3E5F5;
    cursor: pointer;
}

/* Gantt */

.gantt-footer {
    float: left;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    background: var(--white);
    display: flex;
    align-items: center;
}

.gantt-footer .general-btn {
    min-width: auto;
    padding: 0 15px;
    height: 35px;
}

.full-wid-body {
    float: left;
    width: 100%;
    padding: 0px;
}

/* Date picker */

.z-calendar .react-datepicker-wrapper {
    height: 100%;
}

.z-calendar .react-datepicker {
    display: flex;
    border-color: #d3d3d3;
}

.z-calendar .react-datepicker__input-container input {
    padding-left: 10px;
    padding-right: 30px;
}

.z-calendar .react-datepicker__input-container {
    border: 0;
}

.z-calendar .react-datepicker__header {
    background: var(--col-active);
    padding-top: 10px;
    border-color: #d3d3d3;
}

.z-calendar .react-datepicker__time-container {
    border-color: #d3d3d3;
}

.z-calendar .react-datepicker__day-name {
    color: var(--default-grey);
}

.z-calendar .react-datepicker__day--selected,
.z-calendar .react-datepicker__day--in-selecting-range,
.z-calendar .react-datepicker__day--in-range,
.z-calendar .react-datepicker__month-text--selected,
.z-calendar .react-datepicker__month-text--in-selecting-range,
.z-calendar .react-datepicker__month-text--in-range,
.z-calendar .react-datepicker__quarter-text--selected,
.z-calendar .react-datepicker__quarter-text--in-selecting-range,
.z-calendar .react-datepicker__quarter-text--in-range,
.z-calendar .react-datepicker__year-text--selected,
.z-calendar .react-datepicker__year-text--in-selecting-range,
.z-calendar .react-datepicker__year-text--in-range {
    background-color: var(--default-color);
}

.z-calendar .react-datepicker__day--selected:hover,
.z-calendar .react-datepicker__day--in-selecting-range:hover,
.z-calendar .react-datepicker__day--in-range:hover,
.z-calendar .react-datepicker__month-text--selected:hover,
.z-calendar .react-datepicker__month-text--in-selecting-range:hover,
.z-calendar .react-datepicker__month-text--in-range:hover,
.z-calendar .react-datepicker__quarter-text--selected:hover,
.z-calendar .react-datepicker__quarter-text--in-selecting-range:hover,
.z-calendar .react-datepicker__quarter-text--in-range:hover,
.z-calendar .react-datepicker__year-text--selected:hover,
.z-calendar .react-datepicker__year-text--in-selecting-range:hover,
.z-calendar .react-datepicker__year-text--in-range:hover {
    background: var(--default-color);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background: var(--default-color);
}

.permission-tbl .scrollable {
    height: 100%;
}

.permission-tbl .form-table {
    height: 100%;
}

.reschedule-wrapper .z-calendar {
    height: 40px;
}

.dynamic-creation .persitant-top {
    overflow-x: hidden;
}

/* Resources tree */

.folder-tree .FolderTree * {
    color: white;
    cursor: pointer;
    font-size: 14px;
}

.folder-tree .TreeNode {
    display: flex;
    align-items: center;
    position: relative;
}

.folder-tree .typeIconContainer {
    font-size: 24px;
    margin: 0 3px;
}

.folder-tree .TreeNodeToolBar {
    position: absolute;
    left: 20px;
    top: 0;
    background: white;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    display: flex;
}

.folder-tree .TreeNodeToolBar * {
    color: #848484;
}

.folder-tree .TreeNodeToolBar svg {
    font-size: 14px;
    margin: 05px;
    cursor: pointer;
}

.folder-tree .AddFileIcon {
    display: none;
}

.editingName {
    display: flex;
    align-items: center;
}

.iconContainer.editableNameToolbar {
    flex-shrink: 0;
}

.editingName input {
    width: 140px;
    color: #444 !important;
}

.displayName {
    white-space: nowrap;
}

.folder-select {
    float: left;
    width: 100%;
    padding: 20px;
}

.folder-select .FolderTree * {
    color: var(--default-grey);
    cursor: pointer;
}

.folder-select .TreeNode {
    display: flex;
    align-items: center;
    position: relative;
}

.folder-select .typeIconContainer {
    font-size: 24px;
    margin: 0 3px;
}

.folder-select .TreeNodeToolBar {
    position: absolute;
    left: 20px;
    top: 0;
    background: var(--default-grey);
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    display: flex;
}

.folder-select .TreeNodeToolBar * {
    color: var(--default-grey);
}

.folder-select .TreeNodeToolBar {
    display: none;
}

.TreeNode.selected *,
.TreeNode.active * {
    color: var(--default-color);
}

/* Task */

.task-toggler-wrapper.task-tg {
    width: 775px;
}

.date-container-block.double-block {
    display: flex;
}

/* Accordion */

.z-accordion-header {
    float: left;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.z-accordion .card .card-header button {
    background: transparent;
    border: 0;
    padding: 0;
}

.z-accordion-header h5 {
    font-size: 16px;
    font-weight: 600;
    color: var(--default-text);
}

.z-accordion .card-body {
    float: left;
    width: 100%;
    padding: 20px;
    padding-top: 0;

}

.z-accordion .form-option-block.nav-option {
    float: left;
    position: absolute;
    right: 30px;
    top: 30px;
}

.menu-map-list {
    float: left;
    width: 100%;
    padding-left: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.menu-map-list li {
    float: left;
    width: auto;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: var(--default-color);
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    align-items: center;
}

.menu-map-list li:before {
    content: "";
    width: 13px;
    border-bottom: 1px dashed var(--default-color);
    position: absolute;
    left: -19px;
    top: calc(50% - 5.5px);
}

.menu-map-list:before {
    content: "";
    width: 1px;
    height: calc(100% - 20px);
    border-left: 1px dashed var(--default-color);
    position: absolute;
    left: 0;
    top: 0;
}

.menu-map-list li:last-child {
    margin-bottom: 0;
}

.menu-map-list li:hover .wf-btn-block {
    opacity: 1;
    pointer-events: auto;
}

.z-accordion .card {
    border-bottom: 0;
}

/* summary tab */

.summary-card-tab-inner .each-details-entry {
    align-items: unset;
}

.summary-card-tab-inner .details-entry-right {
    height: auto;
}

.summary-card-tab-inner .details-entry-left {
    float: left;
    flex-shrink: 0;
    width: 130px;
    padding: 4px 18px;
}

/* Capture */

.multiple-photo-capture {
    float: left;
    width: 100%;
}

.multiple-photo-capture .photo-capture-wrapper {
    max-width: 300px;
    margin-right: 15px;
}

.browse-ico:before {
    content: "";
    background: var(--sprite-two) no-repeat -23px -140px;
    width: 14px;
    height: 16px;
    display: inline-block;
    zoom: 1.3;
    margin-bottom: 6px;
    opacity: .8;
}

.browse-ico input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

/* collection */

.grey-bg-panel .discussion-inner {
    background: #f0f0f0;
}

.usr-profile .modal-header > h5 {
    font-size: 20px;
    font-weight: 600;
    color: #444;
    padding: 0 35px;
}

/* Learn */

.data-learner-course {
    display: flex;
}

.data-learner-course-left {
    order: 1;
    margin: 0;
}

.data-learner-course-right.learn-footer {
    order: 0;
    margin-right: 20px;
}

.ind-board-list.newcard .file-each {
    width: 66px;
}

/* Board */

.card-figure {
    float: left;
    width: 100%;
    height: 150px;
}

.card-figure img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.board-cards .ind-board-list {
    padding-right: 15px;
}

.ind-board-list h5 {
    padding-right: 35px;
}

.board-list-bottom {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 11px;
}

.board-tag {
    font-size: 11px;
    background: var(--default-grey);
    padding: 4px;
    border-radius: 5px;
    color: var(--white);
    margin: 0 5px 5px 0;
}

.status-tick:before {
    background-position: -564px -107px;
    width: 30px;
    height: 30px;
    filter: grayscale(1);
    opacity: .5;
    zoom: .8;
}

.status-tick.active:before {
    opacity: 1;
    filter: grayscale(0);
}

.board-tag.fb {
    background: #526FA3;
}

.board-tag.tw {
    background: #4DB7EB;
}

.board-tag.pin {
    background: #BB413E;
}

.board-tag.ig {
    background: #C83379;
}

.board-tag.yt {
    background: #BB413E;
}

/* Summury */

.min-width-box {
    float: left;
    width: 100%;
    padding: 0px 20px 20px;
    margin-bottom: 10px;
}

.min-width-box {
    float: left;
    width: 100%;
    padding: 0px 20px 20px;
}

.min-width-box .each-settings-panel-header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.min-width-box .select-box {
    max-width: 400px;
}

.brand-indicator {
    white-space: normal;
}

/*  Board */

.ibt-header-top-right .collection-note {
    float: left;
    width: auto;
    height: 32px;
    padding: 0 20px;
    margin-left: 14px;
    background: #fff;
    border: 1px solid var(--default-color);
    border-radius: 3px;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin-right: 15px;
}

.ibt-header-top-right .collection-note:before {
    content: "";
    background: var(--sprite-two) no-repeat -6px -384px;
    width: 20px;
    height: 17px;
    zoom: .8;
}

.pj-board-container .side-bar-panel {
    top: 70px;
    height: calc(100vh - 72px);
}

.each-category-tag {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dee2e6;
}

.each-category-tag h5 {
    float: left;
    width: 100%;
    margin-bottom: 15px;
    font-weight: 600;
    color: var(--default-color);
}

.each-category-tag ul li {
    float: left;
    width: 100%;
    font-size: 16px;
    line-height: normal;
    color: var(--default-grey);
    margin-bottom: 10px;
}

.each-category-tag ul li:last-child {
    margin-bottom: 0;
}

/* Menu Mapping */

.menu-header-list h5 {
    float: left;
    line-height: 25px;
    padding-left: 28px;
    transition: .3s ease;
}

.menu-header-list h5 span {
    position: absolute;
    left: 20px;
    top: 13px;
    zoom: .9;
}

/* Baord */

.add-card-mod .modal-body {
    max-height: 350px;
    overflow-y: auto;
}

.up-list .file-each:nth-child(3n) {
    margin-right: 15px;
}

.up-list .file-each {
    margin-right: 15px;
    margin-bottom: 15px;
}

.add-card-mod .upload-container-panel-header h5 {
    color: white;
    margin: 0;
}

.add-card-mod .upload-container-panel-header p {
    flex-shrink: 0;
}

/*task*/

.up-wrap {
    float: left;
    width: 100%;
    padding: 0 20px;
    position: relative;
}

.close-btn-ico {
    padding: 0;
    border: 0;
    background: transparent;
    position: absolute;
    right: 13px;
    top: -8px;
}

.close-btn-ico:before {
    background-position: -266px -62px;
    width: 16px;
    height: 16px;
}

.photo-capture-btn {
    position: relative;
}

/* Task Details */

.task-inner-wrapper {
    float: left;
    width: 100%;
    height: 100%;
}

.new-tab .task-right {
    width: 510px;
}

.new-tab .task-left {
    width: calc(100% - 525px);
}

.info-tab-ico:before {
    content: "\f05a";
    font-family: 'FontAwesome';
    color: var(--default-grey);
    font-size: 17px;
}

.task-extra-tab-block .nav-tabs .nav-link.active .info-tab-ico:before {
    color: var(--default-text);
}

.each-task-tag.no-icon p:before {
    content: none;
}

/* Collection */

.fieldset-border {
    float: left;
    width: 100%;
    padding: 20px;
    border: 1px dashed #d3d3d3;
    margin-bottom: 20px;
    background: #fafafa;
    position: relative;
}

.fieldset-border .each-inp-box {
    margin-bottom: 0;
}

.min-width-box .fieldset-border .select-box {
    max-width: 100%;
}

.indent-select {
    padding-left: 25px;
}

.unlink:before {
    content: "\f127 ";
    font-family: 'FontAwesome';
    font-size: 14px;
    color: var(--default-grey);
}

.unlink:hover:before {
    color: var(--default-color);
}

.react-card-front,
.react-card-back {

    float: left;
    width: 100%;
    height: auto;
    min-height: 350px;
    background: #fafafa;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    padding: 20px;
}

/* Collection  */

.collection-card-left .radio-container {
    flex-direction: column;
    margin-bottom: 15px;
    align-items: flex-start;
}

.collection-card-left .radio-container h5 {
    margin-bottom: 10px;
    font-size: 14px !important;
}

/* Matrix Component */

.matrix-table .table tbody tr td {
    vertical-align: middle;
}

.matrix-table .table tbody tr td .add-multiple-box {
    position: relative;
    display: flex;
    right: 0;
    top: 0;
}

.matrix-table .table tbody tr td .input-block {
    margin-bottom: 0;
}

.matrix-table .table thead tr th {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--default-grey);
    border: 0;
}

.matrix-table .table {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin-bottom: 0;
}

.matrix-table .table thead tr th:first-child {
    border-bottom-left-radius: 5px;
}

.each-inp-box.full-width {
    width: 100%;
}

.full-width .modal-content .matrix-modal .modal-content {
    height: auto;
}

.full-width .modal-content .matrix-modal .modal-body {
    height: auto;
    padding: 15px;
    background: var(--white);
}

.full-width .modal-content .matrix-modal .modal-content .modal-header {
    background: var(--theme-second);
}

.full-width .modal-content .matrix-modal .modal-content .modal-header .task-head-text {
    margin: 0;
    color: #fff;
    float: left;
    font-size: 16px;
    font-weight: 400;
}

.full-width .modal-content .matrix-modal .modal-body .each-inp-box.full-width {
    margin: 0;
}

/* flip */

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 300px;
    perspective: 1000px;
    margin-bottom: 20px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 20px;
}

.flip-card-front {
    background-color: #FAFAFA;
    color: black;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
}

.flip-card-back {
    background-color: dodgerblue;
    color: white;
    transform: rotateY(180deg);
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flip-card-front p,
.flip-card-back p {
    float: left;
    width: 100%;
    text-align: left;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 5px;
}

/* Collection */

.template-anchor li nav {
    padding: 0 30px;
}
.set-container .mobile-setting-wrapper {
    padding: 20px;
    border: 1px solid #dee1e4;
    border-radius: 5px;
    margin-bottom: 20px;
}
.each-template-head h5 {
    text-align: center;
}
.button-bottom-block .general-btn.bordered {
    border-radius: 39px;
    background: white;
    color: var(--default-color);
    box-shadow: none;
}
.dropdown-container {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.persitant-top .up-wrap {
    margin-bottom: 20px;
}


/* Media Query */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .left-panel.with-card .right-panel-white-box {
        width: calc(100% - 360px);
    }
}

@media (max-width: 1024px) {

    /* Listing view */
    .multiple-box {
        flex-direction: column;
    }

    .multiple-box-wrap {
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e3e5e8;
    }

    .multiple-box-wrap:first-child {
        padding-right: 0;
        border-right: 0;
        margin-right: 0;
    }

    .multiple-box-wrap:last-child {
        padding: 0;
        border: 0;
        margin: 0;
    }

    .schedule-inner .even-time-block {
        width: 100%;
    }

    .data-learner-course-left {
        order: 0;
        margin-right: 20px;
        margin: 0 0 20px 0;
    }

    .data-learner-course-right.learn-footer {
        order: 1;
        margin-right: 0;
    }

    /* Collection */
    .mini-card-figure {
        width: 65px;
        height: 65px;
    }

    .mini-card-details {
        width: calc(100% - 85px);
    }

    .filter-view-tab .general-btn {
        min-width: auto;
        white-space: nowrap;
    }

}

@media (max-width: 767px) {

    .each-multiple-wf .each-inp-box {
        width: 100%;
    }

    .adv-search-block {
        position: absolute;
        right: 0;
        top: 0;
    }

    .adv-search-block .dropdown-menu {
        width: 320px;
        transform: translate3d(-226px, 37px, 0px) !important;
    }

    .adv-search-footer .general-btn {
        margin-top: 0;
        font-size: 14px;
    }

    .left-panel.multi-card-record.fieldset-template {
        width: 100%;
    }

    .data-learner-course-right-header .audio-player {
        width: 55%;
    }

    .data-learner-course-right-header .audio-player audio {
        width: 100%;
    }

    /* GE View */
    .chart-settings-splitter {
        width: 100%;
        margin-right: 0;
    }

    .chart-settings-splitter {
        margin-bottom: 20px;
    }

    .each-settings-panel-wrap-body .each-inp-field.eq-width-line {
        width: 100%;
        margin-bottom: 20px;
    }

    .each-settings-panel-wrap-body .each-inp-field.eq-width-line:last-child {
        margin-bottom: 0;
    }

    .for-small .each-settings-panel-wrap .each-inp-field {
        width: 100%;
    }

    /* GE View End */
    /* Learn and course */
    .data-learner-course-right-footer .audio-player {
        width: 65%;
    }

    .data-learner-course-right-footer .audio-player audio {
        width: 100%;
    }

    .task-wrapper .data-learner-course-right-body .course-preview-box iframe.ql-video {
        min-height: 184px;
        max-width: 100%;
    }

    /* Learn and course End  */
    /* Blog Template */
    .blog .right-panel-white-box {
        padding: 30px;
    }

    .meta-content-box {
        width: 100%;
        padding: 30px;
    }

    /* Blog Template End */
    /* Learn */
    .learn-detail-panel .learn-course-info {
        position: relative;
        margin-left: 24px;
        right: 0;

        margin-bottom: 7px !important;
    }

    .learn-detail-panel .audio-player audio {
        width: 100%;
    }

    .learn-detail-panel .height-210 .learn-course-info {
        margin-bottom: 8px;
    }

    .learn-detail-panel .learn-tab {
        overflow: hidden;
        overflow-x: auto;
    }

    /* Collection */
    .without-filter .form-table {
        max-width: 100%;
    }

    .without-filter .data-search-left .filter-ico {
        display: none;
    }

    /* template 18 */
    .column-break .col {
        width: 100%;
    }

    .template-18 .ind-form-field.fieldset.flex-box .style-view .input-block {
        width: 100%;
        padding: 0;
    }

    .template-18 .ind-form-field.fieldset.flex-box .style-view .input-block {
        width: 100%;
        padding-right: 0;
    }

    .template-18 .ind-form-field.fieldset.flex-box .style-view .radio-wrapper {
        width: 100%;
        height: auto;
    }

    .template-18 .ind-form-field.fieldset.flex-box .style-view .checkbox-wrapper {
        width: 100%;
        height: auto;
    }

    .template-18 .video-recorder-start {
        border-radius: 0;
        max-width: 100%;
    }


    /* Template 19 */
    .template-19 .ind-form-field.fieldset.flex-box .style-view .input-block {
        width: 100%;
        padding: 0;
    }

    .template-19 .ind-form-field.fieldset.flex-box .style-view .input-block {
        width: 100%;
        padding-right: 0;
    }

    .template-19 .ind-form-field.fieldset.flex-box .style-view .radio-wrapper {
        width: 100%;
        height: auto;
    }

    .template-19 .ind-form-field.fieldset.flex-box .style-view .checkbox-wrapper {
        width: 100%;
        height: auto;
    }

    .template-19 .video-recorder-start {
        border-radius: 0;
        max-width: 100%;
    }

    /* Template 20 */
    .template-20 .ind-form-field.fieldset.flex-box .style-view .input-block {
        width: 100%;
        padding: 0;
    }

    .template-20 .ind-form-field.fieldset.flex-box .style-view .input-block {
        width: 100%;
        padding-right: 0;
    }

    .template-20 .ind-form-field.fieldset.flex-box .style-view .radio-wrapper {
        width: 100%;
        height: auto;
    }

    .template-20 .ind-form-field.fieldset.flex-box .style-view .checkbox-wrapper {
        width: 100%;
        height: auto;
    }

    .template-20 .video-recorder-start {
        border-radius: 0;
        max-width: 100%;
    }

    .column-break .row {
        flex-direction: column;
    }

    .template-18.detailed-form-view.direct-flow .modal-body .form-preview-header .close {
        right: -20px;
        top: 0px;
    }

    /* Learn */
    .task-wrapper .course-preview-box img {
        max-width: 100%;
    }

    .task-wrapper .mob-lg-preview .learn-body-section-preview .data-learner-course-right-body .course-preview-box video {
        max-width: 100%;
    }

    .patient-modal .event-editor-block-2 {
        width: 40% !important;
    }

    .assessment-img img {
        width: 100%;
    }

    .schedule-inner .even-time-block {
        width: 100%;
    }

    .schedule-inner .patient-modal .event-editor-block-2 {
        width: 100% !important;
    }

    .cardblock-tbl .each-growth-record ul li p {
        flex-shrink: 0;
        width: 45%;
        padding-right: 15px;
    }

    .growth-mod .growth-wrapper {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .task-toggler-wrapper.task-tg {
        width: 100%;
    }

    /* Task */
    .top-sticky-bar {
        box-shadow: none;
    }

    .task-wrapper.task-detail-stand {
        padding: 0;
    }

    .task-detail-panel {
        border: 0;
    }

    .task-extra-tab-block .tab-content {
        border-bottom: 0;
    }

    /* task */
    .side-menu-wrapper:before {
        content: "";
        width: 100vw;
        height: 100%;
        background: rgb(0 0 0 / 50%);
        position: absolute;
        left: calc(-100% - 500px);
        top: 0;
        z-index: -1;
        transition: .3s ease;
    }

    .side-menu-wrapper.active:before {
        left: 0;
    }

    .icon-menu {
        background: var(--sprite-two) no-repeat -4px -10px;
        width: 18px;
        height: 12px;
    }

    .side-menu-wrapper {
        top: 0;
        height: 100%;
        z-index: 99999;
    }

    .task-inner-wrapper.hide-sm {
        display: none;
    }

    .task-inner-wrapper.show-sm {
        display: block;
    }

    /* Task  Details */
    .task-top-bar {
        float: left;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        background: var(--white);
        border-bottom: 1px solid #d3d3d3;
    }

    .task-top-bar .backto-list {
        background: transparent;
        border: 0;
        padding: 0 20px;
    }

    .task-tab-nav {
        float: left;
        width: 100%;
        height: 45px;
        background: var(--white);
        display: flex;
        align-items: center;
        overflow: hidden;
        overflow-x: auto;
        margin-bottom: 10px;
        border-bottom: 1px solid #d3d3d3;
    }

    .each-tab-nav {
        float: left;
        width: auto;
        padding: 0 15px;
        font-size: 15px;
        line-height: 45px;
        color: var(--default-grey);
        font-weight: 600;
        white-space: nowrap;
        position: relative;
    }

    .each-tab-nav:before {
        content: "";
        width: 0;
        height: 2px;
        background: var(--default-color);
        position: absolute;
        left: 0;
        bottom: 1px;
        transition: .3s ease-out;
    }

    .each-tab-nav.active:before {
        width: 100%;
    }

    .each-tab-nav.active {
        color: var(--default-color);
    }

    /* .task-tab-body {
        float: left;
        width: 100%;
        height: calc(100% - 45px);
        overflow-y: auto;
    } */
    .task-details-body {
        float: left;
        width: 100%;
        border-top: 1px solid #d3d3d3;
        height: calc(100% - 100px);
        overflow-y: auto;
    }

    .task-tab-body .user-list-block {
        margin-bottom: 0;
        padding-top: 15px;
    }

    .task-details-body .css-1nrl0ya {
        height: 100%;
    }

    .task-tab-body {
        float: left;
        width: 100%;
        height: 100%;
    }

    .react-swipe-container {
        height: 100%;
    }

    .react-swipe-container > div {
        height: 100%;
    }

    .each-swipe-card {
        height: 100%;
        overflow-y: auto;
    }

    .task-details-header-block {
        flex-direction: column;
    }

    .task-details-body .swipe-tab {
        width: 100%;
        height: 100%;
    }

    .swipe-tab .react-swipeable-view-container {
        width: 100%;
        height: 100%;
    }

    /* Collection */
    .mini-card-view .row {
        flex-direction: column;
    }

    .page-full-screen .top-sticky-bar {
        display: none;
    }

    .page-full-screen .form-detail-container.data-view {
        height: 100%;
    }

    .masthead-container .mast-head-banner-container.full-banner {
        background-size: contain !important;
        height: auto !important;
        left: 0;
        width: 100%;
    }

    .growth-nav .growth-unique-record {
        padding: 0 20px;
    }

    .growth-nav .growth-row:first-child .growth-unique-record {
        padding-top: 20px;
    }

    /* Whatsapp */
    .wa-left {
        width: 100%;
        position: absolute;
        left: -100%;
        top: 0;
        z-index: 8;
    }

    .wa-chat-main-right {
        width: 100%;
        position: absolute;
        right: -100%;
        top: 0;
        z-index: 8;
    }

    .wa-right {
        width: 100%;
    }

}