.user-info-click .fa-phone {
    color: var(--default-grey);
    font-size: 25px;
}
.user-info-click .fa-phone:hover {
    color: var(--default-color);
}
.schedule-call-frame {
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    padding: 30px;
    display: none;
}
.schedule-call-frame.active {
    display: block;
}
.schedule-call-frame:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 0;
    opacity: .5;
    z-index: -1;
}
.schedule-call-wrapper {
    float: left;
    width: 100%;
    height: calc(100% - 50px);
    transition: .3s ease-out;
}
.schedule-call-left {
    float: left;
    width: calc(100% - 350px);
    height: 100%;
}
.schedule-call-right {
    float: left;
    width: 350px;
    height: 100%;
    background: var(--white);
    border-left: 1px solid #e3e5e8;
}
.schedule-call-left iframe {
    float: left;
    width: 100%;
    height: 100%;
}
.call-frame .schedule-call-wrapper {
    width: 110px;
    height: 110px;
    position: absolute;
    right: 20px;
    top: 20px;
    overflow: hidden;
    background: var(--default-color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.call-frame .schedule-call-wrapper .schedule-call-left, 
.call-frame .schedule-call-wrapper .schedule-call-right {
    display: none;
}
.call-frame .schedule-call-wrapper:before {
    content: "Take Call";
    color: white;
    font-size: 18px;
    font-weight: 600;
}
.schedule-call-header {
    float: left;
    width: 100%;
    height: 50px;
    background: var(--default-color);
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px 5px 0 0;
}
.schedule-call-header h5 {
    font-size: 16px;
    font-weight: 600;
    color: white;
}
.user-info-click.schedule-icon {
    display: none;
}
.tata .user-info-click.schedule-icon {
    display: flex;
}

@media(max-width: 767px) {
    .schedule-call-frame {
        top: 60px;
        padding: 15px;
        height: calc(100% - 60px);
    }
    .schedule-call-wrapper {
        overflow-y: auto;
    }
    .schedule-call-left {
        width: 100%;
    }
    .schedule-call-right {
        width: 100%;
    }
    .user-info-click .fa-phone {
        color: white !important;
    }
    .schedule-call-header-right .icon-in.icon-minimize {
        display: block;
    }
}